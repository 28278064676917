<template>
  <div class="row text-center">
    <div
      class="dataTables_info"
      id="product_table_info"
      role="status"
      aria-live="polite"
    >
      عرض {{data.length}}
       <!-- إلى  -->

         من  
       {{data.total}}



      
      إدخالات
    </div>
    <div     v-if="data.total > data.limit"
      class="dataTables_paginate paging_simple_numbers"
      id="product_table_paginate"
    >
      <ul class="pagination">
        <li
          class="paginate_button previous disabled"
          id="product_table_previous"
        >
          <a href="javascript:void(0);"  v-if="prev"
           @click="toPage(prev)"
          aria-controls="product_table" data-dt-idx="0" tabindex="0"
            >السابق</a
          >
          <a v-else    :disabled="!prev"
            
          aria-controls="product_table" data-dt-idx="0" tabindex="0"
            >السابق</a
          >
        </li>
        <li class="paginate_button "
          v-for="_page in data.pages"
          :key="_page"
          :disabled="data.current_page == _page"
           :class="(data.current_page == _page) ?'active' :''" 
        
        >
          <a href="javascript:void(0);" 
           @click="toPage(_page)"
          aria-controls="product_table" data-dt-idx="1" tabindex="0"
            >{{ _page }}</a
          >
        </li>
        <li class="paginate_button next disabled" id="product_table_next">
          <a 
          v-if="next"
           @click="toPage(next)" href="javascript:void(0);" aria-controls="product_table" data-dt-idx="2" tabindex="0"
            >التالى</a
          >
          <a  v-else
          :disabled="!next"
              aria-controls="product_table" data-dt-idx="2" tabindex="0"
            >التالى</a
          >
        </li>
      </ul>
    </div>

     
  </div>
</template>
// api pagination
<script>
//  data = "total": 25,
// "limit": 10,
// "page": "2",
// "pages": 3
// "current_page": 0,
//   "pages": 1,
//   "next": 1,
//   "pre": 0,
export default {
  props: {
    data: Object,
  },
  created() {
    console.log(this.data);
  },
  data(){
    return{
       
    }
  },
   computed: {
          prev() {
            if(this.data.page==1)
            return false;
            if(this.data.pages>this.data.page)
            var p=  Number(this.data.page)-1
            console.log('prev',p)
            return p
        },
        next() {
                 if(this.data.pages==this.data.page)
                  return false;
              if(this.data.pages>this.data.page)
            var n= Number(this.data.page)+1
            console.log('next',n)
            return n
        },},
  methods: {
    toPage(url) {
      this.$emit("toPage", url);
    },
  },
};
</script>
