import Client from "./Clients/AxiosClient";
const change_language = "/change_language";
const get_country = "/settings/service_types";
const get_type_list = "/settings/get_type_list";
const service_types = "/settings/service_types";
const type_list = "/settings/type_list";

const trip_languages = "/settings/get_language_list";
const basic_settings = "/settings/basic_settings";
 const promotional_codes = "/promotional_codes";
const documents = "/get_document_list";
const email_emplate = "/settings/email_emplate";
const template = "/settings/template";
const admin_list = "/admin/admin_list";
const terms_privacy_settings = "/settings/terms_privacy_settings";
const guest_token_setting = "/settings/get_guest_token";
const installation_settings = '/installation_settings';
const reviews = '/reviews';
const cancellation_reasons = '/cancellation_reasons';
const city_type = '/city_type';

export default {
  
  city_types(page=1,form_filter=false){
 
    let url = city_type + page;
    if(!form_filter)
    return Client.get(`${url}`);
    else
    
    return Client.post(`${url}`,form_filter);
  },
  cancellation_reasons(page=1,form_filter=false){
    let url = cancellation_reasons + page;
    if(!form_filter)
    return Client.get(`${url}`);
    else
    
    return Client.post(`${cancellation_reasons}`,form_filter);
  },
  reviews(){
    return Client.get(`${reviews}`);
  },
  trip_languages() {
    return Client.get(`${trip_languages}`);
  },
  basic_settings() {
    return Client.get(`${basic_settings}`);
  },
  installation_settings() {
    return Client.get(`${installation_settings}`);
  },
  promotional_codes() {
    return Client.get(`${promotional_codes}`);
  },
  documents() {
    return Client.get(`${documents}`);
  },
  email_emplate() {
    return Client.get(`${email_emplate}`);
  },
  template() {
    return Client.get(`${template}`);
  },
  admin_list() {
    return Client.get(`${admin_list}`);
  },
  terms_privacy_settings() {
    return Client.get(`${terms_privacy_settings}`);
  },
  guest_token_setting() {
    return Client.get(`${guest_token_setting}`);
  },

  getProducts() {
    return Client.get(`${productsResource}`);
  },
  type_list() {
    return Client.post(`${type_list}`);
  },
  service_types() {
    return Client.post(`${service_types}`);
  },
  get_type_list() {
    return Client.post(`${get_type_list}`);
  },
  get_country() {
    return Client.post(`${get_country}`);
  },
  changeLanguage(data) {
    return Client.post(`${change_language}`, data);
  },
};
