<template>

 <!-- Layout wrapper -->
   
      <div class="container-xxl">
        <slot/>

      

            <!-- / Content -->
 <!-- /Content wrapper -->
            <!-- Footer -->
            <footer class="content-footer footer bg-footer-theme">
              <div class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                <div class="mb-2 mb-md-0">
                  ©

                  , made with ❤️ by
                  <a href="https://themeselection.com" target="_blank" class="footer-link fw-medium">ThemeSelection</a>
                </div>
                <div class="d-none d-lg-inline-block">
                  <a href="https://themeselection.com/license/" class="footer-link me-4" target="_blank">License</a>
                  <a href="https://themeselection.com/" target="_blank" class="footer-link me-4">More Themes</a>

                  <a
                    href="https://demos.themeselection.com/sneat-bootstrap-html-admin-template/documentation/"
                    target="_blank"
                    class="footer-link me-4"
                    >Documentation</a
                  >

                  <a
                    href="https://github.com/themeselection/sneat-html-admin-template-free/issues"
                    target="_blank"
                    class="footer-link"
                    >Support</a
                  >
                </div>
              </div>
            </footer>
            <!-- / Footer -->

            <div class="content-backdrop fade"></div>
          
      </div>

</template>

<script>

import navbar from './navbar.vue'
import { useSlots } from 'vue'

import topmenu from './topmenu.vue'
import Menu from './Menu.vue'

export default {


  components: {
    navbar, topmenu, Menu

  },
  methods: {
    updateLang() {

      if (this.getLang() != 'ar')
        this.main_class = ''
      else
        this.main_class = 'overflow-x-hidden'

    }
  },
  created() {
    this.updateLang()
    this.emitter.on('updatelang', this.updateLang);
  },
  data() {
    return {
        slots :useSlots(),
      main_class: '',
      showingNavigationDropdown: false,
    }
  },
}
</script>
