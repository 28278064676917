<template> 

<div class="container">


    <c-form :form="form" :entity="entity" :edit_mode="edit_mode">
        <field :entity="entity" :form="form" />
    </c-form></div>
</template>

<script>
import field from "./field.vue";
export default {
    components: {
        field,
    },
    props: {
        data: [],
       
        edit_mode: false,
    },

    data() {
        return {

             entity: Object,
            loading: false,
            form: {
              contact_person_name:""  ,
              address_type:"",
              contact_person_number:"",
              address:"",
              longitude:90.36934144046135,  
              latitude:23.81695886557418
            },
        };
    },
    created() {
            this.emitter.on('store', this.store);
            this.emitter.on('update', this.update);

          document.title = this.tr('Add Address');
          
          if (this.edit_mode)
            document.title = this.tr("edit") + "-" + this.tr(this.data.title);
         for (var key in this.entity) this.form[key] = this.entity[key];

        this.loading = true;
    },
    methods: { 
    // 
    async store( ) {
      try {
          var u='customer/address/add' 
        var res = await this.client.post(u,this.form);

        if (res.status === 200) {
         this.alertSuccess(res.message)
 this.resetForm( ) 
          this.loaderOn = false;
        }
      } catch (error) {
        this.loaderOn = false;
        throw error;
      }
    },

     
  }
};
</script>