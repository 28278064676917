import Client from './Clients/AxiosClient';
const SendResource = '/otp/send';
const VerifyResource = '/auth/login';
const LogoutResource = '/logout'

export default {
  send(payload) {
    return Client.post(`${SendResource}`, payload)
  },
  verify(payload) {
    return Client.post(`${VerifyResource}`, payload)
  },
  logout() {
    return Client.get(`${LogoutResource}`)
  }
}