<template>
    
 

<div class="style-0">
    <div class="style-1">
        <div class="style-2">
            <div class="style-3">
                <h4 class="style-4"><span class="style-5"> 
                    
                    {{site_data.company_sub_title}}
                    
                     </span></h4>
             </div>
            <p class="style-13">
                 {{site_data.company_description}}
                
                </p>
            <div class="style-49"><button class="style-50" tabindex="0" type="button">
                    <p class="style-51"> Order Now<svg class="style-52" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowRightAltIcon">
                            <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" class="style-53"></path>
                        </svg></p>
                </button></div>
        </div>
    </div>
    <div class="style-54" align="right"><svg width="454" height="303" viewBox="0 0 454 303" fill="none" xmlns="http://www.w3.org/2000/svg" class="style-55">
            <path d="M134.959 211.618L134.148 256.971L63.0516 257.141L62.9487 211.176L134.959 211.618Z" fill="#EBEBEB" class="style-56"></path>
            <path d="M93.0763 257.017C92.9794 257.017 93.0763 246.89 93.3124 234.401C93.5484 221.911 93.7906 211.791 93.8814 211.797C93.9722 211.803 93.8814 221.917 93.6453 234.407C93.4092 246.896 93.1671 257.017 93.0763 257.017Z" fill="#E0E0E0" class="style-57"></path>
            <path d="M63.0518 257.141C63.138 256.975 63.235 256.815 63.3423 256.662L64.22 255.335L67.5191 250.487L78.4938 234.519C82.7796 228.295 86.6355 222.647 89.3898 218.527C90.77 216.472 91.8596 214.788 92.6102 213.63C92.9673 213.067 93.2518 212.612 93.4637 212.285C93.5563 212.122 93.6637 211.968 93.7845 211.824C93.7068 211.993 93.6199 212.156 93.5242 212.315L92.7191 213.697C92.0048 214.909 90.9395 216.581 89.5896 218.66C86.8898 222.811 83.0641 228.483 78.7723 234.713C74.4805 240.943 70.57 246.542 67.7189 250.59L64.3351 255.384L63.4028 256.674C63.2997 256.84 63.1822 256.996 63.0518 257.141Z" fill="#E0E0E0" class="style-58"></path>
            <path d="M93.076 257.016C92.9532 256.877 92.8438 256.727 92.7491 256.567L91.8654 255.252L88.651 250.404L77.9851 234.454C73.8022 228.231 70.0491 222.577 67.3857 218.45C66.06 216.383 64.9643 214.705 64.2682 213.541L63.4389 212.19C63.3346 212.038 63.2435 211.878 63.1665 211.711C63.2881 211.849 63.3974 211.997 63.4934 212.153L64.3772 213.468C65.152 214.638 66.2416 216.292 67.5854 218.316L78.2575 234.266C82.4403 240.49 86.1873 246.144 88.8508 250.277C90.1825 252.337 91.2721 254.016 91.9743 255.185L92.8036 256.531C92.9059 256.686 92.997 256.848 93.076 257.016Z" fill="#E0E0E0" class="style-59"></path>
            <path d="M100.416 251.869L111.831 252.074L111.873 249.735L100.458 249.53L100.416 251.869Z" fill="#E0E0E0" class="style-60"></path>
            <path d="M100.994 247.64C102.849 247.459 104.713 247.392 106.575 247.44C108.439 247.374 110.304 247.418 112.163 247.573C110.308 247.75 108.444 247.817 106.581 247.773C104.718 247.839 102.852 247.794 100.994 247.64Z" fill="#E0E0E0" class="style-61"></path>
            <path d="M116.169 211.361L115.855 228.868L113.391 226.911L110.195 228.765L110.51 211.258L116.169 211.361Z" fill="#E0E0E0" class="style-62"></path>
            <path d="M126.43 177.265L125.812 211.88L71.5564 212.007L71.4717 176.926L126.43 177.265Z" fill="#F5F5F5" class="style-63"></path>
            <path d="M94.4683 211.91C94.3956 211.91 94.4683 204.184 94.6499 194.651C94.8315 185.119 95.0131 177.398 95.0857 177.398C95.1583 177.398 95.0857 185.125 94.9041 194.657C94.7225 204.19 94.5349 211.91 94.4683 211.91Z" fill="#E0E0E0" class="style-64"></path>
            <path d="M71.5566 212.007C71.6198 211.881 71.6926 211.759 71.7746 211.644C71.9441 211.393 72.168 211.054 72.4465 210.626C73.0518 209.735 73.9114 208.474 74.9647 206.929C77.1197 203.796 80.0737 199.506 83.3425 194.767C86.6113 190.028 89.5532 185.677 91.6537 182.562C92.7009 180.986 93.5363 179.708 94.1114 178.823L94.7591 177.793C94.8338 177.672 94.9146 177.554 95.0012 177.441C94.9442 177.573 94.8775 177.701 94.8014 177.823L94.1961 178.871C93.6513 179.774 92.8402 181.077 91.8111 182.659C89.7469 185.828 86.8292 190.161 83.5543 194.912C80.2795 199.663 77.2891 203.936 75.116 207.032L72.5373 210.668C72.2346 211.08 71.9985 211.414 71.823 211.656C71.7445 211.781 71.6554 211.898 71.5566 212.007Z" fill="#E0E0E0" class="style-65"></path>
            <path d="M94.4682 211.912C94.3723 211.808 94.2872 211.694 94.214 211.573L93.542 210.567C92.9367 209.676 92.1195 208.416 91.0904 206.87L82.9305 194.696C79.7404 189.951 76.8772 185.606 74.8433 182.479C73.8324 180.903 73.0273 179.624 72.4643 178.734L71.859 177.703C71.7198 177.467 71.6471 177.34 71.6532 177.34C71.7475 177.444 71.8307 177.558 71.9014 177.679L72.5793 178.679C73.1847 179.576 74.0019 180.83 75.0007 182.382L83.1485 194.556C86.3386 199.301 89.2018 203.616 91.2297 206.767C92.2466 208.343 93.0457 209.628 93.6147 210.518L94.2503 211.542C94.3314 211.66 94.4043 211.784 94.4682 211.912Z" fill="#E0E0E0" class="style-66"></path>
            <path d="M100.472 207.732L109.183 207.906L109.219 206.119L100.508 205.944L100.472 207.732Z" fill="#E0E0E0" class="style-67"></path>
            <path d="M100.485 204.754C101.894 204.621 103.309 204.57 104.723 204.602C106.136 204.556 107.551 204.59 108.96 204.705C107.552 204.842 106.137 204.892 104.723 204.857C103.309 204.91 101.894 204.876 100.485 204.754Z" fill="#EBEBEB" class="style-68"></path>
            <path d="M115.461 177.071L115.219 190.433L113.343 188.936L110.903 190.354L111.139 176.992L115.461 177.071Z" fill="#E0E0E0" class="style-69"></path>
            <path d="M132.314 253.871C131.678 251.984 130.488 250.333 128.899 249.135C127.31 247.937 125.397 247.247 123.409 247.157L121.745 155.438H129.759V156.808H131.267V153.523H118.815C118.815 153.523 120.298 221.189 121.375 247.247C120.84 247.334 120.312 247.462 119.796 247.629C117.805 248.3 116.079 249.589 114.87 251.309C113.66 253.028 113.03 255.089 113.07 257.192L84.6802 256.816L85.4913 260.549L113.476 259.779C113.512 259.907 113.542 260.034 113.585 260.167C113.976 261.427 114.615 262.596 115.464 263.605C116.313 264.615 117.355 265.444 118.529 266.044C119.702 266.644 120.984 267.003 122.299 267.1C123.613 267.197 124.933 267.03 126.182 266.608C127.431 266.187 128.583 265.52 129.571 264.646C130.559 263.772 131.362 262.709 131.933 261.52C132.504 260.331 132.832 259.04 132.898 257.722C132.963 256.404 132.765 255.087 132.314 253.847V253.871Z" fill="#E0E0E0" class="style-70"></path>
            <path d="M90.1221 266.009H91.6233L94.5168 260.324L92.1257 260.391L90.1221 266.009Z" fill="#E0E0E0" class="style-71"></path>
            <path d="M322.933 26.1836H294.507V34.0495H322.933V26.1836Z" fill="#EBEBEB" class="style-72"></path>
            <path d="M347.238 192.688H318.811V200.553H347.238V192.688Z" fill="#EBEBEB" class="style-73"></path>
            <path d="M315.718 34.043H287.292V41.9089H315.718V34.043Z" fill="#F5F5F5" class="style-74"></path>
            <path d="M329.144 200.801H308.72V208.667H329.144V200.801Z" fill="#F5F5F5" class="style-75"></path>
            <path d="M209.215 52.3555H147.98V177.719H209.215V52.3555Z" fill="#EBEBEB" class="style-76"></path>
            <path d="M178.573 52.3555C178.573 52.3555 166.019 109.223 147.956 113.107C147.956 113.107 155.22 153 155.916 177.719H147.98V52.3555H178.573Z" fill="#E0E0E0" class="style-77"></path>
            <path d="M178.804 52.3555C178.804 52.3555 191.358 109.223 209.446 113.107C209.446 113.107 202.182 153 201.492 177.719H209.446V52.3555H178.804Z" fill="#E0E0E0" class="style-78"></path>
            <path d="M179.718 52.1133H177.484V177.719H179.718V52.1133Z" fill="#FAFAFA" class="style-79"></path>
            <path d="M162.695 52.1133H162.145V177.719H162.695V52.1133Z" fill="#FAFAFA" class="style-80"></path>
            <path d="M195.753 52.1133H195.202V177.719H195.753V52.1133Z" fill="#FAFAFA" class="style-81"></path>
            <path d="M209.215 83.2617V83.7647L147.98 84.0132V83.5102L209.215 83.2617Z" fill="#FAFAFA" class="style-82"></path>
            <path d="M209.215 145.633V146.124L147.98 146.372V145.881L209.215 145.633Z" fill="#FAFAFA" class="style-83"></path>
            <path d="M209.215 113.781H147.98V115.872H209.215V113.781Z" fill="#FAFAFA" class="style-84"></path>
            <path d="M210.123 178.63H147.096V51.4492H210.123V178.63ZM148.888 176.812H208.307V53.2672H148.912L148.888 176.812Z" fill="#F5F5F5" class="style-85"></path>
            <path d="M388.412 52.3555H327.177V177.719H388.412V52.3555Z" fill="#EBEBEB" class="style-86"></path>
            <path d="M357.795 52.3555C357.795 52.3555 345.24 109.223 327.177 113.107C327.177 113.107 334.441 153 335.131 177.719H327.177V52.3555H357.795Z" fill="#E0E0E0" class="style-87"></path>
            <path d="M358 52.3555C358 52.3555 370.555 109.223 388.624 113.107C388.624 113.107 381.36 153 380.664 177.719H388.624V52.3555H358Z" fill="#E0E0E0" class="style-88"></path>
            <path d="M358.908 52.1133H356.675V177.719H358.908V52.1133Z" fill="#FAFAFA" class="style-89"></path>
            <path d="M341.886 52.1133H341.335V177.719H341.886V52.1133Z" fill="#FAFAFA" class="style-90"></path>
            <path d="M374.943 52.1133H374.393V177.719H374.943V52.1133Z" fill="#FAFAFA" class="style-91"></path>
            <path d="M388.412 83.2617V83.7647L327.177 84.0132V83.5102L388.412 83.2617Z" fill="#FAFAFA" class="style-92"></path>
            <path d="M388.412 145.633V146.124L327.177 146.372V145.881L388.412 145.633Z" fill="#FAFAFA" class="style-93"></path>
            <path d="M388.412 113.781H327.177V115.872H388.412V113.781Z" fill="#FAFAFA" class="style-94"></path>
            <path d="M389.32 178.63H326.275V51.4492H389.32V178.63ZM328.085 176.812H387.504V53.2672H328.091L328.085 176.812Z" fill="#F5F5F5" class="style-95"></path>
            <path d="M197.999 248.046C195.807 247.017 193.344 246.718 190.971 247.192C189.693 247.404 188.362 247.798 187.496 248.743C186.63 249.689 186.461 251.391 187.46 252.216C188.102 252.654 188.878 252.851 189.651 252.773C191.509 252.773 193.568 252.422 195.057 253.543C195.904 254.185 196.467 255.246 197.478 255.561C197.986 255.675 198.517 255.622 198.992 255.41C199.467 255.198 199.862 254.839 200.117 254.385C200.597 253.449 200.703 252.366 200.414 251.355C200.292 250.655 200.011 249.992 199.592 249.419C199.173 248.845 198.628 248.375 197.999 248.046Z" fill="#EBEBEB" class="style-96"></path>
            <path d="M202.902 238.576C201.595 240.449 199.052 239.831 197.872 238.685C196.691 237.54 196.195 235.892 195.741 234.316C194.706 230.68 193.659 226.929 194.433 223.257C194.612 222.135 195.101 221.085 195.844 220.227C196.217 219.809 196.692 219.496 197.223 219.318C197.754 219.141 198.322 219.105 198.871 219.215C200.148 219.554 200.947 220.808 201.522 222.008C203.221 225.536 204.11 229.4 204.125 233.316C204.125 235.134 203.919 237.049 202.914 238.552" fill="#EBEBEB" class="style-97"></path>
            <path d="M206.491 241.261C205.82 240.382 205.374 239.352 205.19 238.261C205.007 237.17 205.092 236.051 205.438 235.001C206.153 232.899 207.435 231.036 209.143 229.619C211.086 227.89 213.348 226.558 215.801 225.698C216.749 225.319 217.766 225.147 218.786 225.196C219.294 225.225 219.788 225.378 220.223 225.642C220.659 225.906 221.024 226.273 221.286 226.711C221.988 228.044 221.316 229.662 220.589 230.953C219.317 233.288 217.86 235.517 216.231 237.619C214.93 239.303 213.434 240.964 211.443 241.727C209.451 242.491 207.381 242.4 206.328 241.018" fill="#EBEBEB" class="style-98"></path>
            <path d="M202.993 266.792C202.919 266.515 202.878 266.23 202.872 265.943C202.811 265.337 202.738 264.543 202.648 263.622C202.557 262.701 202.393 261.483 202.127 260.217C201.828 258.85 201.423 257.509 200.916 256.205C200.408 254.901 199.717 253.677 198.864 252.569C198.173 251.668 197.29 250.933 196.28 250.417C195.593 250.075 194.855 249.845 194.094 249.739C193.81 249.729 193.528 249.686 193.253 249.612C193.539 249.583 193.827 249.583 194.113 249.612C194.9 249.674 195.669 249.879 196.383 250.218C197.451 250.72 198.388 251.465 199.119 252.393C200.005 253.515 200.719 254.763 201.237 256.096C201.757 257.42 202.162 258.787 202.448 260.18C202.666 261.318 202.818 262.467 202.902 263.622C202.975 264.61 202.999 265.404 203.011 265.955C203.032 266.234 203.026 266.514 202.993 266.792Z" fill="#E0E0E0" class="style-99"></path>
            <path d="M202.829 265.004C202.815 264.873 202.815 264.741 202.829 264.61C202.829 264.313 202.829 263.943 202.829 263.482C202.829 262.507 202.829 261.058 202.781 259.337C202.69 255.841 202.393 251.017 201.812 245.708C201.231 240.4 200.45 235.625 199.742 232.201C199.573 231.34 199.409 230.565 199.246 229.892C199.082 229.219 198.967 228.625 198.846 228.153C198.725 227.68 198.652 227.347 198.58 227.056C198.542 226.93 198.52 226.799 198.513 226.668C198.569 226.787 198.614 226.91 198.646 227.038C198.737 227.322 198.84 227.68 198.973 228.122C199.106 228.565 199.27 229.171 199.439 229.843C199.609 230.516 199.803 231.292 199.984 232.146C200.947 236.611 201.672 241.124 202.157 245.666C202.763 250.987 203.005 255.829 203.029 259.337C203.029 261.089 203.029 262.507 202.962 263.489C202.962 263.943 202.914 264.319 202.896 264.616C202.89 264.747 202.868 264.878 202.829 265.004Z" fill="#E0E0E0" class="style-100"></path>
            <path d="M202.787 255.663C202.757 255.272 202.757 254.879 202.787 254.488C202.829 253.73 202.932 252.633 203.144 251.294C203.403 249.727 203.749 248.175 204.179 246.646C204.697 244.768 205.346 242.93 206.122 241.144C207.482 237.855 209.428 234.843 211.867 232.254C212.286 231.809 212.733 231.392 213.205 231.005C213.411 230.829 213.592 230.654 213.78 230.52L214.3 230.157C214.613 229.926 214.945 229.723 215.293 229.551C214.149 230.446 213.063 231.413 212.043 232.447C209.699 235.063 207.817 238.059 206.48 241.307C205.712 243.081 205.061 244.903 204.53 246.761C204.087 248.275 203.719 249.81 203.429 251.361C203.18 252.688 203.041 253.785 202.956 254.524C202.932 254.908 202.875 255.289 202.787 255.663Z" fill="#E0E0E0" class="style-101"></path>
            <path d="M379.853 267.247C379.853 267.332 311.414 267.405 227.006 267.405C142.599 267.405 74.1475 267.332 74.1475 267.247C74.1475 267.163 142.55 267.09 227.006 267.09C311.462 267.09 379.853 267.163 379.853 267.247Z" fill="#263238" class="style-102"></path>
            <path d="M337.05 247.459C328.248 241.69 326.014 230.649 326.111 220.674C326.111 219.007 325.905 217.262 326.668 215.784C327.431 214.305 329.277 213.263 330.803 213.923C332.068 214.475 332.619 215.899 333.375 217.074C334.353 218.644 335.755 219.905 337.419 220.71C338.502 221.238 339.84 221.541 340.881 220.941C342.316 220.11 342.437 218.123 342.389 216.468C342.292 213.386 342.197 210.303 342.104 207.221C341.96 205.569 342.089 203.904 342.486 202.294C342.97 200.712 344.084 199.222 345.67 198.743C347.256 198.264 349.241 199.125 349.616 200.737C349.696 201.42 349.731 202.109 349.719 202.797C349.718 203.141 349.818 203.478 350.008 203.765C350.197 204.052 350.468 204.276 350.785 204.409C351.463 204.591 352.116 204.106 352.601 203.609C354.289 201.894 355.409 199.712 356.457 197.549C357.504 195.386 358.509 193.162 360.034 191.307C361.56 189.453 363.666 187.95 366.087 187.756C368.509 187.562 371.075 188.968 371.735 191.277C372.395 193.586 371.081 195.998 369.629 197.943C367.468 200.792 364.91 203.314 362.032 205.433C361.468 205.755 361.022 206.25 360.76 206.845C360.428 207.96 361.511 209.033 362.619 209.366C363.878 209.748 365.228 209.584 366.541 209.705C367.855 209.827 369.278 210.36 369.804 211.572C370.531 213.244 369.144 215.05 367.764 216.25C364.99 218.643 361.829 220.547 358.418 221.88C357.207 222.353 355.906 222.777 354.937 223.656C353.969 224.534 353.4 226.037 354.029 227.188C354.659 228.34 356.208 228.673 357.492 228.4C358.775 228.128 359.913 227.413 361.124 226.928C363.4 226.013 366.378 226.098 367.897 228.031C368.365 228.693 368.67 229.456 368.787 230.259C368.905 231.061 368.833 231.88 368.575 232.649C368.056 234.182 367.232 235.594 366.154 236.8C362.594 241.223 357.932 244.628 352.637 246.671C347.34 248.629 342.407 249.247 337.05 247.453" fill="#039D55" class="style-103"></path>
            <path d="M369.253 189.07C369.253 189.07 369.205 189.131 369.096 189.234L368.612 189.682C368.182 190.076 367.546 190.658 366.753 191.428C364.504 193.591 362.421 195.923 360.525 198.403C359.314 199.966 358.061 201.736 356.753 203.657C355.446 205.578 354.06 207.644 352.692 209.868C351.24 212.184 349.918 214.58 348.733 217.043C348.127 218.322 347.576 219.661 347.02 221.025C346.463 222.388 345.906 223.77 345.343 225.17C343.181 230.415 341.318 235.779 339.762 241.235C338.513 245.696 337.69 250.265 337.304 254.882C337.056 257.995 337.08 261.124 337.377 264.232C337.492 265.329 337.601 266.184 337.691 266.76C337.728 267.034 337.758 267.252 337.782 267.414C337.792 267.488 337.792 267.564 337.782 267.638C337.757 267.568 337.738 267.494 337.728 267.42C337.697 267.256 337.655 267.044 337.607 266.766C337.492 266.196 337.364 265.341 337.231 264.245C336.887 261.129 336.83 257.989 337.062 254.864C337.427 250.229 338.238 245.641 339.483 241.162C341.027 235.69 342.887 230.312 345.052 225.055C345.615 223.655 346.178 222.273 346.729 220.903C347.28 219.534 347.831 218.195 348.454 216.91C349.651 214.44 350.982 212.039 352.443 209.717C353.823 207.493 355.216 205.432 356.535 203.511C357.855 201.59 359.12 199.827 360.343 198.269C362.263 195.797 364.376 193.481 366.663 191.343C367.474 190.585 368.121 190.016 368.563 189.64L369.072 189.21L369.253 189.07Z" fill="#263238" class="style-104"></path>
            <path d="M348.557 216.885C348.473 216.657 348.413 216.422 348.376 216.182C348.279 215.728 348.158 215.061 348.018 214.243C347.74 212.601 347.413 210.322 347.11 207.807C346.808 205.292 346.451 203.02 346.148 201.384C345.997 200.565 345.869 199.911 345.779 199.45C345.707 199.228 345.662 198.998 345.646 198.766C345.743 198.988 345.818 199.219 345.869 199.456C346.003 199.905 346.16 200.559 346.336 201.371C346.693 203.002 347.068 205.274 347.395 207.795L348.212 214.243C348.315 215.012 348.4 215.667 348.472 216.194C348.528 216.42 348.557 216.652 348.557 216.885Z" fill="#263238" class="style-105"></path>
            <path d="M369.992 212.395C369.711 212.459 369.426 212.499 369.138 212.516C368.588 212.577 367.789 212.661 366.808 212.801C364.841 213.055 362.135 213.504 359.175 214.134C356.215 214.764 353.557 215.443 351.65 215.994C350.694 216.261 349.925 216.497 349.392 216.661C349.121 216.761 348.842 216.836 348.557 216.885C348.812 216.746 349.079 216.632 349.356 216.546C349.877 216.352 350.633 216.079 351.584 215.782C353.478 215.176 356.136 214.461 359.108 213.831C361.641 213.283 364.201 212.866 366.778 212.583C367.77 212.474 368.594 212.419 369.126 212.395C369.414 212.363 369.704 212.363 369.992 212.395Z" fill="#263238" class="style-106"></path>
            <path d="M339.756 240.752C339.696 240.673 339.645 240.588 339.604 240.498L339.217 239.734C338.884 239.074 338.424 238.104 337.879 236.898C336.777 234.474 335.367 231.105 333.86 227.354C332.352 223.603 330.978 220.215 329.931 217.785L328.696 214.906L328.363 214.125C328.317 214.034 328.283 213.939 328.26 213.84C328.26 213.84 328.327 213.925 328.418 214.094C328.508 214.264 328.648 214.555 328.805 214.858C329.138 215.518 329.592 216.488 330.143 217.694C331.238 220.118 332.655 223.487 334.162 227.239C335.67 230.99 337.044 234.377 338.091 236.807L339.338 239.686C339.471 240.001 339.58 240.292 339.671 240.467C339.713 240.558 339.742 240.654 339.756 240.752Z" fill="#263238" class="style-107"></path>
            <path d="M368.152 230.957C368.06 231.005 367.965 231.045 367.867 231.078L367.044 231.375L364.017 232.424C361.451 233.314 357.915 234.587 354.017 235.999C350.119 237.411 346.572 238.647 343.987 239.483C342.691 239.908 341.644 240.235 340.912 240.447L340.07 240.689C339.973 240.724 339.871 240.744 339.768 240.75C339.856 240.696 339.952 240.655 340.052 240.629L340.875 240.332L343.902 239.277C346.469 238.387 350.004 237.12 353.902 235.708C357.8 234.296 361.348 233.06 363.933 232.218C365.228 231.799 366.275 231.472 367.008 231.26L367.849 231.018C367.947 230.983 368.048 230.963 368.152 230.957Z" fill="#263238" class="style-108"></path>
            <path d="M94.4381 103.07C113.283 103.07 128.561 87.7764 128.561 68.9102C128.561 50.0441 113.283 34.75 94.4381 34.75C75.5927 34.75 60.3154 50.0441 60.3154 68.9102C60.3154 87.7764 75.5927 103.07 94.4381 103.07Z" fill="#FAFAFA" class="style-109"></path>
            <path d="M94.4321 103.585C87.5815 103.585 80.8848 101.551 75.1887 97.7412C69.4927 93.931 65.0531 88.5155 62.4315 82.1794C59.8099 75.8433 59.124 68.8712 60.4605 62.1449C61.797 55.4185 65.0958 49.24 69.9399 44.3906C74.784 39.5411 80.9558 36.2386 87.6747 34.9007C94.3937 33.5627 101.358 34.2494 107.687 36.8739C114.016 39.4984 119.426 43.9428 123.232 49.6451C127.038 55.3475 129.069 62.0516 129.069 68.9097C129.058 78.1027 125.405 86.916 118.912 93.4165C112.419 99.9169 103.615 103.574 94.4321 103.585ZM94.4321 35.2646C87.7836 35.2646 81.2844 37.2384 75.7565 40.9363C70.2285 44.6342 65.9201 49.8901 63.3761 56.0394C60.8321 62.1887 60.1668 68.9552 61.4643 75.483C62.7618 82.0109 65.9638 88.0069 70.6655 92.7129C75.3671 97.4188 81.3571 100.623 87.878 101.921C94.3989 103.219 101.158 102.552 107.3 100.004C113.442 97.4558 118.691 93.1417 122.384 87.6069C126.077 82.0722 128.047 75.5655 128.046 68.9097C128.037 59.9884 124.492 51.4353 118.19 45.1275C111.888 38.8197 103.344 35.2726 94.4321 35.2646Z" fill="#263238" class="style-110"></path>
            <path d="M94.8013 45.1893C94.6691 44.1039 94.6246 43.0095 94.6682 41.9169C94.6243 40.8243 94.6689 39.73 94.8013 38.6445C94.9338 39.73 94.9783 40.8243 94.9345 41.9169C94.9781 43.0095 94.9335 44.1039 94.8013 45.1893Z" fill="#263238" class="style-111"></path>
            <path d="M77.1561 51.6438C76.3732 51.0282 75.6479 50.3426 74.989 49.5955C74.267 48.9095 73.6067 48.1612 73.0156 47.3594C73.7985 47.975 74.5238 48.6606 75.1827 49.4077C75.9047 50.0937 76.565 50.842 77.1561 51.6438Z" fill="#263238" class="style-112"></path>
            <path d="M72.64 69.6261C71.2762 69.7585 69.9053 69.8031 68.5358 69.7594C67.1663 69.8031 65.7954 69.7585 64.4316 69.6261C65.7954 69.4937 67.1663 69.4491 68.5358 69.4928C69.9053 69.4491 71.2762 69.4937 72.64 69.6261Z" fill="#263238" class="style-113"></path>
            <path d="M77.0772 86.3945C76.5541 87.1192 75.973 87.8001 75.3399 88.4307C74.7707 89.1242 74.1203 89.7468 73.4028 90.2851C73.9315 89.5631 74.5143 88.8824 75.1462 88.2489C75.7122 87.5545 76.3607 86.9318 77.0772 86.3945Z" fill="#263238" class="style-114"></path>
            <path d="M115.825 89.9375C120.803 84.92 123.872 78.3171 124.499 71.2727C125.565 58.5467 120.02 51.8807 115.916 47.4023L112.35 50.8202C115.982 54.7168 121.612 62.4614 120.086 71.6484C119.135 77.3641 116.578 82.6905 112.713 87.0044L115.825 89.9375Z" fill="#039D55" class="style-115"></path>
            <path d="M93.6029 73.287C92.8275 73.1339 92.1147 72.7541 91.5549 72.1954C90.9952 71.6368 90.6135 70.9245 90.4582 70.1486C90.3029 69.3727 90.381 68.5682 90.6826 67.8368C90.9842 67.1054 91.4958 66.4799 92.1525 66.0396C92.8093 65.5993 93.5817 65.364 94.3721 65.3633C95.1625 65.3626 95.9354 65.5966 96.5929 66.0358C97.2504 66.475 97.763 67.0995 98.0659 67.8304C98.3687 68.5613 98.4482 69.3657 98.2943 70.1418C98.1929 70.6569 97.9911 71.147 97.7004 71.5839C97.4097 72.0208 97.0358 72.3961 96.6 72.6882C96.1643 72.9803 95.6753 73.1836 95.161 73.2863C94.6468 73.3891 94.1173 73.3893 93.6029 73.287Z" fill="#263238" class="style-116"></path>
            <path d="M108.591 78.8048L85.431 61.043L83.2275 64.4063L108.591 78.8048Z" fill="#263238" class="style-117"></path>
            <path d="M124.614 68.6249C124.614 68.6249 124.614 68.4613 124.614 68.134C124.614 67.8068 124.614 67.328 124.548 66.6978C124.257 62.3905 123.048 58.1955 121.002 54.3957C118.955 50.5958 116.118 47.2793 112.683 44.6697C108.954 41.8346 104.616 39.909 100.013 39.046C97.4597 38.5704 94.8553 38.4278 92.2651 38.6218C90.9103 38.7232 89.5635 38.9135 88.2336 39.1915C86.8701 39.4843 85.5273 39.867 84.2142 40.3368C78.4164 42.4509 73.4085 46.2991 69.8699 51.3596C66.3313 56.4201 64.4333 62.4477 64.4333 68.6249C64.4333 74.8021 66.3313 80.8297 69.8699 85.8902C73.4085 90.9506 78.4164 94.7989 84.2142 96.913C85.5281 97.382 86.8707 97.7666 88.2336 98.0644C92.1162 98.8732 96.1187 98.9205 100.019 98.2038C104.62 97.3392 108.955 95.4138 112.683 92.5801C116.117 89.9694 118.953 86.6526 120.999 82.853C123.046 79.0533 124.256 74.8589 124.548 70.552C124.602 69.946 124.59 69.4369 124.614 69.1157C124.638 68.7946 124.614 68.6249 124.614 68.6249C124.614 68.6249 124.614 69.2915 124.572 70.552C124.471 72.3916 124.197 74.2176 123.755 76.006C123.059 78.8516 121.949 81.5794 120.462 84.1021C118.47 87.4383 115.861 90.3631 112.774 92.7194C109.026 95.5826 104.663 97.5311 100.032 98.4098C97.4587 98.8955 94.8335 99.0422 92.2227 98.8461C90.8571 98.7554 89.4997 98.567 88.1609 98.2825C86.7865 97.9913 85.4334 97.6066 84.1113 97.1311C78.2598 95.0064 73.2038 91.1292 69.6308 86.0268C66.0577 80.9243 64.1409 74.8441 64.1409 68.6128C64.1409 62.3814 66.0577 56.3012 69.6308 51.1987C73.2038 46.0963 78.2598 42.2192 84.1113 40.0944C85.4334 39.6189 86.7865 39.2342 88.1609 38.943C89.5007 38.664 90.8577 38.4757 92.2227 38.3794C94.8338 38.1853 97.459 38.334 100.032 38.8218C102.435 39.265 104.774 40.0081 106.993 41.0337C109.045 41.9675 110.985 43.1324 112.774 44.5061C115.863 46.8695 118.473 49.8028 120.462 53.1476C121.943 55.6603 123.053 58.3753 123.755 61.2074C124.197 62.9959 124.47 64.8219 124.572 66.6614C124.638 67.9583 124.614 68.6249 124.614 68.6249Z" fill="#263238" class="style-118"></path>
            <path d="M124.626 68.431C123.559 68.567 122.481 68.6117 121.406 68.5643C120.329 68.6115 119.249 68.5669 118.179 68.431C119.249 68.2987 120.329 68.2541 121.406 68.2976C122.481 68.2539 123.558 68.2985 124.626 68.431Z" fill="#263238" class="style-119"></path>
            <path d="M115.83 89.9373C114.923 89.2544 114.073 88.4984 113.288 87.6769C112.44 86.9197 111.651 86.0992 110.927 85.2227C111.835 85.9056 112.685 86.6616 113.47 87.483C114.319 88.2386 115.108 89.0591 115.83 89.9373Z" fill="#263238" class="style-120"></path>
            <path d="M115.915 47.4023C116 47.4811 115.007 48.4992 113.73 49.6688C112.983 50.4088 112.173 51.082 111.309 51.6807C111.994 50.8835 112.743 50.1438 113.548 49.4688C114.19 48.8628 114.759 48.3598 115.207 47.9902C115.432 47.7817 115.669 47.5854 115.915 47.4023Z" fill="#263238" class="style-121"></path>
            <path d="M110.522 79.9922C110.014 80.5276 109.373 80.9184 108.665 81.125C107.956 81.3315 107.206 81.3463 106.49 81.1678C107.197 81.1254 107.9 81.0261 108.591 80.8709C109.256 80.6291 109.902 80.3353 110.522 79.9922Z" fill="#263238" class="style-122"></path>
            <path d="M111.569 80.9258C111.369 81.1902 111.115 81.4091 110.825 81.5681C110.154 81.9981 109.405 82.2906 108.621 82.4287C107.836 82.5644 107.032 82.5459 106.254 82.3741C105.928 82.3234 105.617 82.2036 105.34 82.0226C106.396 82.3037 107.5 82.3512 108.576 82.1618C109.651 81.9724 110.673 81.5506 111.569 80.9258Z" fill="#263238" class="style-123"></path>
            <path d="M86.9565 58.6007C86.2625 58.4674 85.5565 58.4065 84.8499 58.4189C84.1493 58.522 83.4615 58.6987 82.7979 58.9461C83.3619 58.4581 84.0802 58.1855 84.8257 58.1765C85.5637 58.065 86.3173 58.2151 86.9565 58.6007Z" fill="#263238" class="style-124"></path>
            <path d="M87.4164 57.7034C86.4181 57.5246 85.4027 57.4595 84.3898 57.5095C83.3875 57.6524 82.4046 57.9086 81.46 58.273C82.311 57.6601 83.3189 57.3028 84.3656 57.2428C85.4056 57.0988 86.4651 57.2588 87.4164 57.7034Z" fill="#263238" class="style-125"></path>
            <path d="M136.339 265.151L137.326 258.982L126.291 257.164L124.729 266.375L125.401 266.533C128.428 267.218 140.813 269.599 142.956 269.29C145.347 268.927 136.339 265.151 136.339 265.151Z" fill="#039D55" class="style-126"></path>
            <path d="M143.307 268.743C143.307 268.846 139.148 268.27 134.051 267.452C128.954 266.634 124.838 265.888 124.856 265.785C124.874 265.682 129.015 266.258 134.112 267.076C139.208 267.894 143.325 268.64 143.307 268.743Z" fill="#263238" class="style-127"></path>
            <path d="M136.333 266.442C136.248 266.381 136.375 266.066 136.666 265.781C136.956 265.497 137.271 265.375 137.332 265.46C137.392 265.545 137.199 265.793 136.932 266.066C136.666 266.339 136.418 266.509 136.333 266.442Z" fill="#263238" class="style-128"></path>
            <path d="M136.969 266.962C136.878 266.908 136.969 266.55 137.265 266.205C137.562 265.859 137.871 265.665 137.937 265.744C138.004 265.823 137.822 266.114 137.562 266.441C137.302 266.768 137.066 267.017 136.969 266.962Z" fill="#263238" class="style-129"></path>
            <path d="M128.191 266.277C128.143 266.277 128.004 266.113 127.804 265.804C127.572 265.402 127.3 265.025 126.993 264.677C126.691 264.344 126.307 264.098 125.879 263.962C125.552 263.865 125.322 263.883 125.316 263.823C125.31 263.762 125.534 263.659 125.921 263.701C126.437 263.777 126.91 264.03 127.259 264.416C127.593 264.788 127.855 265.217 128.034 265.683C128.185 266.034 128.234 266.259 128.191 266.277Z" fill="#263238" class="style-130"></path>
            <path d="M136.551 265.292C136.588 265.382 136.37 265.57 136.031 265.631C135.692 265.691 135.425 265.631 135.425 265.51C135.425 265.389 135.667 265.322 135.958 265.261C136.249 265.201 136.509 265.189 136.551 265.292Z" fill="#263238" class="style-131"></path>
            <path d="M136.472 264.658C136.472 264.767 136.158 264.81 135.782 264.755C135.407 264.701 135.128 264.573 135.177 264.47C135.225 264.367 135.504 264.325 135.861 264.379C136.218 264.434 136.478 264.555 136.472 264.658Z" fill="#263238" class="style-132"></path>
            <path d="M175.377 263.816L175.147 257.574L163.966 257.914L164.214 267.252H164.911C168.004 267.337 180.649 267.252 182.665 266.543C184.941 265.767 175.377 263.816 175.377 263.816Z" fill="#039D55" class="style-133"></path>
            <path d="M182.907 265.988C182.907 266.097 178.737 266.334 173.579 266.528C168.422 266.722 164.239 266.782 164.233 266.679C164.227 266.576 168.41 266.334 173.567 266.146C178.725 265.958 182.901 265.885 182.907 265.988Z" fill="#263238" class="style-134"></path>
            <path d="M175.619 265.083C175.522 265.041 175.589 264.707 175.819 264.374C176.049 264.041 176.334 263.853 176.424 263.926C176.515 263.998 176.364 264.277 176.152 264.586C175.94 264.895 175.716 265.132 175.619 265.083Z" fill="#263238" class="style-135"></path>
            <path d="M176.346 265.472C176.249 265.442 176.285 265.066 176.491 264.672C176.697 264.278 176.975 264.03 177.06 264.066C177.145 264.102 177.018 264.448 176.83 264.817C176.642 265.187 176.449 265.508 176.346 265.472Z" fill="#263238" class="style-136"></path>
            <path d="M167.599 266.515C167.556 266.515 167.387 266.388 167.132 266.121C166.823 265.776 166.483 265.459 166.116 265.176C165.76 264.913 165.343 264.746 164.905 264.691C164.566 264.691 164.342 264.721 164.3 264.691C164.257 264.661 164.481 264.485 164.905 264.449C165.426 264.42 165.941 264.577 166.358 264.891C166.757 265.19 167.097 265.559 167.363 265.982C167.544 266.267 167.641 266.485 167.599 266.515Z" fill="#263238" class="style-137"></path>
            <path d="M175.607 263.915C175.668 264 175.486 264.224 175.171 264.351C174.856 264.479 174.566 264.448 174.566 264.351C174.566 264.254 174.766 264.115 175.044 264C175.323 263.885 175.547 263.824 175.607 263.915Z" fill="#263238" class="style-138"></path>
            <path d="M175.413 263.313C175.413 263.416 175.129 263.519 174.754 263.537C174.378 263.555 174.076 263.488 174.076 263.379C174.076 263.27 174.372 263.173 174.735 263.155C175.099 263.137 175.395 263.21 175.413 263.313Z" fill="#263238" class="style-139"></path>
            <path d="M190.535 108.433L189.93 106.397C189.714 105.222 189.383 104.071 188.943 102.961C188.733 102.401 188.43 101.881 188.047 101.422C187.597 100.834 187.069 100.311 186.479 99.8643C185.874 99.5067 186.025 98.6523 186.63 98.5856C187.236 98.519 188.864 99.4704 190.111 101.282C191.358 103.094 191.588 99.6825 191.697 98.4402C191.806 97.1979 192.302 93.374 192.72 93.1195C193.325 92.7377 193.846 93.3195 193.713 94.5557C193.58 95.792 193.428 99.5371 194.082 99.5795C194.736 99.6219 195.19 93.2892 195.19 93.2892C195.19 93.2892 195.069 91.9136 195.832 91.9378C197.2 91.9802 196.213 98.6644 196.104 99.2947C196.031 99.737 196.739 99.8037 196.8 99.331C196.861 98.8583 197.109 92.9983 198.501 93.0286C199.572 93.0286 197.744 98.5311 198.362 99.7916C198.979 101.052 199.324 95.4769 200.347 95.5496C200.723 95.5496 201.007 95.6405 200.347 98.7977C199.687 101.955 196.431 110.457 196.431 110.457L190.486 108.33" fill="#FFBE9D" class="style-140"></path>
            <path d="M149.638 106.76L159.699 107.506C159.832 106.342 160.032 101.815 160.032 101.815C160.032 101.815 164.233 101.421 164.536 97.2581C164.838 93.0949 164.717 83.4898 164.717 83.4898C162.415 82.3261 159.843 81.7991 157.269 81.9631C154.695 82.1272 152.211 82.9764 150.074 84.4231L149.427 84.8594L149.638 106.76Z" fill="#FFBE9D" class="style-141"></path>
            <path d="M163.313 90.5913C163.2 90.4756 163.138 90.3199 163.141 90.1586C163.143 89.9972 163.209 89.8433 163.325 89.7308C163.44 89.6183 163.596 89.5564 163.757 89.5587C163.918 89.5609 164.072 89.6272 164.184 89.7429C164.24 89.8002 164.284 89.8679 164.313 89.9422C164.343 90.0165 164.357 90.0958 164.356 90.1757C164.355 90.2556 164.338 90.3345 164.307 90.4079C164.275 90.4813 164.229 90.5477 164.172 90.6035C164.115 90.6592 164.047 90.703 163.973 90.7326C163.899 90.7621 163.82 90.7767 163.74 90.7756C163.66 90.7745 163.581 90.7576 163.508 90.726C163.435 90.6944 163.368 90.6486 163.313 90.5913Z" fill="#263238" class="style-142"></path>
            <path d="M164.384 89.6909C164.312 89.7575 163.924 89.4182 163.349 89.3818C162.774 89.3454 162.344 89.6121 162.284 89.5333C162.223 89.4545 162.332 89.3757 162.52 89.2485C162.775 89.0848 163.077 89.0102 163.38 89.0364C163.681 89.0563 163.967 89.1732 164.197 89.3697C164.36 89.5394 164.415 89.6909 164.384 89.6909Z" fill="#263238" class="style-143"></path>
            <path d="M157.75 89.7935C157.882 89.8859 157.971 90.0269 157.999 90.1855C158.027 90.344 157.991 90.5071 157.898 90.6389C157.806 90.7707 157.665 90.8604 157.507 90.8882C157.348 90.9161 157.186 90.8798 157.054 90.7874C156.988 90.7414 156.933 90.683 156.89 90.6154C156.847 90.5478 156.818 90.4724 156.805 90.3935C156.791 90.3146 156.793 90.2338 156.811 90.1558C156.829 90.0778 156.862 90.0041 156.909 89.939C157.001 89.8083 157.142 89.7197 157.299 89.6924C157.457 89.6652 157.619 89.7015 157.75 89.7935Z" fill="#263238" class="style-144"></path>
            <path d="M158.125 89.824C158.058 89.8907 157.671 89.5513 157.096 89.5149C156.521 89.4786 156.091 89.7452 156.03 89.6664C155.97 89.5877 156.073 89.5089 156.267 89.3816C156.519 89.2171 156.82 89.1424 157.12 89.1695C157.422 89.1881 157.709 89.3052 157.937 89.5028C158.107 89.6907 158.161 89.7937 158.125 89.824Z" fill="#263238" class="style-145"></path>
            <path d="M160.135 94.6956C160.438 94.6005 160.755 94.5555 161.073 94.5623C161.224 94.5623 161.345 94.5259 161.364 94.4472C161.378 94.2953 161.351 94.1423 161.285 94.0048C161.158 93.6169 161.037 93.223 160.904 92.7928C160.534 91.7816 160.267 90.7362 160.104 89.6719C160.57 90.6434 160.939 91.6582 161.206 92.7019C161.327 93.114 161.442 93.5139 161.563 93.9139C161.642 94.1062 161.656 94.3185 161.606 94.5199C161.58 94.5756 161.543 94.6248 161.496 94.6637C161.448 94.7026 161.393 94.7301 161.333 94.7441C161.249 94.7553 161.163 94.7553 161.079 94.7441C160.764 94.7776 160.445 94.7612 160.135 94.6956Z" fill="#263238" class="style-146"></path>
            <path d="M158.234 95.2157C157.659 94.8218 159.826 96.1913 160.994 96.2035C160.781 96.4925 160.477 96.7009 160.131 96.7944C159.784 96.888 159.417 96.8612 159.087 96.7186C157.871 96.1853 158.234 95.2157 158.234 95.2157Z" fill="white" class="style-147"></path>
            <path d="M160.038 101.754C158.116 101.669 156.262 101.015 154.711 99.875C154.711 99.875 155.807 102.953 159.983 102.832L160.038 101.754Z" fill="#EB996E" class="style-148"></path>
            <path d="M149.517 89.0847L150.02 92.0723L151.872 92.0359L151.751 85.8123C151.751 85.8123 159.1 86.3819 159.941 83.4368C159.941 83.4368 162.102 86.691 164.784 86.6789C164.784 86.6789 167.133 86.4365 164.899 83.158C161.448 78.1161 149.16 82.1702 149.16 82.1702C148.85 83.0913 148.701 84.0588 148.718 85.0305C148.67 88.2545 149.517 89.0847 149.517 89.0847Z" fill="#263238" class="style-149"></path>
            <path d="M148.506 86.0532L164.85 82.805C164.85 82.805 163.688 76.2481 154.856 78.157C146.285 80.0296 148.506 86.0532 148.506 86.0532Z" fill="#039D55" class="style-150"></path>
            <path d="M148.506 86.0548C148.736 86.0123 168.519 86.479 169.929 83.7398C170.601 82.4309 164.747 82.3945 164.747 82.3945L164.881 82.8066L148.506 86.0548Z" fill="#039D55" class="style-151"></path>
            <path opacity="0.2" d="M148.506 86.0548C148.736 86.0123 168.519 86.479 169.929 83.7398C170.601 82.4309 164.747 82.3945 164.747 82.3945L164.881 82.8066L148.506 86.0548Z" fill="black" class="style-152"></path>
            <path d="M150.226 88.5572C150.135 88.5208 147.138 88.133 147.423 91.672C147.707 95.211 151.182 93.7566 151.182 93.6536C151.182 93.5506 150.226 88.5572 150.226 88.5572Z" fill="#FFBE9D" class="style-153"></path>
            <path d="M150.002 92.4952C150.002 92.4952 149.941 92.5437 149.838 92.6043C149.696 92.6788 149.531 92.6941 149.378 92.6467C149.139 92.5206 148.937 92.3328 148.795 92.1027C148.652 91.8725 148.574 91.6085 148.567 91.3378C148.532 91.0349 148.567 90.7279 148.67 90.4409C148.692 90.3266 148.743 90.2197 148.817 90.1298C148.891 90.0399 148.986 89.9698 149.094 89.9258C149.163 89.9018 149.238 89.9027 149.306 89.9281C149.375 89.9535 149.432 90.002 149.469 90.0652C149.523 90.1621 149.511 90.2349 149.529 90.2409C149.548 90.247 149.602 90.1743 149.529 90.0288C149.502 89.9425 149.447 89.8679 149.372 89.8167C149.269 89.753 149.146 89.7313 149.027 89.7561C148.883 89.7975 148.752 89.8749 148.646 89.9809C148.54 90.0869 148.463 90.218 148.422 90.3621C148.299 90.6822 148.253 91.0269 148.289 91.3681C148.297 91.6853 148.398 91.9931 148.58 92.2529C148.762 92.5127 149.016 92.7129 149.312 92.8285C149.409 92.8475 149.509 92.8449 149.605 92.8208C149.702 92.7967 149.791 92.7518 149.868 92.6892C150.002 92.5861 150.014 92.5013 150.002 92.4952Z" fill="#EB996E" class="style-154"></path>
            <path d="M159.868 81.0279L157.864 81.0664L157.903 83.0719L159.906 83.0334L159.868 81.0279Z" fill="#263238" class="style-155"></path>
            <path d="M159.847 79.9375L158.843 79.957L158.862 80.9628L159.867 80.9433L159.847 79.9375Z" fill="#263238" class="style-156"></path>
            <path d="M189.796 105.829C189.796 105.829 186.703 125.536 184.524 125.639C182.036 125.76 174.384 114.895 174.384 114.895C169.487 107.332 163.028 106.108 160.674 105.92V104.987L149.1 104.035C149.1 104.035 149.1 105.035 149.1 105.908H143.972C143.972 105.908 134.796 105.483 133.415 117.828L140.02 123.051L142.725 133.129C143.549 136.208 142.29 140.135 140.909 147.031L140.08 146.837L139.729 153.788C139.681 154.115 139.632 154.448 139.59 154.782H139.681L136.884 210.285L122.586 264.043L138.682 264.649L153.525 212.2C153.525 212.2 154.814 170.265 156.121 170.241C158.319 170.198 159.723 263.528 160.565 263.316C161.406 263.104 177.938 263.274 177.938 263.274C177.938 263.274 172.465 154.533 172.52 154.533L171.103 133.929L170.565 127.978C173.706 132.402 179.645 139.31 185.989 139.189C196.322 138.989 197.774 106.92 197.774 106.92L189.796 105.829Z" fill="#455A64" class="style-157"></path>
            <path d="M155.528 169.822C155.89 169.24 156.21 168.632 156.485 168.004C157.034 166.463 157.298 164.834 157.266 163.199C157.266 161.308 157.266 159.605 157.266 158.248C157.234 157.564 157.26 156.879 157.344 156.199C157.442 156.878 157.49 157.562 157.49 158.248C157.526 159.514 157.605 161.277 157.586 163.205C157.578 164.121 157.497 165.034 157.344 165.938C157.217 166.68 156.999 167.405 156.697 168.095C156.487 168.557 156.227 168.994 155.922 169.398C155.68 169.683 155.571 169.84 155.528 169.822Z" fill="#263238" class="style-158"></path>
            <path d="M146.031 161.015C146.09 161.199 146.118 161.392 146.115 161.585C146.146 161.955 146.182 162.482 146.2 163.149C146.293 164.893 145.945 166.634 145.189 168.209C144.742 169.077 144.079 169.816 143.264 170.354C142.591 170.779 141.819 171.021 141.024 171.057C140.491 171.084 139.957 170.993 139.463 170.79C139.278 170.726 139.108 170.622 138.966 170.487C139.613 170.756 140.313 170.876 141.012 170.839C141.76 170.769 142.479 170.517 143.107 170.105C143.872 169.584 144.496 168.88 144.923 168.057C145.651 166.525 146.015 164.845 145.988 163.149C145.966 162.437 145.98 161.725 146.031 161.015Z" fill="#263238" class="style-159"></path>
            <path d="M173.664 170.668C173.664 170.668 173.567 170.843 173.271 171.043C172.836 171.31 172.333 171.441 171.824 171.419C171.065 171.388 170.336 171.116 169.742 170.643C169.027 170.085 168.413 169.409 167.926 168.644C167.011 167.278 166.436 165.714 166.249 164.08C166.195 163.587 166.181 163.091 166.206 162.596C166.203 162.414 166.231 162.234 166.291 162.062C166.346 162.062 166.291 162.832 166.473 164.05C166.716 165.63 167.3 167.138 168.186 168.468C168.655 169.205 169.239 169.862 169.917 170.413C170.46 170.863 171.127 171.136 171.83 171.195C172.305 171.233 172.781 171.143 173.21 170.934C173.507 170.783 173.646 170.643 173.664 170.668Z" fill="#263238" class="style-160"></path>
            <path d="M139.42 261.077C139.42 261.168 135.849 261.108 131.454 260.95C127.059 260.793 123.488 260.581 123.488 260.502C123.488 260.423 127.059 260.471 131.46 260.635C135.861 260.799 139.426 260.993 139.42 261.077Z" fill="#263238" class="style-161"></path>
            <path d="M177.362 258.654C177.144 258.714 176.918 258.744 176.691 258.744L174.875 258.884C173.325 258.981 171.188 259.096 168.821 259.205C166.454 259.314 164.312 259.393 162.768 259.441L160.952 259.472C160.726 259.49 160.498 259.48 160.274 259.441C160.494 259.385 160.719 259.355 160.946 259.35L162.762 259.217C164.312 259.114 166.448 258.999 168.815 258.89C171.182 258.781 173.325 258.702 174.869 258.66L176.685 258.623C176.911 258.609 177.138 258.619 177.362 258.654Z" fill="#263238" class="style-162"></path>
            <path d="M147.665 171.105C147.674 171.184 147.674 171.263 147.665 171.342V172.039C147.665 172.645 147.635 173.566 147.616 174.717C147.562 177.038 147.459 180.401 147.29 184.558C147.12 188.716 146.89 193.649 146.563 199.145C146.4 201.89 146.218 204.769 145.958 207.75C145.831 209.241 145.716 210.78 145.455 212.295C145.195 213.81 144.85 215.355 144.535 216.913C141.775 229.306 138.676 240.371 136.254 248.328C135.044 252.309 133.954 255.491 133.191 257.691L132.265 260.199C132.156 260.472 132.078 260.69 132.011 260.848C131.991 260.924 131.96 260.998 131.92 261.066C131.931 260.989 131.951 260.914 131.981 260.842C132.035 260.678 132.108 260.46 132.205 260.181L133.076 257.648C133.803 255.442 134.892 252.249 136.042 248.267C138.403 240.298 141.49 229.221 144.227 216.84C144.56 215.295 144.892 213.762 145.147 212.247C145.401 210.732 145.522 209.217 145.649 207.726C145.897 204.744 146.085 201.866 146.254 199.127C146.593 193.673 146.86 188.697 147.047 184.546C147.235 180.395 147.374 177.05 147.477 174.711C147.52 173.56 147.556 172.663 147.58 172.032C147.58 171.742 147.592 171.511 147.616 171.342C147.629 171.262 147.645 171.183 147.665 171.105Z" fill="#263238" class="style-163"></path>
            <path d="M169.881 259.059C169.861 258.988 169.851 258.915 169.85 258.841C169.85 258.683 169.85 258.471 169.802 258.198C169.76 257.592 169.705 256.786 169.632 255.726C169.505 253.563 169.324 250.46 169.094 246.636C168.664 238.958 168.125 228.341 167.629 216.621C167.132 204.901 166.769 194.265 166.533 186.581C166.43 182.751 166.339 179.643 166.285 177.491C166.285 176.431 166.243 175.601 166.23 175.019V174.37C166.224 174.298 166.224 174.225 166.23 174.152C166.237 174.225 166.237 174.298 166.23 174.37C166.23 174.534 166.231 174.746 166.267 175.013C166.267 175.619 166.327 176.431 166.376 177.491C166.461 179.655 166.576 182.764 166.727 186.581C167.03 194.278 167.453 204.889 167.938 216.609C168.422 228.329 168.936 238.946 169.312 246.63C169.499 250.46 169.645 253.563 169.754 255.72C169.796 256.78 169.832 257.611 169.856 258.198C169.856 258.465 169.856 258.677 169.856 258.841C169.871 258.913 169.879 258.986 169.881 259.059Z" fill="#263238" class="style-164"></path>
            <path d="M156.588 104.645C156.633 105.187 156.633 105.732 156.588 106.275C156.571 107.635 156.437 108.991 156.188 110.329L156.152 110.529L155.97 110.432C155.583 110.232 155.183 110.02 154.79 109.783C153.107 108.802 151.678 107.778 150.619 107.075C149.56 106.372 148.912 105.941 148.93 105.905C149.568 106.168 150.177 106.497 150.746 106.887C151.836 107.547 153.288 108.541 154.953 109.511C155.347 109.741 155.74 109.959 156.115 110.159L155.891 110.262C156.146 108.942 156.312 107.605 156.388 106.263C156.411 105.719 156.478 105.178 156.588 104.645Z" fill="#263238" class="style-165"></path>
            <path d="M160.704 105.997C160.629 106.507 160.52 107.011 160.377 107.506C160.262 107.967 160.141 108.524 159.941 109.136C159.839 109.457 159.715 109.771 159.572 110.076C159.526 110.182 159.45 110.272 159.354 110.336C159.288 110.381 159.209 110.4 159.13 110.391C159.051 110.383 158.978 110.346 158.924 110.288C158.806 110.125 158.714 109.945 158.652 109.755C158.089 108.409 157.592 107.191 157.253 106.3C157.045 105.831 156.887 105.342 156.781 104.84C157.061 105.276 157.297 105.738 157.483 106.222C157.877 107.094 158.404 108.3 158.966 109.645C159.021 109.796 159.087 109.942 159.166 110.082C159.197 110.082 159.166 110.082 159.215 110.082C159.259 110.046 159.294 110 159.318 109.948C159.46 109.655 159.585 109.353 159.693 109.045C159.899 108.439 160.044 107.912 160.183 107.452C160.292 106.946 160.467 106.457 160.704 105.997Z" fill="#263238" class="style-166"></path>
            <path d="M158.531 147.406C158.498 147.263 158.482 147.117 158.482 146.97C158.482 146.655 158.434 146.243 158.397 145.728C158.343 144.613 158.258 143.055 158.161 141.146C157.986 137.25 157.75 131.923 157.483 126.039C157.217 120.154 157.054 114.828 156.878 110.925C156.817 109.016 156.775 107.465 156.739 106.344C156.739 105.829 156.739 105.416 156.739 105.132C156.73 104.986 156.73 104.841 156.739 104.695C156.772 104.836 156.791 104.981 156.793 105.126C156.793 105.441 156.842 105.853 156.872 106.368C156.933 107.489 157.011 109.04 157.114 110.949C157.29 114.846 157.526 120.173 157.786 126.057C158.046 131.941 158.222 137.274 158.391 141.17C158.452 143.079 158.5 144.637 158.537 145.752C158.537 146.267 158.537 146.685 158.537 147C158.549 147.135 158.547 147.271 158.531 147.406Z" fill="#263238" class="style-167"></path>
            <path d="M165.908 117.197L160.346 117.305L160.368 118.456L165.93 118.348L165.908 117.197Z" fill="white" class="style-168"></path>
            <path d="M171.194 136.01C171.103 136.01 170.752 131.805 170.407 126.599C170.062 121.394 169.85 117.182 169.935 117.176C170.02 117.17 170.371 121.381 170.722 126.581C171.073 131.78 171.309 136.004 171.194 136.01Z" fill="#263238" class="style-169"></path>
            <path d="M197.593 110.955C196.183 110.793 194.787 110.528 193.416 110.162C192.023 109.893 190.652 109.525 189.312 109.059C190.722 109.221 192.118 109.486 193.489 109.852C194.882 110.117 196.254 110.486 197.593 110.955Z" fill="#263238" class="style-170"></path>
            <path d="M191.001 107.227C191.113 107.224 191.224 107.256 191.319 107.317C191.414 107.377 191.489 107.465 191.534 107.569C191.579 107.672 191.593 107.787 191.573 107.898C191.553 108.009 191.5 108.112 191.421 108.193C191.343 108.274 191.242 108.33 191.132 108.353C191.022 108.377 190.907 108.367 190.802 108.326C190.697 108.284 190.607 108.212 190.543 108.119C190.48 108.025 190.445 107.915 190.444 107.803C190.442 107.728 190.455 107.654 190.483 107.585C190.51 107.515 190.55 107.452 190.602 107.398C190.654 107.345 190.716 107.302 190.784 107.273C190.853 107.243 190.926 107.228 191.001 107.227Z" fill="#263238" class="style-171"></path>
            <path d="M165.85 114.629L163.847 114.668L163.885 116.673L165.888 116.635L165.85 114.629Z" fill="#039D55" class="style-172"></path>
            <path d="M165.824 113.535L164.819 113.555L164.838 114.56L165.843 114.541L165.824 113.535Z" fill="#039D55" class="style-173"></path>
            <path d="M195.843 124.91L195.02 157.27L144.299 157.009L144.463 124.219L195.843 124.91Z" fill="#039D55" class="style-174"></path>
            <path d="M165.716 157.081C165.631 157.081 165.716 149.852 165.97 140.943C166.224 132.035 166.466 124.836 166.533 124.836C166.6 124.836 166.533 132.059 166.285 140.974C166.037 149.888 165.801 157.081 165.716 157.081Z" fill="#263238" class="style-175"></path>
            <path d="M144.299 157.008C144.353 156.888 144.42 156.774 144.499 156.669L145.104 155.718L147.471 152.27L155.377 140.949C158.47 136.532 161.255 132.526 163.246 129.605C164.245 128.145 165.062 126.957 165.583 126.133L166.188 125.181C166.268 125.056 166.361 124.94 166.467 124.836C166.423 124.961 166.362 125.08 166.285 125.187L165.716 126.175C165.201 127.018 164.433 128.23 163.464 129.702C161.521 132.659 158.761 136.689 155.674 141.107C152.586 145.525 149.747 149.5 147.689 152.373L145.268 155.766C144.989 156.142 144.759 156.445 144.59 156.681C144.508 156.803 144.411 156.913 144.299 157.008Z" fill="#263238" class="style-176"></path>
            <path d="M165.214 157.338C165.141 157.386 160.468 150.096 154.784 141.055C149.1 132.013 144.541 124.644 144.62 124.602C144.699 124.559 149.36 131.843 155.05 140.885C160.74 149.926 165.286 157.295 165.214 157.338Z" fill="#263238" class="style-177"></path>
            <path d="M171.623 152.564L179.765 152.809L179.815 151.136L171.674 150.892L171.623 152.564Z" fill="#263238" class="style-178"></path>
            <path d="M171.418 150.437C172.739 150.278 174.071 150.227 175.401 150.285C176.731 150.23 178.063 150.285 179.384 150.449C178.062 150.601 176.731 150.651 175.401 150.6C174.071 150.647 172.74 150.593 171.418 150.437Z" fill="#263238" class="style-179"></path>
            <path d="M185.583 124.648L185.268 137.143L183.525 135.731L181.23 137.04L181.551 124.551L185.583 124.648Z" fill="#263238" class="style-180"></path>
            <path d="M143.464 121.353C143.464 121.353 141.006 138.527 142.168 139.533C148.609 144.957 157.562 147.799 160.764 148.696C161.23 148.581 161.781 148.435 162.405 148.235C162.949 148.062 163.459 147.796 163.912 147.447C164.494 147.041 165.018 146.555 165.468 146.005C165.537 145.891 165.64 145.802 165.762 145.747C165.883 145.693 166.019 145.677 166.15 145.701C166.281 145.726 166.401 145.789 166.496 145.883C166.59 145.978 166.654 146.098 166.678 146.229C166.708 146.835 165.758 148.35 163.972 149.459C163.972 149.459 168.077 156.071 169.384 156.665C170.692 157.258 174.439 158.949 173.803 159.276C173.167 159.604 168.615 158.27 168.615 158.27L159.378 155.798C159.378 155.798 128.815 151.005 130.855 136.97C132.102 128.37 133.464 120.608 133.954 118.656L143.464 121.353Z" fill="#FFBE9D" class="style-181"></path>
            <path d="M133.427 117.832L130.685 136.569C130.08 142.345 133.912 146.92 139.105 149.501C147.011 153.422 158.749 156.216 158.652 156.198C158.555 156.18 159.445 147.865 159.445 147.865C159.445 147.865 142.992 140.733 142.495 137.666C141.999 134.6 144.584 121.783 144.584 121.783L133.427 117.832Z" fill="#455A64" class="style-182"></path>
            <path d="M158.652 156.197C158.644 156.149 158.644 156.1 158.652 156.052C158.652 155.936 158.652 155.791 158.652 155.615C158.682 155.209 158.724 154.652 158.779 153.949C158.906 152.476 159.088 150.391 159.312 147.828L159.378 147.937L151.097 143.925L148.676 142.756C147.871 142.326 147.066 141.859 146.255 141.344C145.441 140.822 144.66 140.249 143.918 139.629C143.543 139.314 143.173 138.987 142.816 138.635C142.621 138.464 142.459 138.259 142.338 138.029C142.278 137.91 142.233 137.784 142.205 137.653C142.167 137.526 142.139 137.397 142.12 137.266C141.854 135.223 142.405 133.284 142.683 131.442C142.962 129.6 143.288 127.806 143.609 126.097C144.215 122.697 144.735 119.637 145.189 117.055C145.433 115.911 145.573 114.747 145.607 113.577C145.615 112.696 145.434 111.824 145.074 111.02C144.841 110.514 144.512 110.06 144.106 109.68C143.821 109.42 143.646 109.323 143.652 109.305C143.831 109.391 143.998 109.501 144.148 109.632C144.583 109.999 144.936 110.453 145.183 110.965C145.576 111.778 145.783 112.668 145.788 113.571C145.774 114.754 145.649 115.933 145.413 117.092C144.983 119.679 144.469 122.746 143.9 126.151L142.992 131.502C142.913 131.963 142.834 132.436 142.75 132.908C142.665 133.381 142.58 133.842 142.514 134.32C142.356 135.278 142.332 136.253 142.441 137.217C142.456 137.336 142.48 137.453 142.514 137.569C142.539 137.682 142.578 137.792 142.629 137.896C142.729 138.103 142.871 138.286 143.046 138.435C143.391 138.781 143.755 139.102 144.124 139.417C144.856 140.025 145.625 140.59 146.424 141.108C147.211 141.623 148.01 142.089 148.803 142.513L151.188 143.689L159.427 147.761L159.499 147.798V147.864C159.233 150.428 159.015 152.512 158.894 153.979C158.815 154.682 158.755 155.233 158.712 155.639C158.712 155.815 158.67 155.955 158.652 156.07C158.634 156.185 158.658 156.203 158.652 156.197Z" fill="#263238" class="style-183"></path>
            <path d="M157.126 146.691C157.149 147.12 157.116 147.55 157.029 147.97C156.927 148.758 156.763 149.837 156.545 151C156.327 152.164 156.079 153.254 155.873 154.03C155.789 154.449 155.653 154.856 155.468 155.242C155.383 155.242 155.795 153.315 156.236 150.952C156.447 149.515 156.744 148.092 157.126 146.691Z" fill="#263238" class="style-184"></path>
            <path d="M158.652 156.197C158.555 156.197 158.46 156.182 158.367 156.154L157.55 155.955L154.572 155.179C152.072 154.5 148.585 153.615 144.886 152.24C143.129 151.598 141.418 150.837 139.765 149.961C138.42 149.236 137.133 148.408 135.915 147.483C135.099 146.853 134.329 146.167 133.609 145.428C133.361 145.168 133.167 144.956 133.046 144.822C132.976 144.752 132.917 144.673 132.871 144.586C132.871 144.586 133.167 144.859 133.7 145.356C134.444 146.056 135.23 146.709 136.055 147.313C137.274 148.219 138.561 149.03 139.905 149.737C141.55 150.596 143.251 151.345 144.995 151.979C148.7 153.343 152.175 154.264 154.681 155.009L157.629 155.888L158.428 156.136C158.504 156.151 158.579 156.171 158.652 156.197Z" fill="#263238" class="style-185"></path>
            <path d="M158.857 148.697C158.859 148.767 158.847 148.837 158.821 148.902C158.796 148.967 158.758 149.027 158.71 149.078C158.662 149.128 158.604 149.169 158.54 149.197C158.476 149.225 158.407 149.241 158.337 149.242C158.197 149.242 158.063 149.188 157.962 149.091C157.862 148.994 157.803 148.861 157.798 148.721C157.796 148.651 157.809 148.581 157.834 148.516C157.859 148.451 157.897 148.391 157.945 148.341C157.994 148.29 158.052 148.249 158.116 148.221C158.18 148.193 158.249 148.177 158.319 148.176C158.458 148.176 158.593 148.23 158.693 148.327C158.794 148.425 158.853 148.557 158.857 148.697Z" fill="#263238" class="style-186"></path>
            <path d="M301.504 60.6641H234.7V267.243H301.504V60.6641Z" fill="#E0E0E0" class="style-187"></path>
            <path d="M278.532 62.2461V258.008L310.494 267.25L310.597 62.2461H278.532Z" fill="#455A64" class="style-188"></path>
            <path d="M282.473 157.153C282.533 158.686 281.946 159.953 281.159 159.989C280.372 160.025 279.682 158.777 279.621 157.268C279.561 155.759 280.148 154.468 280.935 154.438C281.722 154.408 282.412 155.62 282.473 157.153Z" fill="white" class="style-189"></path>
            <path d="M280.996 156.012L287.291 156.854C287.454 156.875 287.605 156.954 287.716 157.076C287.827 157.198 287.891 157.356 287.896 157.521C287.901 157.625 287.882 157.729 287.841 157.824C287.799 157.92 287.737 158.006 287.659 158.074C287.581 158.143 287.488 158.193 287.387 158.221C287.287 158.249 287.182 158.254 287.079 158.236L281.026 157.212L280.996 156.012Z" fill="white" class="style-190"></path>
            <path d="M226.897 53.3633V60.1202V62.2472V267.251H237.387V62.2472H298.95V267.251H310.494V62.2472V60.1202V53.3633H226.897Z" fill="#263238" class="style-191"></path>
            <path d="M237.388 62.2461H232.146V267.244H237.388V62.2461Z" fill="#263238" class="style-192"></path>
            <path opacity="0.5" d="M237.388 62.2461H232.146V267.244H237.388V62.2461Z" fill="black" class="style-193"></path>
            <path d="M310.597 53.3633H305.354V267.251H310.597V53.3633Z" fill="#263238" class="style-194"></path>
            <path opacity="0.5" d="M310.597 53.3633H305.354V267.251H310.597V53.3633Z" fill="black" class="style-195"></path>
            <path d="M275.887 40.8789H258.659V44.727H275.887V40.8789Z" fill="#455A64" class="style-196"></path>
            <path d="M269.258 94.4141C269.358 96.2789 270.052 98.0627 271.24 99.5027C272.428 100.943 274.046 101.963 275.856 102.413C277.727 102.868 279.882 102.656 281.365 103.91C283.907 106.074 282.418 110.576 284.137 113.442C285.191 115.224 287.285 116.097 288.472 117.787C290.445 120.593 289.119 124.841 286.432 126.932C283.744 129.023 280.154 129.423 276.789 129.356C269.373 129.162 261.776 126.805 256.419 121.545C253.901 119.072 245.735 109.673 249.446 96.129" fill="#263238" class="style-197"></path>
            <path d="M242.478 114.34C242.478 114.34 229.506 125.63 229.161 124.254C227.345 116.782 227.854 107.238 228.15 103.584C228.35 103.275 228.586 102.929 228.864 102.547C229.181 102.105 229.572 101.722 230.021 101.414C230.563 101.005 231.157 100.671 231.788 100.42C231.92 100.405 232.043 100.346 232.139 100.253C232.234 100.16 232.296 100.038 232.315 99.9065C232.335 99.7748 232.31 99.6404 232.245 99.5241C232.181 99.4077 232.08 99.3159 231.958 99.2629C231.437 99.0689 229.778 99.4992 228.241 100.784C226.703 102.069 227.333 99.0144 227.539 97.8994C227.745 96.7843 228.229 93.2938 227.932 92.9665C227.502 92.4817 226.897 92.8696 226.722 93.9907C226.546 95.1118 225.753 98.4387 225.166 98.3175C224.579 98.1963 225.729 92.512 225.729 92.512C225.729 92.512 226.165 91.3303 225.493 91.1667C224.282 90.8698 223.519 96.9904 223.459 97.5721C223.416 97.9781 222.775 97.8691 222.853 97.4327C222.932 96.9964 224.119 91.7909 222.89 91.4758C221.945 91.2334 222.212 96.4995 221.364 97.457C220.517 98.4145 221.57 93.4332 220.656 93.215C220.317 93.1423 220.051 93.1362 219.863 96.0753C219.818 98.4416 219.903 100.809 220.117 103.166C219.312 111.225 217.732 135.92 228.877 135.071C242.024 134.065 245.051 123.691 245.051 123.691L242.478 114.34Z" fill="#B78876" class="style-198"></path>
            <path d="M252.842 110.664C253.03 109.622 252.697 105.247 252.697 105.247C252.697 105.247 248.816 104.756 248.653 100.799C248.49 96.8415 248.877 87.7151 248.877 87.7151L256.014 84.2852L263.689 90.4785L262.358 110.598L252.842 110.664Z" fill="#B78876" class="style-199"></path>
            <path d="M249.537 94.3836C249.537 94.4817 249.566 94.5775 249.62 94.6591C249.674 94.7407 249.751 94.8043 249.842 94.8421C249.932 94.8799 250.032 94.8901 250.128 94.8714C250.224 94.8527 250.312 94.806 250.382 94.7371C250.452 94.6682 250.499 94.5802 250.519 94.4842C250.539 94.3883 250.53 94.2885 250.493 94.1976C250.457 94.1067 250.394 94.0287 250.313 93.9734C250.232 93.9181 250.137 93.8879 250.039 93.8867C249.907 93.8867 249.78 93.9389 249.686 94.0319C249.592 94.1249 249.538 94.2513 249.537 94.3836Z" fill="#263238" class="style-200"></path>
            <path d="M249.07 93.818C249.131 93.8847 249.5 93.5756 250.033 93.5575C250.566 93.5393 250.959 93.8059 251.014 93.7332C251.068 93.6605 250.977 93.5756 250.802 93.4544C250.573 93.2915 250.296 93.2104 250.015 93.2242C249.735 93.2385 249.468 93.3426 249.252 93.5211C249.089 93.6605 249.04 93.7877 249.07 93.818Z" fill="#263238" class="style-201"></path>
            <path d="M255.245 94.4813C255.245 94.5793 255.274 94.6752 255.328 94.7567C255.382 94.8383 255.459 94.902 255.55 94.9398C255.64 94.9776 255.74 94.9878 255.836 94.9691C255.932 94.9504 256.02 94.9036 256.09 94.8348C256.16 94.7659 256.207 94.6779 256.227 94.5819C256.247 94.4859 256.238 94.3862 256.201 94.2953C256.165 94.2044 256.102 94.1264 256.021 94.0711C255.94 94.0157 255.845 93.9856 255.747 93.9844C255.615 93.9844 255.488 94.0365 255.394 94.1296C255.3 94.2226 255.246 94.349 255.245 94.4813Z" fill="#263238" class="style-202"></path>
            <path d="M254.948 93.9277C255.015 93.9944 255.384 93.6853 255.917 93.6611C256.45 93.6368 256.837 93.9156 256.898 93.8429C256.958 93.7701 256.861 93.6853 256.686 93.558C256.455 93.3992 256.178 93.3203 255.899 93.3334C255.619 93.3466 255.351 93.451 255.136 93.6308C254.973 93.7641 254.918 93.8913 254.948 93.9277Z" fill="#263238" class="style-203"></path>
            <path d="M252.956 98.4993C252.676 98.4114 252.384 98.3665 252.091 98.366C251.958 98.366 251.825 98.3296 251.8 98.2326C251.79 98.0823 251.819 97.9319 251.885 97.7963L252.266 96.6752C252.625 95.7203 252.913 94.7399 253.126 93.7422C252.699 94.6659 252.341 95.6199 252.055 96.5964C251.927 96.9964 251.8 97.3661 251.685 97.7297C251.603 97.9077 251.582 98.1079 251.625 98.2993C251.643 98.3493 251.672 98.3941 251.711 98.43C251.75 98.4659 251.797 98.4917 251.849 98.5053C251.925 98.5161 252.002 98.5161 252.079 98.5053C252.37 98.5418 252.665 98.5398 252.956 98.4993Z" fill="#263238" class="style-204"></path>
            <path d="M254.7 99.0415C255.245 98.684 253.199 99.9202 252.121 99.902C252.305 100.181 252.58 100.388 252.899 100.487C253.217 100.587 253.561 100.573 253.871 100.447C254.017 100.41 254.155 100.344 254.277 100.253C254.398 100.162 254.5 100.048 254.577 99.9171C254.654 99.7864 254.705 99.6418 254.726 99.4915C254.747 99.3413 254.738 99.1883 254.7 99.0415Z" fill="white" class="style-205"></path>
            <path d="M252.696 105.247C254.535 105.197 256.318 104.612 257.83 103.562C257.83 103.562 256.619 106.453 252.763 106.217L252.696 105.247Z" fill="#A36957" class="style-206"></path>
            <path d="M269.258 94.4752C269.222 94.1964 269.18 93.7722 269.137 93.3299C269.077 92.7239 269.01 92.0694 268.932 91.7179C266.97 83.4339 259.936 81.7734 258.544 81.7492C257.043 81.7189 254.44 80.8281 250.548 84.8398C249.441 85.9038 248.672 87.2717 248.34 88.7715C248.007 90.2713 248.124 91.8361 248.677 93.2693L249.331 92.6633L254.991 91.2695C254.567 89.6999 255.257 87.7728 255.644 86.876L255.681 86.8396C256.704 92.7845 263.042 93.5056 263.042 93.5056L262.436 109.262C268.677 108.001 269.174 97.8688 269.162 94.4388L269.258 94.4752Z" fill="#263238" class="style-207"></path>
            <path d="M271.71 165.192C271.71 165.113 271.71 165.035 271.71 164.986C271.71 164.938 271.722 165.083 271.71 165.192Z" fill="#B78876" class="style-208"></path>
            <path d="M279.059 261.263C279.059 261.263 264.446 211.953 264.167 210.359C263.913 208.935 270.681 174.338 271.661 165.363L251.116 165.557C251.116 165.557 251.116 165.612 251.116 165.709L241.189 165.982L246.159 264.517L257.055 264.802L256.891 232.411C262.279 248.228 269.095 266.099 269.095 266.099L279.059 261.263Z" fill="#B78876" class="style-209"></path>
            <path d="M250.36 208.152C250.39 208.152 250.36 208.54 250.293 209.231C250.245 210.205 250.263 211.181 250.347 212.152C250.474 213.593 250.716 215.022 251.074 216.424C251.547 218.136 252.13 219.816 252.817 221.454C254.167 224.993 255.378 228.199 256.237 230.544L257.266 233.307C257.401 233.642 257.511 233.986 257.593 234.338C257.424 234.019 257.276 233.689 257.151 233.35L256.056 230.623C255.142 228.314 253.901 225.114 252.545 221.575C252.212 220.69 251.885 219.824 251.564 218.988C251.269 218.176 251.021 217.349 250.82 216.509C250.469 215.089 250.245 213.641 250.148 212.182C250.085 211.199 250.101 210.212 250.196 209.231C250.232 208.886 250.275 208.625 250.311 208.437C250.311 208.34 250.328 208.244 250.36 208.152Z" fill="#A36957" class="style-210"></path>
            <path d="M256.946 244.178C256.946 243.881 257.139 233.198 257.139 233.198C257.139 233.198 253.556 223.296 253.465 223.592C253.374 223.889 256.946 244.178 256.946 244.178Z" fill="#A36957" class="style-211"></path>
            <path d="M269.47 265.928L279.083 261.262L280.191 263.328L276.795 265.249C274.476 266.516 266.595 270.649 264.73 270.982C262.654 271.358 269.47 265.928 269.47 265.928Z" fill="#039D55" class="style-212"></path>
            <path d="M264.301 270.705C264.252 270.632 267.763 268.887 272.14 266.821C276.516 264.754 280.094 263.136 280.136 263.185C280.179 263.233 276.674 265.003 272.297 267.063C267.921 269.123 264.343 270.778 264.301 270.705Z" fill="#263238" class="style-213"></path>
            <path d="M277.691 264.439C277.642 264.439 277.624 264.257 277.642 263.966C277.667 263.579 277.772 263.202 277.951 262.857C278.151 262.481 278.474 262.184 278.865 262.015C279.186 261.888 279.41 261.918 279.41 261.948C279.41 261.979 279.228 262.045 278.986 262.197C278.681 262.395 278.429 262.666 278.254 262.985C278.096 263.311 277.971 263.652 277.878 264.003C277.8 264.275 277.733 264.445 277.691 264.439Z" fill="#263238" class="style-214"></path>
            <path d="M268.95 266.371C268.95 266.456 269.246 266.571 269.628 266.698C270.009 266.826 270.33 266.91 270.384 266.838C270.439 266.765 270.166 266.535 269.749 266.395C269.331 266.256 268.962 266.28 268.95 266.371Z" fill="#263238" class="style-215"></path>
            <path d="M268.29 266.886C268.29 266.977 268.544 267.141 268.895 267.256C269.246 267.371 269.597 267.407 269.639 267.322C269.682 267.238 269.397 267.08 269.034 266.959C268.671 266.838 268.314 266.801 268.29 266.886Z" fill="#263238" class="style-216"></path>
            <path d="M267.745 267.367C267.709 267.446 267.914 267.597 268.181 267.737C268.447 267.876 268.707 267.955 268.786 267.888C268.865 267.821 268.671 267.615 268.38 267.464C268.09 267.312 267.787 267.282 267.745 267.367Z" fill="#263238" class="style-217"></path>
            <path d="M268.992 266.451C269.045 266.111 269.009 265.762 268.889 265.439C268.794 265.066 268.625 264.716 268.393 264.409C268.244 264.218 268.068 264.048 267.872 263.906C267.758 263.824 267.634 263.757 267.503 263.706C267.334 263.64 267.146 263.64 266.976 263.706C266.905 263.743 266.843 263.795 266.794 263.858C266.746 263.921 266.711 263.995 266.694 264.073C266.677 264.151 266.677 264.232 266.694 264.31C266.712 264.388 266.746 264.461 266.795 264.524C266.927 264.719 267.077 264.902 267.243 265.07C267.518 265.35 267.818 265.605 268.139 265.833C268.421 266.072 268.746 266.257 269.095 266.379C269.131 266.342 268.798 266.094 268.308 265.67C268.023 265.423 267.752 265.16 267.497 264.882C267.354 264.728 267.224 264.561 267.11 264.385C267.001 264.227 267.031 264.033 267.14 263.991C267.249 263.949 267.473 263.991 267.654 264.142C267.829 264.261 267.986 264.404 268.12 264.567C268.347 264.844 268.525 265.157 268.647 265.494C268.732 265.823 268.848 266.144 268.992 266.451Z" fill="#263238" class="style-218"></path>
            <path d="M268.992 266.261C268.722 265.993 268.398 265.787 268.042 265.655C267.654 265.484 267.248 265.36 266.831 265.285C266.581 265.241 266.328 265.219 266.074 265.219C265.929 265.22 265.785 265.238 265.644 265.273C265.556 265.293 265.473 265.332 265.402 265.388C265.359 265.422 265.326 265.465 265.303 265.515C265.281 265.565 265.272 265.619 265.275 265.673C265.319 265.82 265.397 265.954 265.502 266.066C265.607 266.177 265.737 266.263 265.88 266.316C266.114 266.43 266.363 266.509 266.619 266.552C267.038 266.611 267.462 266.623 267.884 266.588C268.617 266.546 269.016 266.291 268.98 266.261C268.613 266.315 268.243 266.349 267.872 266.364C267.466 266.367 267.061 266.332 266.661 266.261C266.451 266.218 266.247 266.147 266.056 266.049C265.874 265.952 265.669 265.77 265.657 265.655C265.644 265.54 265.88 265.522 266.111 265.534C266.341 265.524 266.572 265.536 266.801 265.57C267.192 265.633 267.577 265.73 267.951 265.861C268.287 266.021 268.635 266.155 268.992 266.261Z" fill="#263238" class="style-219"></path>
            <path d="M269.658 265.827C269.712 265.918 271.946 265.064 274.543 263.785C277.14 262.506 279.144 261.361 279.077 261.264C279.01 261.167 276.892 262.191 274.301 263.464C271.71 264.737 269.597 265.736 269.658 265.827Z" fill="#263238" class="style-220"></path>
            <path d="M247.406 264.406L257.049 264.479L256.885 267.273H256.28C253.483 267.273 242.048 267.067 240.214 266.479C238.144 265.836 247.406 264.406 247.406 264.406Z" fill="#039D55" class="style-221"></path>
            <path d="M239.979 266.033C239.979 265.954 243.774 266.1 248.453 266.36C253.132 266.621 256.898 266.906 256.892 266.966C256.885 267.027 253.102 266.906 248.417 266.645C243.732 266.385 239.972 266.13 239.979 266.033Z" fill="#263238" class="style-222"></path>
            <path d="M253.828 266.798C253.792 266.767 253.883 266.61 254.076 266.373C254.339 266.051 254.659 265.779 255.021 265.573C255.421 265.351 255.878 265.252 256.334 265.289C256.685 265.331 256.867 265.458 256.849 265.489C256.831 265.519 256.631 265.489 256.322 265.489C255.931 265.513 255.551 265.625 255.208 265.816C254.872 266.012 254.555 266.242 254.264 266.501C254.028 266.707 253.864 266.822 253.828 266.798Z" fill="#263238" class="style-223"></path>
            <path d="M246.68 264.542C246.613 264.608 246.819 264.857 247.079 265.148C247.339 265.439 247.563 265.663 247.654 265.62C247.745 265.578 247.654 265.263 247.364 264.954C247.073 264.645 246.746 264.475 246.68 264.542Z" fill="#263238" class="style-224"></path>
            <path d="M245.765 264.676C245.693 264.736 245.838 265.009 246.092 265.282C246.346 265.554 246.619 265.736 246.697 265.688C246.776 265.639 246.637 265.367 246.371 265.082C246.104 264.797 245.862 264.615 245.765 264.676Z" fill="#263238" class="style-225"></path>
            <path d="M245.027 264.821C244.942 264.87 245.027 265.094 245.184 265.336C245.341 265.579 245.505 265.767 245.602 265.736C245.699 265.706 245.668 265.451 245.505 265.191C245.341 264.93 245.105 264.773 245.027 264.821Z" fill="#263238" class="style-226"></path>
            <path d="M246.673 264.632C246.915 264.376 247.093 264.067 247.194 263.729C247.334 263.377 247.398 262.999 247.382 262.62C247.371 262.389 247.32 262.161 247.23 261.948C247.181 261.825 247.113 261.711 247.03 261.608C246.912 261.471 246.745 261.385 246.564 261.366C246.48 261.358 246.396 261.368 246.316 261.395C246.236 261.422 246.163 261.466 246.101 261.523C246.039 261.58 245.989 261.649 245.955 261.727C245.921 261.804 245.904 261.888 245.905 261.972C245.904 262.196 245.926 262.419 245.971 262.639C246.039 263.004 246.145 263.361 246.286 263.705C246.388 264.042 246.564 264.353 246.8 264.614C246.751 264.281 246.662 263.956 246.534 263.645C246.432 263.301 246.355 262.951 246.304 262.596C246.274 262.396 246.262 262.193 246.268 261.99C246.268 261.808 246.407 261.663 246.534 261.675C246.661 261.687 246.813 261.845 246.891 262.045C246.976 262.236 247.027 262.441 247.043 262.651C247.072 262.997 247.039 263.346 246.946 263.681C246.822 263.988 246.731 264.306 246.673 264.632Z" fill="#263238" class="style-227"></path>
            <path d="M246.788 264.476C246.707 264.127 246.543 263.803 246.31 263.531C246.076 263.204 245.799 262.911 245.487 262.658C245.299 262.501 245.096 262.363 244.881 262.246C244.753 262.177 244.617 262.124 244.476 262.088C244.382 262.07 244.285 262.07 244.191 262.088C244.133 262.096 244.078 262.116 244.029 262.148C243.98 262.179 243.938 262.221 243.907 262.27C243.854 262.404 243.838 262.55 243.859 262.692C243.88 262.835 243.939 262.969 244.028 263.082C244.16 263.294 244.33 263.479 244.53 263.628C244.869 263.871 245.235 264.074 245.62 264.234C245.971 264.438 246.38 264.519 246.782 264.464C246.428 264.344 246.082 264.2 245.747 264.034C245.398 263.851 245.068 263.636 244.76 263.391C244.598 263.256 244.457 263.096 244.343 262.919C244.289 262.844 244.252 262.759 244.233 262.669C244.215 262.58 244.215 262.487 244.234 262.397C244.234 262.343 244.506 262.397 244.7 262.506C244.915 262.602 245.118 262.722 245.305 262.864C245.611 263.094 245.891 263.356 246.14 263.646C246.516 264.155 246.734 264.494 246.788 264.476Z" fill="#263238" class="style-228"></path>
            <path d="M247.63 264.407C249.161 264.653 250.711 264.757 252.261 264.716C253.808 264.786 255.359 264.731 256.898 264.553C255.358 264.412 253.812 264.359 252.267 264.395C249.712 264.371 247.636 264.323 247.63 264.407Z" fill="#263238" class="style-229"></path>
            <path d="M276.898 131.35C276.898 131.35 277.291 129.871 276.171 120.812C275.687 116.867 273.005 114.418 270.118 112.934V112.885L269.87 112.813C267.501 111.667 264.958 110.925 262.345 110.619L252.182 110.455C252.182 110.455 244.742 109.528 240.003 114.697C235.263 119.866 232.03 122.902 232.03 122.902L239.603 131.992L241.631 129.987C241.631 129.987 241.419 134.174 242.799 136.616L246.074 142.385L243.949 152.548C242.605 156.42 238.174 170.583 239.912 184.611C242.061 201.986 242.406 207.064 242.406 207.064L266.946 210.421C266.946 210.421 276.752 173.637 273.393 159.026C271.086 148.985 269.325 149.451 268.756 147.276L269.373 131.647L276.898 131.35Z" fill="#039D55" class="style-230"></path>
            <path d="M243.574 126.605C243.378 127.255 243.079 127.868 242.69 128.423C242.397 129.028 242.006 129.581 241.534 130.06C241.734 129.412 242.032 128.799 242.418 128.242C242.714 127.638 243.105 127.086 243.574 126.605Z" fill="#263238" class="style-231"></path>
            <path d="M265.572 145.602C265.317 145.676 265.055 145.727 264.791 145.753C264.282 145.838 263.544 145.935 262.636 146.026C260.257 146.267 257.866 146.366 255.475 146.323C253.083 146.278 250.696 146.094 248.326 145.771C247.418 145.644 246.685 145.523 246.183 145.426C245.917 145.39 245.655 145.327 245.402 145.238C245.67 145.237 245.937 145.259 246.201 145.305L248.35 145.553C250.166 145.747 252.69 145.953 255.481 146.008C258.271 146.062 260.79 145.935 262.612 145.802L264.773 145.632C265.037 145.591 265.305 145.581 265.572 145.602Z" fill="#263238" class="style-232"></path>
            <path d="M273.841 173.133C273.827 173.295 273.797 173.456 273.75 173.612C273.665 173.957 273.556 174.418 273.417 174.993C273.338 175.29 273.26 175.599 273.169 175.981C273.078 176.363 272.927 176.726 272.794 177.145C272.515 177.969 272.243 178.932 271.801 179.938L271.147 181.538C270.917 182.089 270.62 182.641 270.342 183.229C269.803 184.404 269.089 185.592 268.369 186.865C265.093 192.282 260.771 196.992 255.657 200.718C254.446 201.542 253.338 202.354 252.206 202.984C251.649 203.312 251.123 203.651 250.59 203.93L249.052 204.711C248.09 205.239 247.152 205.584 246.353 205.923C245.953 206.093 245.578 206.257 245.233 206.39L244.264 206.717L242.92 207.166C242.767 207.224 242.609 207.268 242.448 207.299C242.592 207.219 242.744 207.154 242.902 207.105L244.222 206.602L245.178 206.251C245.523 206.111 245.887 205.936 246.286 205.76C247.073 205.396 247.999 205.039 248.956 204.499L250.475 203.699C251.002 203.421 251.522 203.093 252.073 202.748C253.193 202.112 254.301 201.294 255.487 200.469C260.559 196.746 264.859 192.07 268.145 186.701C268.871 185.453 269.591 184.277 270.13 183.108C270.415 182.502 270.735 181.986 270.947 181.435C271.159 180.884 271.407 180.356 271.619 179.853C272.073 178.853 272.358 177.908 272.654 177.09C272.794 176.678 272.939 176.296 273.048 175.939C273.157 175.581 273.242 175.254 273.32 174.957L273.714 173.599C273.746 173.441 273.788 173.285 273.841 173.133Z" fill="#263238" class="style-233"></path>
            <path d="M276.51 126.048C276.391 126.138 276.261 126.213 276.123 126.272C275.869 126.405 275.518 126.605 274.991 126.841C273.595 127.553 272.099 128.047 270.554 128.308C269.473 128.464 268.373 128.404 267.315 128.132C266.722 127.975 266.157 127.728 265.639 127.399C265.098 127.039 264.614 126.6 264.204 126.096C263.396 124.991 262.67 123.828 262.031 122.618C261.323 121.406 260.402 120.194 259.004 119.733C257.606 119.272 256.135 119.618 254.797 120C253.498 120.455 252.148 120.746 250.778 120.866C249.96 120.896 249.146 120.754 248.387 120.449C247.627 120.144 246.94 119.683 246.37 119.096C245.8 118.509 245.359 117.809 245.075 117.041C244.792 116.273 244.672 115.454 244.724 114.637C244.766 113.959 244.919 113.294 245.178 112.667C245.383 112.128 245.694 111.635 246.092 111.219C245.775 111.689 245.508 112.191 245.293 112.716C245.058 113.334 244.925 113.987 244.9 114.649C244.875 115.431 245.01 116.211 245.297 116.939C245.583 117.667 246.016 118.329 246.567 118.884C247.119 119.439 247.778 119.876 248.504 120.167C249.23 120.457 250.008 120.596 250.79 120.575C252.135 120.448 253.459 120.153 254.73 119.697C255.427 119.483 256.139 119.327 256.861 119.23C257.622 119.13 258.395 119.2 259.125 119.436C259.85 119.695 260.502 120.125 261.026 120.691C261.519 121.231 261.952 121.825 262.315 122.46C262.946 123.659 263.658 124.813 264.446 125.914C265.197 126.87 266.235 127.558 267.406 127.878C268.429 128.155 269.497 128.227 270.548 128.09C272.077 127.85 273.563 127.39 274.961 126.726L276.117 126.211C276.244 126.147 276.376 126.093 276.51 126.048Z" fill="white" class="style-234"></path>
            <path d="M262.11 147.515C262.317 147.205 262.463 146.857 262.539 146.491C262.599 145.99 262.546 145.482 262.382 145.005C262.219 144.527 261.95 144.093 261.595 143.734C261.107 143.234 260.508 142.858 259.846 142.637C259.08 142.379 258.288 142.206 257.485 142.122C256.631 142.007 255.717 141.94 254.785 141.74C253.802 141.562 252.873 141.162 252.067 140.571C251.667 140.251 251.328 139.861 251.068 139.419C250.824 138.993 250.622 138.545 250.463 138.08C250.214 137.22 249.883 136.386 249.476 135.589C249.094 134.946 248.557 134.409 247.914 134.028C247.271 133.647 246.543 133.434 245.796 133.408C244.773 133.389 243.774 133.713 242.957 134.329C242.665 134.564 242.388 134.817 242.127 135.086C242.127 135.086 242.17 134.996 242.285 134.838C242.465 134.616 242.668 134.413 242.89 134.232C243.711 133.552 244.743 133.182 245.808 133.184C246.599 133.197 247.373 133.411 248.059 133.805C248.745 134.199 249.32 134.761 249.73 135.438C250.154 136.249 250.499 137.1 250.76 137.977C251.022 138.89 251.546 139.705 252.267 140.322C253.033 140.881 253.914 141.262 254.846 141.437C255.754 141.637 256.662 141.71 257.515 141.837C259.222 142.049 260.844 142.54 261.752 143.583C262.121 143.967 262.394 144.433 262.551 144.942C262.707 145.451 262.743 145.99 262.654 146.516C262.592 146.798 262.473 147.064 262.303 147.297C262.247 147.377 262.182 147.45 262.11 147.515Z" fill="white" class="style-235"></path>
            <path d="M271.607 190.553C270.799 190.473 269.984 190.507 269.186 190.656C268.252 190.848 267.329 191.089 266.419 191.377C265.209 191.781 263.947 192.01 262.672 192.056C261.117 192.086 259.573 191.782 258.145 191.165C256.486 190.471 255.061 189.314 254.04 187.832C252.949 186.153 252.484 184.142 252.727 182.154C252.846 181.107 253.164 180.093 253.665 179.166C254.186 178.207 254.916 177.379 255.802 176.742C256.661 176.1 257.618 175.53 258.048 174.639C258.266 174.201 258.338 173.704 258.254 173.221C258.157 172.733 257.994 172.26 257.769 171.815C257.258 170.941 256.914 169.979 256.752 168.979C256.659 168.002 256.804 167.016 257.176 166.107C257.889 164.435 259.224 163.107 260.899 162.404C262.331 161.793 263.883 161.514 265.439 161.586C266.697 161.652 267.958 161.615 269.21 161.477C270.149 161.345 271.044 160.997 271.825 160.459C272.325 160.12 272.739 159.67 273.036 159.144C273.229 158.78 273.272 158.568 273.296 158.574C273.258 158.786 273.189 158.99 273.09 159.18C272.815 159.729 272.412 160.202 271.916 160.562C271.128 161.13 270.218 161.506 269.258 161.659C267.998 161.817 266.726 161.87 265.457 161.816C263.935 161.758 262.418 162.042 261.02 162.647C259.418 163.338 258.147 164.624 257.473 166.234C257.12 167.099 256.985 168.038 257.079 168.967C257.239 169.934 257.576 170.862 258.072 171.706C258.312 172.174 258.483 172.674 258.58 173.191C258.68 173.737 258.599 174.301 258.35 174.797C257.836 175.803 256.855 176.379 256.014 177.009C255.184 177.614 254.492 178.388 253.983 179.28C253.474 180.172 253.159 181.162 253.06 182.184C252.829 184.092 253.27 186.021 254.307 187.638C255.294 189.078 256.669 190.209 258.272 190.898C259.667 191.506 261.175 191.814 262.697 191.801C263.954 191.777 265.202 191.573 266.401 191.195C267.318 190.917 268.25 190.693 269.192 190.523C269.791 190.416 270.401 190.388 271.008 190.438C271.211 190.457 271.411 190.496 271.607 190.553Z" fill="white" class="style-236"></path>
            <path d="M263.042 209.88C263.012 209.88 263.042 209.455 263.084 208.698C263.143 207.635 262.954 206.573 262.533 205.595C262.236 204.939 261.714 204.41 261.062 204.104C260.292 203.806 259.465 203.68 258.641 203.735C257.681 203.812 256.715 203.692 255.802 203.383C255.33 203.164 254.916 202.838 254.591 202.432C254.269 202.019 253.989 201.574 253.756 201.105C253.358 200.188 252.837 199.329 252.206 198.553C251.597 197.854 250.867 197.271 250.051 196.832C248.662 196.082 247.068 195.798 245.505 196.02C244.451 196.171 243.436 196.529 242.521 197.075C241.879 197.469 241.565 197.754 241.546 197.735C241.528 197.717 241.613 197.65 241.758 197.511C242.805 196.592 244.096 195.998 245.475 195.802C247.087 195.536 248.742 195.809 250.184 196.578C251.038 197.023 251.802 197.623 252.436 198.347C252.771 198.722 253.069 199.128 253.326 199.559C253.58 200.008 253.798 200.474 254.034 200.935C254.403 201.848 255.077 202.604 255.941 203.074C256.815 203.369 257.739 203.486 258.659 203.42C259.533 203.37 260.407 203.512 261.22 203.838C261.917 204.181 262.47 204.761 262.781 205.474C263.193 206.489 263.355 207.589 263.254 208.68C263.223 209.061 263.187 209.358 263.151 209.552C263.125 209.665 263.088 209.774 263.042 209.88Z" fill="white" class="style-237"></path>
            <path d="M248.519 159.766C248.375 160.123 248.209 160.471 248.023 160.808C247.535 161.722 247.229 162.722 247.121 163.753C247.081 164.457 247.234 165.159 247.563 165.783C247.972 166.497 248.433 167.18 248.943 167.825C249.523 168.604 249.941 169.491 250.175 170.434C250.409 171.376 250.453 172.356 250.305 173.316C250.149 174.282 249.766 175.198 249.187 175.987C248.608 176.776 247.85 177.417 246.976 177.855C246.216 178.243 245.377 178.45 244.524 178.461C243.618 178.451 242.73 178.21 241.943 177.76C241.156 177.31 240.496 176.667 240.027 175.891C239.885 175.642 239.767 175.381 239.675 175.11C239.638 175.015 239.616 174.914 239.609 174.813C239.767 175.161 239.943 175.501 240.136 175.831C240.701 176.697 241.513 177.375 242.466 177.776C243.112 178.061 243.812 178.203 244.518 178.194C245.331 178.175 246.129 177.967 246.849 177.588C247.675 177.164 248.391 176.552 248.938 175.8C249.484 175.049 249.847 174.179 249.996 173.261C250.28 171.398 249.814 169.498 248.701 167.977C248.19 167.316 247.731 166.617 247.327 165.886C246.989 165.218 246.84 164.47 246.897 163.723C247.025 162.669 247.371 161.653 247.914 160.741C248.096 160.414 248.241 160.166 248.35 160.002C248.399 159.918 248.455 159.839 248.519 159.766Z" fill="white" class="style-238"></path>
            <path d="M266.758 139.432C266.708 139.194 266.684 138.953 266.686 138.71C266.686 138.177 266.625 137.498 266.589 136.735C266.504 135.002 266.395 132.741 266.274 130.239C266.153 127.736 266.068 125.391 266.05 123.73C266.05 122.894 266.05 122.221 266.05 121.755C266.038 121.511 266.055 121.267 266.099 121.027C266.152 121.266 266.177 121.51 266.171 121.755C266.201 122.282 266.232 122.967 266.274 123.724C266.353 125.457 266.462 127.718 266.589 130.22C266.716 132.723 266.789 135.068 266.807 136.729C266.807 137.565 266.807 138.238 266.807 138.704C266.818 138.948 266.802 139.192 266.758 139.432Z" fill="#263238" class="style-239"></path>
            <path d="M266.546 131.756C266.546 131.756 267.557 143.985 266.395 144.833C259.924 149.36 251.322 151.178 248.235 151.717C247.805 151.56 247.309 151.372 246.734 151.111C246.236 150.889 245.779 150.584 245.384 150.208C244.874 149.761 244.427 149.247 244.052 148.681C244.007 148.557 243.923 148.451 243.812 148.379C243.702 148.307 243.571 148.274 243.439 148.283C243.307 148.293 243.183 148.345 243.083 148.432C242.984 148.52 242.916 148.637 242.89 148.766C242.811 149.33 243.574 150.881 245.154 152.117C246.734 153.354 243.628 153.426 242.496 153.469C241.364 153.511 237.878 153.82 237.624 154.19C237.248 154.723 237.745 155.244 238.877 155.172C240.009 155.099 243.398 155.129 243.404 155.741C243.411 156.353 237.648 156.493 237.648 156.493C237.648 156.493 236.437 156.323 236.395 157.026C236.358 158.299 242.448 157.68 243.053 157.632C243.459 157.583 243.483 158.238 243.053 158.28C242.624 158.323 237.309 158.28 237.26 159.583C237.224 160.577 242.285 159.123 243.392 159.753C244.5 160.383 239.44 160.456 239.446 161.407C239.446 161.765 239.494 162.013 242.388 161.559C244.458 161.127 246.503 160.581 248.513 159.923V159.953C248.513 159.953 278.605 157.371 277.848 143.791C277.339 134.598 277.509 136.295 276.946 131.465" fill="#B78876" class="style-240"></path>
        </svg></div>
</div>
 
</template>
<script>
export default {
      props: {
site_data:{}
  },
}
</script>
<style scoped>
    .style-0 {
        flex-direction: row;
        width: calc(100% + 32px);
        margin-right: -32px;
        margin-top: -32px;
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
    }

    .style-1 {
        flex-basis: 50%;
        flex-grow: 0;
        max-width: 50%;
        padding-right: 32px;
        padding-top: 32px;
        box-sizing: border-box;
        margin: 0px;
        flex-direction: row;
    }

    .style-2 {
        padding-bottom: 0px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .style-3 {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .style-4 {
        font-size: 40px;
        box-sizing: border-box;
        margin: 0px;
        font-weight: 700;
        line-height: 55px;
        font-family: Rubik, 'sans-serif';
        color: rgb(3, 157, 85);
    }

    .style-5 {
        color: rgb(3, 157, 85);
        box-sizing: border-box;
    }

    .style-6 {
        font-size: 40px;
        box-sizing: border-box;
        margin: 0px;
        font-weight: 700;
        line-height: 55px;
        font-family: Rubik, 'sans-serif';
    }

    .style-7 {
        color: inherit;
        box-sizing: border-box;
    }

    .style-8 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-9 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-10 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-11 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-12 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-13 {
        font-size: 18px;
        box-sizing: border-box;
        margin: 24px 0px 0px;
        line-height: 27px;
        font-family: Rubik, 'sans-serif';
        font-weight: 400;
        color: rgba(107, 114, 128, 0.8);
    }

    .style-14 {
        color: inherit;
        box-sizing: border-box;
    }

    .style-15 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-16 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-17 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-18 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-19 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-20 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-21 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-22 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-23 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-24 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-25 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-26 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-27 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-28 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-29 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-30 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-31 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-32 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-33 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-34 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-35 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-36 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-37 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-38 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-39 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-40 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-41 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-42 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-43 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-44 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-45 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-46 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-47 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-48 {
        color: inherit;
        margin-left: 3px;
        margin-left: 3px;
        box-sizing: border-box;
    }

    .style-49 {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        justify-content: flex-start;
        margin: 24px 0px 0px;
    }

    .style-50 {
        transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, border-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s;
        box-sizing: border-box;
        position: relative;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: rgb(255, 255, 255) none 0px;
        border: 0px none rgb(255, 255, 255);
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none solid rgb(255, 255, 255);
        font-weight: 600;
        font-family: Rubik, 'sans-serif';
        font-size: 17.5px;
        line-height: 30.625px;
        min-width: 64px;
        text-transform: none;
        padding: 9px 16px;
        background-color: rgb(3, 157, 85);
        height: 45px;
        border-radius: 30px;
        box-shadow: rgba(3, 157, 85, 0.2) 0px 4px 60px 0px;
        color: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style-51 {
        box-sizing: border-box;
        margin: 0px;
        font-size: 17.5px;
        font-weight: 400;
        line-height: 26.25px;
        font-family: Rubik, 'sans-serif';
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .style-52 {
        transition: fill 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
        box-sizing: border-box;
        user-select: none;
        width: 1em;
        height: 30px;
        display: block;
        fill: rgb(255, 255, 255);
        flex-shrink: 0;
        font-size: 30px;
    }

    .style-53 {
        box-sizing: border-box;
    }

    .style-54 {
        flex-basis: 50%;
        flex-grow: 0;
        max-width: 50%;
        padding-right: 32px;
        padding-top: 32px;
        box-sizing: border-box;
        margin: 0px;
        flex-direction: row;
    }

    .style-55 {
        transition: all 0.5s ease 0s;
        box-sizing: border-box;
    }

    .style-56 {
        box-sizing: border-box;
    }

    .style-57 {
        box-sizing: border-box;
    }

    .style-58 {
        box-sizing: border-box;
    }

    .style-59 {
        box-sizing: border-box;
    }

    .style-60 {
        box-sizing: border-box;
    }

    .style-61 {
        box-sizing: border-box;
    }

    .style-62 {
        box-sizing: border-box;
    }

    .style-63 {
        box-sizing: border-box;
    }

    .style-64 {
        box-sizing: border-box;
    }

    .style-65 {
        box-sizing: border-box;
    }

    .style-66 {
        box-sizing: border-box;
    }

    .style-67 {
        box-sizing: border-box;
    }

    .style-68 {
        box-sizing: border-box;
    }

    .style-69 {
        box-sizing: border-box;
    }

    .style-70 {
        box-sizing: border-box;
    }

    .style-71 {
        box-sizing: border-box;
    }

    .style-72 {
        box-sizing: border-box;
    }

    .style-73 {
        box-sizing: border-box;
    }

    .style-74 {
        box-sizing: border-box;
    }

    .style-75 {
        box-sizing: border-box;
    }

    .style-76 {
        box-sizing: border-box;
    }

    .style-77 {
        box-sizing: border-box;
    }

    .style-78 {
        box-sizing: border-box;
    }

    .style-79 {
        box-sizing: border-box;
    }

    .style-80 {
        box-sizing: border-box;
    }

    .style-81 {
        box-sizing: border-box;
    }

    .style-82 {
        box-sizing: border-box;
    }

    .style-83 {
        box-sizing: border-box;
    }

    .style-84 {
        box-sizing: border-box;
    }

    .style-85 {
        box-sizing: border-box;
    }

    .style-86 {
        box-sizing: border-box;
    }

    .style-87 {
        box-sizing: border-box;
    }

    .style-88 {
        box-sizing: border-box;
    }

    .style-89 {
        box-sizing: border-box;
    }

    .style-90 {
        box-sizing: border-box;
    }

    .style-91 {
        box-sizing: border-box;
    }

    .style-92 {
        box-sizing: border-box;
    }

    .style-93 {
        box-sizing: border-box;
    }

    .style-94 {
        box-sizing: border-box;
    }

    .style-95 {
        box-sizing: border-box;
    }

    .style-96 {
        box-sizing: border-box;
    }

    .style-97 {
        box-sizing: border-box;
    }

    .style-98 {
        box-sizing: border-box;
    }

    .style-99 {
        box-sizing: border-box;
    }

    .style-100 {
        box-sizing: border-box;
    }

    .style-101 {
        box-sizing: border-box;
    }

    .style-102 {
        box-sizing: border-box;
    }

    .style-103 {
        box-sizing: border-box;
    }

    .style-104 {
        box-sizing: border-box;
    }

    .style-105 {
        box-sizing: border-box;
    }

    .style-106 {
        box-sizing: border-box;
    }

    .style-107 {
        box-sizing: border-box;
    }

    .style-108 {
        box-sizing: border-box;
    }

    .style-109 {
        box-sizing: border-box;
    }

    .style-110 {
        box-sizing: border-box;
    }

    .style-111 {
        box-sizing: border-box;
    }

    .style-112 {
        box-sizing: border-box;
    }

    .style-113 {
        box-sizing: border-box;
    }

    .style-114 {
        box-sizing: border-box;
    }

    .style-115 {
        box-sizing: border-box;
    }

    .style-116 {
        box-sizing: border-box;
    }

    .style-117 {
        box-sizing: border-box;
    }

    .style-118 {
        box-sizing: border-box;
    }

    .style-119 {
        box-sizing: border-box;
    }

    .style-120 {
        box-sizing: border-box;
    }

    .style-121 {
        box-sizing: border-box;
    }

    .style-122 {
        box-sizing: border-box;
    }

    .style-123 {
        box-sizing: border-box;
    }

    .style-124 {
        box-sizing: border-box;
    }

    .style-125 {
        box-sizing: border-box;
    }

    .style-126 {
        box-sizing: border-box;
    }

    .style-127 {
        box-sizing: border-box;
    }

    .style-128 {
        box-sizing: border-box;
    }

    .style-129 {
        box-sizing: border-box;
    }

    .style-130 {
        box-sizing: border-box;
    }

    .style-131 {
        box-sizing: border-box;
    }

    .style-132 {
        box-sizing: border-box;
    }

    .style-133 {
        box-sizing: border-box;
    }

    .style-134 {
        box-sizing: border-box;
    }

    .style-135 {
        box-sizing: border-box;
    }

    .style-136 {
        box-sizing: border-box;
    }

    .style-137 {
        box-sizing: border-box;
    }

    .style-138 {
        box-sizing: border-box;
    }

    .style-139 {
        box-sizing: border-box;
    }

    .style-140 {
        box-sizing: border-box;
    }

    .style-141 {
        box-sizing: border-box;
    }

    .style-142 {
        box-sizing: border-box;
    }

    .style-143 {
        box-sizing: border-box;
    }

    .style-144 {
        box-sizing: border-box;
    }

    .style-145 {
        box-sizing: border-box;
    }

    .style-146 {
        box-sizing: border-box;
    }

    .style-147 {
        box-sizing: border-box;
    }

    .style-148 {
        box-sizing: border-box;
    }

    .style-149 {
        box-sizing: border-box;
    }

    .style-150 {
        box-sizing: border-box;
    }

    .style-151 {
        box-sizing: border-box;
    }

    .style-152 {
        box-sizing: border-box;
    }

    .style-153 {
        box-sizing: border-box;
    }

    .style-154 {
        box-sizing: border-box;
    }

    .style-155 {
        box-sizing: border-box;
    }

    .style-156 {
        box-sizing: border-box;
    }

    .style-157 {
        box-sizing: border-box;
    }

    .style-158 {
        box-sizing: border-box;
    }

    .style-159 {
        box-sizing: border-box;
    }

    .style-160 {
        box-sizing: border-box;
    }

    .style-161 {
        box-sizing: border-box;
    }

    .style-162 {
        box-sizing: border-box;
    }

    .style-163 {
        box-sizing: border-box;
    }

    .style-164 {
        box-sizing: border-box;
    }

    .style-165 {
        box-sizing: border-box;
    }

    .style-166 {
        box-sizing: border-box;
    }

    .style-167 {
        box-sizing: border-box;
    }

    .style-168 {
        box-sizing: border-box;
    }

    .style-169 {
        box-sizing: border-box;
    }

    .style-170 {
        box-sizing: border-box;
    }

    .style-171 {
        box-sizing: border-box;
    }

    .style-172 {
        box-sizing: border-box;
    }

    .style-173 {
        box-sizing: border-box;
    }

    .style-174 {
        box-sizing: border-box;
    }

    .style-175 {
        box-sizing: border-box;
    }

    .style-176 {
        box-sizing: border-box;
    }

    .style-177 {
        box-sizing: border-box;
    }

    .style-178 {
        box-sizing: border-box;
    }

    .style-179 {
        box-sizing: border-box;
    }

    .style-180 {
        box-sizing: border-box;
    }

    .style-181 {
        box-sizing: border-box;
    }

    .style-182 {
        box-sizing: border-box;
    }

    .style-183 {
        box-sizing: border-box;
    }

    .style-184 {
        box-sizing: border-box;
    }

    .style-185 {
        box-sizing: border-box;
    }

    .style-186 {
        box-sizing: border-box;
    }

    .style-187 {
        box-sizing: border-box;
    }

    .style-188 {
        box-sizing: border-box;
    }

    .style-189 {
        box-sizing: border-box;
    }

    .style-190 {
        box-sizing: border-box;
    }

    .style-191 {
        box-sizing: border-box;
    }

    .style-192 {
        box-sizing: border-box;
    }

    .style-193 {
        box-sizing: border-box;
    }

    .style-194 {
        box-sizing: border-box;
    }

    .style-195 {
        box-sizing: border-box;
    }

    .style-196 {
        box-sizing: border-box;
    }

    .style-197 {
        box-sizing: border-box;
    }

    .style-198 {
        box-sizing: border-box;
    }

    .style-199 {
        box-sizing: border-box;
    }

    .style-200 {
        box-sizing: border-box;
    }

    .style-201 {
        box-sizing: border-box;
    }

    .style-202 {
        box-sizing: border-box;
    }

    .style-203 {
        box-sizing: border-box;
    }

    .style-204 {
        box-sizing: border-box;
    }

    .style-205 {
        box-sizing: border-box;
    }

    .style-206 {
        box-sizing: border-box;
    }

    .style-207 {
        box-sizing: border-box;
    }

    .style-208 {
        box-sizing: border-box;
    }

    .style-209 {
        box-sizing: border-box;
    }

    .style-210 {
        box-sizing: border-box;
    }

    .style-211 {
        box-sizing: border-box;
    }

    .style-212 {
        box-sizing: border-box;
    }

    .style-213 {
        box-sizing: border-box;
    }

    .style-214 {
        box-sizing: border-box;
    }

    .style-215 {
        box-sizing: border-box;
    }

    .style-216 {
        box-sizing: border-box;
    }

    .style-217 {
        box-sizing: border-box;
    }

    .style-218 {
        box-sizing: border-box;
    }

    .style-219 {
        box-sizing: border-box;
    }

    .style-220 {
        box-sizing: border-box;
    }

    .style-221 {
        box-sizing: border-box;
    }

    .style-222 {
        box-sizing: border-box;
    }

    .style-223 {
        box-sizing: border-box;
    }

    .style-224 {
        box-sizing: border-box;
    }

    .style-225 {
        box-sizing: border-box;
    }

    .style-226 {
        box-sizing: border-box;
    }

    .style-227 {
        box-sizing: border-box;
    }

    .style-228 {
        box-sizing: border-box;
    }

    .style-229 {
        box-sizing: border-box;
    }

    .style-230 {
        box-sizing: border-box;
    }

    .style-231 {
        box-sizing: border-box;
    }

    .style-232 {
        box-sizing: border-box;
    }

    .style-233 {
        box-sizing: border-box;
    }

    .style-234 {
        box-sizing: border-box;
    }

    .style-235 {
        box-sizing: border-box;
    }

    .style-236 {
        box-sizing: border-box;
    }

    .style-237 {
        box-sizing: border-box;
    }

    .style-238 {
        box-sizing: border-box;
    }

    .style-239 {
        box-sizing: border-box;
    }

    .style-240 {
        box-sizing: border-box;
    }
</style>