// import html2pdf from 'html2pdf.js'

export default {
    created() {
    },
    mounted() {
    },
    methods: {
         setDark(){
            // localStorage.setItem("theme", "dark");
        $('html').addClass('light-style');
        $('html').removeClass('dark-style');
        document.getElementById("core_dark_rtl").disabled=true;
        document.getElementById("theme_default_dark_rtl").disabled=true;
        document.getElementById("sn_custum_dark").disabled=true;
        },
          setLight(){
            $('html').addClass('dark-style');
            $('html').removeClass('light-style');
            // localStorage.setItem("theme", "light");
        
            document.getElementById("core_dark_rtl").disabled=false;
            document.getElementById("theme_default_dark_rtl").disabled=false;
            document.getElementById("sn_custum_dark").disabled=false;
            
        },
        swithTheme(theme=false){
            return 
            if(!theme)
            {
                if (document.getElementById("main_html").classList.contains('dark-style')) 
                    theme='light-style';
                    else
                    theme='dark-style';
            }

            // if(response==='dark-style')
            // this.setLight()
            // else
            
            // this.setDark()
            if (theme=='light-style') {
               
                document.getElementById("main_html").classList.add('light-style');
                document.getElementById("main_html").classList.remove('dark-style');

                document.getElementById("core_dark_rtl").disabled=true;
                document.getElementById("theme_default_dark_rtl").disabled=true;
                document.getElementById("sn_custum_dark").disabled=true;
                this.$cookies.set('theme', 'light-style', 60 * 60 * 365, '/')   //return this
        
              } else {
                document.getElementById("main_html").classList.add('dark-style');
                
                document.getElementById("main_html").classList.remove('light-style');
                document.getElementById("core_dark_rtl").disabled=false;
                document.getElementById("theme_default_dark_rtl").disabled=false;
                document.getElementById("sn_custum_dark").disabled=false;
                this.$cookies.set('theme', 'dark-style', 60 * 60 * 365, '/')   //return this
        
              }
                      
        },
        swithSideBar(){

            if ( document.getElementById("main_html").classList.contains('layout-menu-expanded') )
            {
            document.getElementById("main_html").classList.add('layout-compact');

            document.getElementById("main_html").classList.remove('layout-menu-expanded');
            }
            else{
            document.getElementById("main_html").classList.add('layout-menu-expanded');

            document.getElementById("main_html").classList.remove('layout-compact');
            }
                },
        error4(field, errorBag = 'default') {
            console.log(field,this.$page.props.errors)
            return
            if (!this.$page.errors[field]) {
              return null;
            }
            if (this.$page.errors[field]) {
              return this.$page.errors[field][0];
            }
            return null;
          }
        ,
          HummanNum(number, decPlaces) {
            // 2 decimal places => 100, 3 => 1000, etc
            decPlaces = Math.pow(10,decPlaces);

            // Enumerate number abbreviations
            var abbrev = [ "k", "m", "b", "t" ];

            // Go through the array backwards, so we do the largest first
            for (var i=abbrev.length-1; i>=0; i--) {

                // Convert array index to "1000", "1000000", etc
                var size = Math.pow(10,(i+1)*3);

                // If the number is bigger or equal do the abbreviation
                if(size <= number) {
                     // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                     // This gives us nice rounding to a particular decimal place.
                     number = Math.round(number*decPlaces/size)/decPlaces;

                     // Handle special case where we round up to the next abbreviation
                     if((number == 1000) && (i < abbrev.length - 1)) {
                         number = 1;
                         i++;
                     }

                     // Add the letter for the abbreviation
                     number += abbrev[i];

                     // We are done... stop
                     break;
                }
            }

            return number;
        },
        getFinalCredit(account) {
return account.inaugural_credit+account.year_credit
        },
        getFinalDebit() {
            return account.inaugural_debit+account.year_debit

        },
        removeM(v){

            var  check = v.toString().includes("-")

    if(check)
    {

       v= v.replace("-", "");

       return '('+ v+')'
    }
    return v;
    if(v<0)
    {
        console.log(v,'min0')
       v= v.replace("-", "");

       return '('+ v+')'
    }
    console.log(v,'over0')
              return v.replace("-", "");
        },
         toObject(arr) {
            var rv = {};
            for (var i = 0; i < arr.length; ++i)
              rv[i] = arr[i];
            return rv;
          },
        isNumeric(n) {

            if (parseInt(n) == NaN || n == null || n=='')
                return false;


                return true;

        },
        numFormat(n) {
            if (isNaN(n))
                return 0;
            // return this.HummanNum(n,2)
            let nf1 = new Intl.NumberFormat('en-US');
            return nf1.format(n); // "1,234,567,890"
            return n.toLocaleString();
            // default behaviour on a machine with a local that uses commas for numbers
            let number = 1234567890;
            number.toLocaleString(); // "1,234,567,890"

// With custom settings, forcing a "US" locale to guarantee commas in output
            let number2 = 1234.56789; // floating point example
            number2.toLocaleString('en-US', {maximumFractionDigits: 2}) // "1,234.57"
            // 2
            let number3 = 1234567890;
            let nf = new Intl.NumberFormat('en-US');
            nf.format(number3); // "1,234,567,890"
        },
        exportToPDF(title_pdf) {

            html2pdf(this.$refs.documentpdf, {
                margin: 1,
                filename: title_pdf + '.pdf',
                image: {type: 'jpeg', quality: 0.98},
                html2canvas: {dpi: 900, letterRendering: true},
                // portrait landscape
                jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
            })
        },
        getCites4() {
            var list = []
            axios.get(route('settings.cites')).then(({data}) => {

                list = data;
            });
            return list;
        },
        setMyCookies(column) {
            console.log(column)
            this.$cookies.set(column.key, column.checked, 60 * 60 * 365, '/')   //return this
            console.log(this.$cookies.get(column.key), column.key) // return value

        },
        setCols(columns) {
            var chekCols = 0
            var _cols = []
            for (var i in columns) {
                var n = columns[i]
                n.checked = this.getMyCookies(n)
                if (n.checked == true)
                    chekCols += 1;
                _cols.push(n)
            }
            console.log({chekCols})
            if (chekCols < 2) {
                _cols[0].checked = true
                _cols[1].checked = true
                _cols[2].checked = true
            }
            return _cols;
        },
        getMyCookies(column) {
            console.log(this.$cookies.get(column.key), column.key, 'getMyCookies')
            // console.log(this.$cookies.get(column.key),this.$cookies.keys()  // return a array
            // )
            if (this.$cookies.get(column.key) == null)
                return false
            return this.$cookies.get(column.key)  // return value

        },
        getMyCookies2(column) {
            console.log(this.$cookies.get(column.key), column.key, 'getMyCookies2')
            // console.log(this.$cookies.get(column.key),this.$cookies.keys()  // return a array
            // )
            if (this.$cookies.get(column.key) == null)
                return false
            return this.$cookies.get(column.key)  // return value

        },
        setcolumnsCookie(columns) {

            // for(var col in columns){
            //     if(this.$cookies.get(col.key)==null)
            //         columns[col]=
            // }
        },

        AuditRole(key) {

            if (!this.$page.props.auth_audit)
                return false;
            let role = this.$page.props.auth_role


            let k = key.toLowerCase();
            let r = role.toLowerCase();
            if (key == 'audit'){
                if (r == 'jonuorauditor' || r == 'senuorauditor')
                return true;
            }
            if (r == k)
                return true;
            return false;
        },
        maindomain() {
            return this.$page.props.maindomain;
        },
        authAudit() {
            return this.$page.props.auth_audit;
        },
        authId() {
            return this.$page.props.auth_id;
        },
        authRole() {
            return this.$page.props.auth_role;
        },
        hasAccess(key) {
            let role = this.$page.props.auth_role
            if (key === 'revisingGuid') {
                if (role == 'super' || role == 'subscriber')
                    return true;
            }

            if (key === 'auditdropdowns') {
                if (role == 'super' || role == 'subscriber')
                    return true;
            }
            return false;
        },
        userIsSuper() {
            // if (!this.$page.props.auth_audit)
            // return false;
            let role = this.$page.props.auth_role
            if (role == 'super' || role == 'subscriber')
                return true;

            return false;
        },
        getYear(_date){
            const d = new Date(_date);
let year = d.getFullYear();
return year;
        }

    }
}
