<template>

<nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
<div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
   <a class="nav-item nav-link px-0 me-xl-4"

@click.prevent="swithSideBar()"
               href="javascript:void(0)">
                <i class="bx bx-menu bx-sm"></i>
              </a>
</div>

<div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
   

    <ul class="navbar-nav flex-row align-items-center ms-auto">
        <!-- Place this tag where you want the button to render. -->
        

              <!-- Notifications: style can be found in dropdown.less -->


                    
                                        <li class=" ">
              <a class="nav-link  " @click.prevent="swithTheme()"
                id="switch-theme" href="#">
                <i class="bx bx-sm bx-moon"></i>
              </a>
              
            </li>
  
  


            
            
 
            
                
    
        
       
              
               

              
  


              
                                                          
                
                            


        
        <li class="nav-item navbar-dropdown dropdown-user dropdown">
            <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
                <div class="avatar avatar-online">
                                    <img src="http://127.0.0.1:8001/uploads/business_logos/1700239268_5.jpg" alt="" class="w-px-40 h-auto rounded-circle">
                                        </div>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
                <li>
                    <a class="dropdown-item" href="#">
                        <div class="d-flex">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar avatar-online">
                                                                    <img src="http://127.0.0.1:8001/uploads/business_logos/1700239268_5.jpg" alt="" class="w-px-40 h-auto rounded-circle">
                                                                        </div>
                            </div>
                            <div class="flex-grow-1">
                                <span class="fw-medium d-block"> محمد عثمان</span>
                                <small class="text-muted">Admin</small>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <div class="dropdown-divider"></div>
                </li>
                <li>
                    <a class="dropdown-item" href="http://127.0.0.1:8001/user/profile">
                        <i class="bx bx-user me-2"></i>
                        <span class="align-middle">البيانات الشخصية</span>
                    </a>
                </li>
                
                 
                <li>
                    <div class="dropdown-divider"></div>
                </li>
                <li>
                    <a class="dropdown-item" href="http://127.0.0.1:8001/logout">
                        <i class="bx bx-power-off me-2"></i>
                        <span class="align-middle">تسجيل الخروج</span>
                    </a>
                </li>
            </ul>

        </li>
        <!--/ User -->     <li class="nav-itemnavbar-dropdown dropdown-user dropdown">
     <buttons-dropdown>
                             <template v-slot:title>

                             <i class="fa-solid fa-language fa-2x"></i>
                         
                             </template>
                     <li>       
            <router-link to="#"  class="dropdown-item" @click.prevent="swithLang('en')" href="#" data-language="en"><i
                class="flag-icon flag-icon-us"></i> English
            </router-link>
</li><li>
            <router-link to="#" class="dropdown-item" @click.prevent="swithLang('ar')" href="#" data-language="fr"><i
                class="flag-icon flag-icon-ar"></i> العربية
            </router-link></li>
 </buttons-dropdown>


        </li>

    </ul>
 
   
</div>
</nav>
  <div class='row' id="top_url">

  </div>
</template>
<script>
import notification from './notification.vue'

export default {
  name: 'Navbar',
  components: {notification},
  data() {
    return {
      timeNow: 0,
    }
  },
  created() {
  },
  mounted() {
    setInterval(() => this.getTime(), 1000)
  },

  methods: {
    getTime() {
      const d = new Date();
      this.timeNow = d.toLocaleString()
    },
  }
}
</script>
