import { defineStore } from 'pinia'
import Repo from '../repositories/RepositoryFactory'

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      userData: {}
    }
  },
  actions: {
    async logout() {
      
      try {
    
          this.userData = {};
       
      } catch (error) {
        throw error
      }
    },
    async login(data) {
      
      try {
        const res = await Repo.otp.verify(data);
        if (res.status === 200) {
          this.userData = res.data;
        }
      } catch (error) {
        throw error
      }
    },
  },
  getters: {
    token (state) {
      if(state.userData)
      return state.userData?.token
      return false
      },
  },
})
