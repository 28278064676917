import TestRepository from './TestRepository';
import UserRepository from './UserRepository';
import ProductsRepository from './ProductsRepository';
import AdsRepository from './AdsRepository';
import OTPRepository from './OTPRepository';
import CartRepository from './CartRepository';
import QuotesRepository from './QuotesRepository';
import AppDataRepository from './AppDataRepository';
import OrderRepository from './OrderRepository';
import SiteRepository from './SiteRepository';
import SettingsRepository from './SettingsRepository';
import ProvidersRepository from './ProvidersRepository';

const repositories = {
  providers:ProvidersRepository,
  settings:SettingsRepository,
  site:SiteRepository,
  test: TestRepository,
  users: UserRepository,
  products: ProductsRepository,
  ads: AdsRepository,
  otp: OTPRepository,
  cart: CartRepository,
  quotes: QuotesRepository,
  appData: AppDataRepository,
  order: OrderRepository
}

export default repositories