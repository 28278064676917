<template>
<div class="container single-store">
  <div class="row" v-if="!loaderOn">
     <top :store="store" />
     <products :store="store"/>
  </div></div>
</template>

<script>
import top from './store/top.vue'
import products from './store/products.vue'


export default {components: {
  top,products
},
  data() {
    return {
      loaderOn:true,
      store:{},
       total_size: 0,
    limit: 0,
    offset: 0,
    };
  },
  async created() {
   
    // this.service_types()

    this.getData();
  },
  methods: {
    async getData(page = 1) {
      try {
          var u='/stores/details/'+this.$route.params.id
        var res = await this.client.get(u);

        if (res.status === 200) {
          this.store = res.data;
           document.title = this.tr(this.store.name);
 this.total_size=res.data.total_size
    this.limit= res.data.limit
    this.offset= res.data.offset
          this.loaderOn = false;
        }
      } catch (error) {
        this.loaderOn = false;
        throw error;
      }
    },
  },
};
</script>
