<template>
    <div class="container">

        <div class="row">
<div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
<div class="checkout-progress-wrap">
                            <ul class="steps">
                                <li class="step 1st">
                                    <div class="checkout-act active">
                                        <h3 class="title-box"><span class="number">1</span>{{tr('Payment Method')}}</h3>
                                        <div class="box-content ">
  <div class="row">

<div class="col-xs-6">
<div class="btn btn-thin btn-method"

    :class="(order_detail.payment_method=='cash_on_delivery')?'active':''"
 @click.prevent="setPaymethod(1)">
      <div class="MuiBox-root mui-style-1atvzjw" smheight="20px" sm_width="20px"><img src="/theme/img/home.png" loading="lazy"></div>

<p class="MuiTypography-root MuiTypography-body1 text mui-style-1ssdcfi">{{tr('cash on delivery')}}</p>
  </div>

</div>
<div class="col-xs-6">
   
</div>
  </div>
  
  
                                        </div>
                                    </div>
                                </li>
                                <li class="step 2nd">
                                    <div class="checkout-act"><h3 class="title-box"><span class="number">2</span>{{tr('Delivery Options')}}</h3>
                                    <div class="row">

<div class="col-xs-6">
<div class="btn btn-thin btn-method" @click.prevent="setDelivery(1)">
      <div class="MuiBox-root mui-style-1atvzjw" smheight="20px" sm_width="20px"><img src="/theme/img/home.png" loading="lazy"></div>

<p class="MuiTypography-root MuiTypography-body1 text mui-style-1ssdcfi">{{tr('Home Delivery')}}</p>
  </div>

</div>
<div class="col-xs-6">
    <div class="btn btn-thin btn-method"
    :class="order_detail.order_type=='take_away'?'active':''"
     @click.prevent="setDelivery(1)">
      <div class="MuiBox-root mui-style-1atvzjw" smheight="20px" sm_width="20px"><img src="/theme/img/home.png" loading="lazy"></div>

<p class="MuiTypography-root MuiTypography-body1 text mui-style-1ssdcfi">{{tr('I’ll Pick It Up MySelf')}}</p>
  </div>
</div>
  </div>
                                    
                                    </div>
                                </li>
                                
                                
                            </ul>
                        </div>
</div>
<div class="col-lg-5 col-md-5 col-sm-6 col-xs-12 sm-padding-top-48px sm-margin-bottom-0 xs-margin-bottom-15px">
    <div class="order-summary sm-margin-bottom-80px">
                            <div class="title-block">
                                <h3 class="title">{{tr('Order Summary')}}</h3>
                                <a href="#" class="link-forward">Edit cart</a>
                            </div>
                            <div class="cart-list-box short-type">
                                <span class="number">{{cart_store.cart_items.length??0}} {{tr('items.')}}</span>
                                <ul class="cart-list">
                                    
                                    <li class="cart-elem" v-for="(item,index) in  cart_store.cart_items" :key="index">
                                        <div class="cart-item">
                                            <div class="product-thumb">
                                                <router-link     :to="{ name: 'product.show', params: { id: item.item.id } }"  class="prd-thumb"  >
                                                    <figure><img      v-lazy="base_domain+'upload/product/'+item.item.image"  width="113" height="113" alt="shop-cart"></figure>
                                                </router-link>
                                            </div>
                                            <div class="info">
                                                <span class="txt-quantity">{{item.quantity}}</span>
                                                                     <router-link      :to="{ name: 'product.show', params: { id: item.item.id } }"  class="pr-name"  >{{item.item.name}}</router-link>

                                            </div>
                                            <div class="price price-contain">
                                                <ins><span class="price-amount"><span class="currencySymbol">£</span>{{item.price}}</span></ins>
                                                <!-- <del><span class="price-amount"><span class="currencySymbol">£</span>95.00</span></del> -->
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="subtotal">
                                    <li>
                                        <div class="subtotal-line">
                                            <b class="stt-name">Subtotal</b>
                                            <span class="stt-price">£170.00</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="subtotal-line">
                                            <b class="stt-name">Shipping</b>
                                            <span class="stt-price">£20.00</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="subtotal-line">
                                            <b class="stt-name">Tax</b>
                                            <span class="stt-price">£0.00</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="subtotal-line">
                                            <a href="#" class="link-forward">Promo/Gift Certificate</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="subtotal-line">
                                            <b class="stt-name">total:</b>
                                            <span class="stt-price">£190.00</span>
                                        </div>
                                    </li>
                                    <li><button type="button"
                                    @click.prevent="PlaceOrder()"
                                     name="btn-sbmt" class="btn place_order">{{tr('Place Order')}}</button></li>
                                </ul>
                            </div>
                        </div>
</div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
    return {
        order_detail:{
        
        },
      loaderOn:true,
     
       total_size: 0,
    limit: 0,
    offset: 0,
    };
  },
  async created() {
   document.title = this.tr('Checkout');
 this.order_detail.order_amount=55
 this.order_detail.distance=5
 this.order_detail.store_id=this.cart_store.store_id
 
 this.order_detail.longitude=90.36934144046135
              this.order_detail.latitude=23.81695886557418
 
  },
 methods:{
  
     setDelivery(id){
this.order_detail.order_type='take_away'
     },
     setPaymethod(id){

this.order_detail.payment_method='cash_on_delivery'
     }
 }   
}
</script>
<style scoped>

.btn-method,.place_order{
        display: inline-flex;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 600;
    font-family: Rubik, "sans-serif";
    font-size: 0.875rem;
    line-height: 1.75;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    text-transform: none;
    /* color: rgb(255, 255, 255); */
    background-color: rgb(255, 255, 255);
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(3, 157, 85);
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 20px;
    padding: 7px 16px;
 
}
.place_order{
    font-weight: bold;
}
.btn-method p,.place_order{
      font-size: 1.5rem;
}
.btn-method.active p,.active.place_order{
      
          color: #ffffff !important;
}

.btn-method.active,.active.place_order{
    border-color: #7faf51;
        color: #ffffff !important;
            background-color: #7faf51;
 
} 
.mui-style-1atvzjw img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.mui-style-1atvzjw {
    display: inline-flex;
    background: transparent;
    width: 30px;
    height: 30px;
    position: relative;
}
.mui-style-1ssdcfi {
    margin: 0px;
    font-size: 0.875rem;
    line-height: 1.5;
    font-family: Rubik, "sans-serif";
    font-weight: 400;
    color: rgb(55, 65, 81);
}
 
.order-summary .subtotal-line .stt-name, .shpcart-subtotal-block .subtotal-line .stt-name{
    float:left;
}
 
 
</style>