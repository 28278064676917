import Client from './Clients/AxiosClient';
const change_language = '/change_language';
const all_city = '/city/all_city';
const city="/city/city"
const get_country_json_list = '/country/get_country_json_list';
const admin_dashboard = '/admin/dashboard';
const dispatcher = '/dispatcher';
const corporate = '/corporate';



export default {
  dispatcher(page,form_filter=false){
    let url=dispatcher+page
    if (!form_filter) return Client.get(`${url}`);
    else return Client.post(`${url}`, form_filter);
  },
  
  corporate(page,form_filter=false){
   

    let url=corporate+page
    if (!form_filter) return Client.get(`${url}`);
    else return Client.post(`${url}`, form_filter);
  },
  admin_dashboard(){
    return Client.get(`${admin_dashboard}`);
  },
  all_country() {
    return Client.get(`${get_country_json_list}`);
  },
  all_city(page,form_filter=false) {
    
    // let url=all_city+page
    let url=city+page
    
    if (!form_filter) return Client.get(`${url}`);
    else return Client.post(`${url}`, form_filter);
  },
  
  get_country() {
    return Client.post(`${get_country}`);
  },
  changeLanguage(data) {
    return Client.post(`${change_language}`,
      data
    )
  },
   
}