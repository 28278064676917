import Client from './Clients/AxiosClient';
const CartResource = '/cart'
const CartItemResource = '/cart_items';
const CouponResource = '/coupon';
const RemoveCartItemResource = '/cart_items'
const ClearCartResource = '/clear_cart'

export default {
  getCart() {
    return Client.get(`${CartResource}`)
  },
  getCartDirectPurchase() {
    return Client.get(`${CartResource}?is_direct_purchase=false`)
  },
  addCartItem(price_id, slicing_id, options_ids_array, note_text, in_cart_status, quantity_number) {
    return Client.post(`${CartItemResource}`, {
      "product_price_id": price_id,
      "slicing_method_id": slicing_id,
      "option_ids": options_ids_array,
      "note": note_text,
      "in_cart": in_cart_status,
      "quantity": quantity_number
    })
  },
  addCoupon(code_text) {
    return Client.post(`${CouponResource}`, {
      "code": code_text
    })
  },
  deleteCartItem(cart_item_id) {
    return Client.delete(`${RemoveCartItemResource}/${cart_item_id}`)
  },
  clearCart() {
    return Client.post(`${ClearCartResource}`)
  }
}