import login from "../Pages/Auth/Login.vue";

const routes = [
    {
        path: "/login",
        name: "login",
        component: login,
        
      }
]
export default routes;