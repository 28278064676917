<template>
<div class="container">
<div class="row" v-if="!loaderOn">
 <div v-html="page.value"></div>
  </div>
</div>
  
</template>

<script>
import StarRating from 'vue-star-rating'


export default {components: {
  StarRating
},

  data() {
    return {
      loaderOn:true,
       page:{}
    };
  },
  async created() {
    // document.title = this.tr("mapview");
    // this.service_types()
document.title = this.tr(this.pageType);
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      try {
          var u='page/'+this.pageType
        var res = await this.client.get(u);

        if (res.status === 200) {
          this.page = res.data;
   
          this.loaderOn = false;
        }
      } catch (error) {
        this.loaderOn = false;
        throw error;
      }
    },
  },
   computed: {
    pageType() {
      return this.$route.params.slug;
    },
  },
  watch: {
    "$route.params.slug": {
      immediate: true,
      handler(slug) {
        document.title = this.tr(this.pageType);
        // Update the content based on the id parameter
        this.getData();
      },
    },
  },
};
</script>
