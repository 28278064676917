<template>
   <div class='col-12'>

          <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <router-link  to="/">{{tr('dasboard')}}</router-link>
                      </li>
                      
                      <li  v-for="(link,index) in links" :key="index" class="breadcrumb-item">
                        <router-link :to="link.url">
                         {{tr(link.title)}}</router-link>
                      </li>

                      
                      <li class="breadcrumb-item">
                        <a @click="goBack" href="javascript:void(0);">{{('goBack')}}</a>
                      </li>
                       
                     </ol>

                  </nav>

      </div>  
</template>

<script>

export default {
props: {
links:[]
  },
      computed: {
        data() {
            return[]
        }},
mounted(){


},

      data() {
    return {

    
 

        }}

}
</script>
