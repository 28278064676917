<template>
    <div class="shopping-cart-container">
                    <div class="row" v-if="!loaderOn">
                        <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                            <h3 class="box-title">Your cart items</h3>
                            <form class="shopping-cart-form" action="#" method="post">
                                <table class="shop_table cart-form">
                                    <thead>
                                    <tr>
                                        <th class="product-name">Product Name</th>
                                        <th class="product-price">Price</th>
                                        <th class="product-quantity">Quantity</th>
                                        <th class="product-subtotal">Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(item,index) in  cart_store.cart_items" :key="index" >
                                      <item :item="item" />
                                        </template>
                                    
                                    <tr class="cart_item wrap-buttons">
                                        <td class="wrap-btn-control" colspan="4">
                                            <router-link to="/stores" class="btn btn-info" >Back to Shop</router-link>
                                            
                                            <button class="btn btn-clear" @click.prevent="clearCart()" type="reset">clear all</button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <div class="shpcart-subtotal-block">
                                <div class="subtotal-line">
                                    <b class="stt-name">Subtotal <span class="sub">(2ittems)</span></b>
                                    <span class="stt-price">£170.00</span>
                                </div>
                                <div class="subtotal-line">
                                    <b class="stt-name">Shipping</b>
                                    <span class="stt-price">£0.00</span>
                                </div>
                                <div class="tax-fee">
                                    <p class="title">Est. Taxes &amp; Fees</p>
                                    <p class="desc">Based on 56789</p>
                                </div>
                                <div class="btn-checkout">
                                    <router-link to="/chekout"     class="btn checkout">{{tr('Proceed to Checkout')}}</router-link>
                                </div>
                                <div class="biolife-progress-bar">
                                    <table>
                                        <tbody><tr>
                                            <td class="first-position">
                                                <span class="index">$0</span>
                                            </td>
                                            <td class="mid-position">
                                                <div class="progress">
                                                    <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </td>
                                            <td class="last-position">
                                                <span class="index">$99</span>
                                            </td>
                                        </tr>
                                    </tbody></table>
                                </div>
                                <p class="pickup-info"><b>Free Pickup</b> is available as soon as today More about shipping and pickup</p>
                            </div>
                        </div>
                    </div>
                </div>
</template>
<script>
import item from './item.vue'
export default {
 components: {
  item
},
  data() {
    return {
      loaderOn:true,
     
       total_size: 0,
    limit: 0,
    offset: 0,
    };
  },
  async created() {
   document.title = this.tr('cart');
 
 
    this.getCartData();
  },
  methods: { 
    // 
   

     
  }
}
</script>