
<template>

    <router-link to="href" :class="classes">
  {{ _title}}
        <slot />
    </router-link>
</template>
<script  >

// import { computed } from 'vue';

export default {
props : [
    'href' ,
    'title' ,
    'active'
]
,
 data() {
    return {
      // editor: ClasicEditor,
      _title:this.title??'',


    }}}

// const classes = computed(() => {
//     return props.active
//         ? 'nav-link'
//         : 'nav-link';
// });
// const title = computed(() => {
//     return props.title;
// });
</script>
