<template>
  <div
    class="MuiGrid-root txt-content MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4 rtl-uefhsl"
  >
    <a href="tel:01700000000"
      ><div class="MuiBox-root rtl-8uka4h">
        <div class="MuiBox-root rtl-8axkuw">
          <img :src="icon" alt="help" />
        </div>
        <div class="MuiBox-root rtl-xi606m">
          <p class="MuiTypography-root MuiTypography-body1 title rtl-p71ag8">
           {{title}}
          </p>
          <p class="MuiTypography-root MuiTypography-body1 title rtl-1vvpufe">
            {{text}}
          </p>
        </div>
      </div></a
    >
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    icon: String,
    title: String,
  },
};
</script>
