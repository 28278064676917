 
import Client from '../repositories/Clients/AxiosClient';

 


 
export const apiCart = {
 
    methods: {
      async  addToCart(){
        this.cart_store.setStoreId(this.product.store_id);
        try {
                  var u='/customer/cart/add' 
        var add_item_data={}
                  add_item_data.item_id =this.product.id
                    add_item_data.model='Item'
                    add_item_data.price=this.product.price
                    add_item_data.quantity=this.quantity
                var res = await this.client.post(u,add_item_data);
                if (res.status === 200) {
           this.cart_store.setCartItems(res.data)
           this.cart_id=this.cart_store.getCartId(this.product.id),
           this.alertSuccess(res.data.message)
                  this.in_cart=true
                       this.loaderOn = false;
                }
              } catch (error) {
                      throw error;
              }
              },
      async deleteItem(cart_id) {
        if (confirm("هل انت متأكد من حذف المنتج؟")) {
          try {
            var _data={}
            _data.cart_id=cart_id;
  
 
    var u='customer/cart/remove-item' 
          var res = await this.client.delete(u,{data:{cart_id:cart_id}});
          
          if (res.status === 200) {
             this.alertSuccess("تم حذف المنتج من السلة بنجاح");
             this.in_cart=false
                     this.cart_store.setCartItems(res.data)
            }
          } catch (error) {
            throw error;
          }
        }
      },
      async clearCart() {
        if (confirm("هل انت متأكد من افراغ السلة؟")) {
          try {
            var u='customer/cart/remove' 
          var res = await this.client.delete(u);
          
            if (res.status === 200) {
              this.alertSuccess("تم افراغ السلة بنجاح");
              this.getCartData();
            }
          } catch (error) {
            throw error;
          }
        }
      },
      async   updateToCart(){
        try {
                  var u='/customer/cart/update' 
        var add_item_data={}
                  add_item_data.item_id =this.product.id
                    add_item_data.model='Item'
                    add_item_data.price=this.product.price
                    add_item_data.quantity=this.quantity
                    add_item_data.cart_id=this.cart_store.getCartId(this.product.id)
                    console.log(add_item_data.cart_id,'add_item_data.cart_id')
                var res = await this.client.post(u,add_item_data);
                if (res.status === 200) {
           this.cart_store.setCartItems(res.data)
           this.alertSuccess()
                  this.in_cart=true
        
                       this.loaderOn = false;
                         
                }
              } catch (error) {
                    this.quantity=   this.cart_store.getProductQuantity(this.$route.params.id)
        
                     this.alertError(error.data.errors[0]['message'])
                      throw error;
              }
               },
      async getCartData(page = 1) {
        try {
            var u='customer/cart/list' 
          var res = await this.client.get(u);
  
          if (res.status === 200) {
  
           this.cart_store.setCartItems(res.data)
            
   
            this.loaderOn = false;
          }
        } catch (error) {
          this.loaderOn = false;
          throw error;
        }
      },
      async PlaceOrder(page = 1) {
        try {
            var u='customer/order/place' 
          var res = await this.client.post(u,this.order_detail);
  
          if (res.status === 200) {
           this.getCartData()
   this.alertSuccess(res.data.message)
            this.loaderOn = false;
            this.$router.push("/")
          }
        } catch (error) {
          this.loaderOn = false;
          throw error;
        }
      },  
    }
}
