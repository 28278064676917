<template>
 
<div class="row">

                    <!--Form Sign In-->
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div class="signin-container">
                                 <h4 class="mb-2">Welcome to {{ tr("site_title") }}! 👋</h4>
              <p class="mb-4">Please sign-in to your account and start  </p>

                            <form action="#" 
                            
                             role="form"
        
        @submit.prevent="verify"
       
                            name="frm-login" method="post">
                                <p class="form-row">
                                    <label for="fid-name">{{tr('phone')}} :<span class="requite">*</span></label>
                                    <input type="text" 
                                      v-model="login_data.phone" name="phone" 
                                    
                                    id="fid-name"   class="txt-input">
                                </p>
                                <p class="form-row">
                                    <label for="fid-pass">{{tr('password')}}:<span class="requite">*</span></label>
                                    <input  
                                      v-model="login_data.password" name="password" type="password" 
                                    
                                     id="fid-pass"    class="txt-input">
                                </p>
                                <p class="form-row wrap-btn">
                                    <button class="btn btn-submit btn-bold" type="submit">sign in</button>
                                    <a href="#" class="link-to-help">Forgot your password</a>
                                </p>
                            </form>
                        </div>
                    </div>

                    <!--Go to Register form-->
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div class="register-in-container">
                            <div class="intro">
                                <h4 class="box-title">New Customer?</h4>
                                <p class="sub-title">Create an account with us and you’ll be able to:</p>
                                <ul class="lis">
                                    <li>Check out faster</li>
                                    <li>Save multiple shipping anddesses</li>
                                    <li>Access your order history</li>
                                    <li>Track new orders</li>
                                    <li>Save items to your Wishlist</li>
                                </ul>
                                <a href="#" class="btn btn-bold">Create an account</a>
                            </div>
                        </div>
                    </div>

                </div>
   
<!-- end -->
   
 
     

  
    




   
</template>

<script  >
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      login_data: {
        phone: "0912300000",
        // "info@zaincar.com"

        password: "22222222"

      }
      ,
      role: "",
      form: {
        phone: "",
        ppassword: "",

      },
    };
  },
  methods: {
    async login() {
      // const res = await axios.post('https://dev.with.ws/api/v2/login', this.login_data)
      // console.log(`login2: `, JSON.stringify(res))
      // return
            console.log('login')
      this.buttonLoader = true;
      try {

        const res = await this.repo.users.login(

          this.login_data);
        console.log(res)
        if (res.status === 200) {
          this.numberSent = false;
          this.buttonLoader = false;
        }
        this.buttonLoader = false;
      } catch (error) {
        throw error;
        this.alertError()
        this.buttonLoader = false;
      }
    },
  async verify() {
      this.buttonLoader = true;
      this.user_store.login(
        this.login_data
        
        ).then(() => {
        this.buttonLoader = false;
        this.verified = true;
//  this.getUserData();
 this.$router.push("/");
        // this.userName = true;
      }).catch((error) => {
          this.alertError();
        });
    },
      async getUserData() {
      try {
        const res = await this.repo.users.getUserData();
        if (res.status === 200) {
          this.userName = res.data.user.name;
        }
      } catch (error) {
        throw error;
      }
    },
  },
  created() {
    // this.verify()
    document.title = this.tr('title')
    for (var key in this.form_search)
      this.form[key] = this.form_search[key]
  },
  props: {

    data: [],
    years: [],
    form_search: {},
    roles: [],
    columns: [],
    list: Object,
  },
};
</script>
<style></style>