import Client from './Clients/AxiosClient';
const Resource = '/products';
const SlicingMethodResource = '/slicing_method'

export default {
  getAllProducts() {
    return Client.get(`${Resource}`)
  },
  getOneProduct(product_id) {
    return Client.get(`${Resource}/${product_id}`)
  },
  getSlicingMethods() {
    return Client.get(`${SlicingMethodResource}`)
  }
}