export default {
    created() {},
    mounted() {},
    methods: {
        SumFourthLVLAccountPrevColumn(FourthLVLAccount, col) {
            var sums = 0;
            FourthLVLAccount.account_transactionprev.forEach((item) => {
                sums += this.convertNumber(item[col]);
            });
            return sums;
        },
        SumFourthLVLAccountColumn(FourthLVLAccount, col) {
            var sums = 0;
            FourthLVLAccount.account_transaction.forEach((item) => {

                if (col == 'impact')
                    sums += this.convertNumber(this.finalImpact(item))
                else
                    sums += this.convertNumber(item[col]);
                            });

            return sums;
        },
        convertNumber(n) {
            if (n == '' || n == null)
                return 0
            var num = parseFloat(n)
            if (num == 0 || num == NaN)
                return 0
            return num
        },
        CompareSumFourthLVLAccountColumn(FourthLVLAccount, col) {
            var sums = 0;
            FourthLVLAccount.account_transaction.forEach((item) => {
                if (col == 'impact')
                    sums += this.convertNumber(this.finalImpact(item.compare))
                else
                    sums += this.convertNumber(item.compare[col]);
            });
            return sums;
        },
        ComparePrevSumFourthLVLAccountColumn(FourthLVLAccount, col) {
            var sums = 0;
            FourthLVLAccount.account_transaction.forEach((item) => {
                if (col == 'impact')
                    sums += this.convertNumber(this.finalImpact(item.compareprev))
                else
                    sums += this.convertNumber(item.compareprev[col]);
            });
            return sums;
        },
        calcBalanceType(acc, col) {
            var impact = 0;
            var intotals_credit = 0,
                intotals_debit = 0,
                final_debit = 0,
                final_credit = 0,
                type = "";
            var b = this.convertNumber(acc.intotals_debit) - this.convertNumber(acc.intotals_credit) +
                this.convertNumber(acc.impact_debit) - this.convertNumber(acc.impact_credit);


            if (b > 0) {
                final_debit = b;
            } else {
                if (b == 0)
                    final_credit = 0
                else
                final_credit = -1 * b;
            }
            if (col === "credit") {
                return Math.round(final_credit);
            } else if (col === "debit") {
                return Math.round(final_debit)
            }


            return 0;
        },
        finalBlance(acc) {
            if (this.convertNumber(acc.final_debit) == 0)
                return this.convertNumber(acc.final_credit);
            return this.convertNumber(acc.final_debit);
        },
        SumFourthLVLAccountInauguralBlance(FourthLVLAccount) {
            var sums = 0,
                b = 0;
            FourthLVLAccount.account_transaction.forEach((item) => {
                b = this.convertNumber(item.inaugural_debit) - this.convertNumber(item.inaugural_credit)
                sums += this.convertNumber(b);
            });
            return sums;
        },
        SumFourthLVLAccountBlance(FourthLVLAccount) {
            var sums = 0,
                b = 0;
            FourthLVLAccount.account_transaction.forEach((item) => {
                b = this.finalBlance(item)
                sums += this.convertNumber(b);
            });
            return sums;
        },
        CompareSumFourthLVLAccountBlance(FourthLVLAccount) {
            var sums = 0,
                b = 0;
            FourthLVLAccount.account_transaction.forEach((item) => {
                b = this.finalBlance(item.compare)
                sums += this.convertNumber(b);
            });
            return sums;
        },
        ComparePrevSumFourthLVLAccountBlance(FourthLVLAccount) {
            var sums = 0,
                b = 0;
            FourthLVLAccount.account_transaction.forEach((item) => {
                b = this.finalBlance(item.compareprev)
                sums += this.convertNumber(b);
            });
            return sums;
        },
        finalImpact(acc) {
            if (this.convertNumber(acc.impact_credit) == 0)
                return this.convertNumber(acc.impact_debit);
            return this.convertNumber(acc.impact_credit);
        },
        intotalsBalanceType(acc, col) {
          var  intotals_debit  = this.convertNumber(acc.inaugural_debit) + this.convertNumber(acc.year_debit);
         var    intotals_credit  =  this.convertNumber(acc.inaugural_credit) + this.convertNumber(acc.year_credit);
            if (col == 'debit')
                return intotals_debit
                else if (col == 'credit')
                return  intotals_credit
        }
    },
};
