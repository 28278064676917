<template>
     <li class="nav-item dropdown dropdown-notification mr-25">
         <a class="nav-link"
      href="javascript:void(0);" data-toggle="dropdown">
         </a>
                </li>

        <!--end -->

</template>
<script>

export default  {
    data() {
        return {
            dropdown_class:'',
            loading:false,
unread:"",
            notifications:[]
        };
    },
    methods: {
        GetNotification() {
            axios.get(route("settings.notifications"))
                .then((res ) => {

                    this.notifications = res.data.notifications;
                    this.unread = res.data.unread;
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                });
        },

        updateLang(){
            var   n= document.getElementById("my-noty"); //('body')[0];

            if(this.getLang()!='ar')
            {
                // this.dropdown_class='dropdown-menu-end'
                n.classList.add("dropdown-menu-end");
            }
            else{
                // this.dropdown_class=''
                n.classList.remove("dropdown-menu-end");
            }
        }
    }
    ,
    created() {



        this.GetNotification()
        this.loading=true
        // this.emitter.on('updatelang', this.updateLang);
        // this.updateLang()

    }
}
</script>
<style>

</style>
