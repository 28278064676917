// define a mixin object
import {
    createToast
} from 'mosha-vue-toastify';
//   import CustomizedContent from "./CustomizedContent.vue";
import 'mosha-vue-toastify/dist/style.css';
export default {
    created() {
        //   this.hello()
    },
    methods: {
        allPermissions() {
            return this.$page.props.allPermissions;
        },
        authrole() {
            return this.$page.props.myauth.role;
            // return window.location.href.startsWith(this.href)
        },
        exportExcel(routename, id = false) {
            let url = 'export.' + routename
            if (id)
                window.open(route(url, id), '_blank');
            else
                window.open(route(url), '_blank');

        },
        openBlank(routename, id = false) {
            let url =   routename
            if (id)
                window.open(route(url, id), '_blank');
            else
                window.open(route(url), '_blank');

        },
        chekCols(columns, col) {

            var valObj = columns.filter(function (elem) {
                if (elem.key == col) {

                    return elem;
                }
                return false;
            });


            if (valObj[0]) {
                // $cookies.set(col, valObj[0].checked[ 60])   //return this
//                 // console.log($cookies.keys() )
//                 console.log(valObj[0].key,'valObj[0].key',this.$cookies.get(valObj[0].key))
// if(this.$cookies.get(valObj[0].key)==true)
//     return true
//                 return false;

                return valObj[0].checked
            }

            return false;

        },
        alertSuccess(text = 'تمت العملية بنجاح') {
            createToast({
                title: 'نجاح العملية',
                description: text
            }, {
                position: 'top-right',
                showIcon: true,
                type: 'success',
                timeout: 5000,
                transition: 'slide',
            })
        },
        alertError(text= 'حدث خطأ أعد المحاولة') {
            createToast({
                title: 'فشل العملية ',
                description:text
            }, {
                position: 'top-right',
                showIcon: true,
                type: 'danger',
                timeout: 5000,
                transition: 'slide',
            })
        },
        AuthRole() {
            return this.$page.props.auth_role
        },

        hasModule(key) {
            return true;
            let allmodules = this.$page.props.allmodules
            if (allmodules == 'mainsite')
                return true;
                let i = allmodules.indexOf(key)
                if (i == -1)
                    return false;
                return true;
                   },

        chekPerm(key) {


            let allPermissions = this.get_allPermissions()


            let i = allPermissions.indexOf(key)
            if (i == -1)
                return false;
            return true;
               },
               get_allPermissions(){

                   return [];
               },
        hasAnyPerm(keys) {

            let allPermissions = this.get_allPermissions()

            for (var k in keys)
            {
                var r=keys[k]
                let i = allPermissions.indexOf(r)
                if (i != -1)
                    return true;
                       }


                    return false;

                   },
        chekRole(key) {

            let role =this.$page.props.auth_role

            if (role == key)
                return true;
            return false;


            console.log(index, i, i2)

        }
    }
}
