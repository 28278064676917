<template>
 

  <div class="btn-group">
                      <button type="button" 
                      @click="show()"
                     
                      class="btn btn-secondary dropdown-toggle" 
                        :class="_class"
                      
                      data-bs-toggle="dropdown" aria-expanded="false">
                     
                       <slot name="title"/>
                      </button>
                      <ul class="dropdown-menu"   :class="_class" >
                          <slot/>
                    
                     </ul>
                    </div>
</template>

<script>
 
  export default {
   
   
    data () {
      return {
          _class:"",
        sharedState: {
          active: false
        }
      }
    },
    methods: {
         show () {
             if(this._class=='')
        this._class = 'show'
        else
          this._class = ''
      },
      toggle () {
        this.sharedState.active = !this.sharedState.active
      },
      away () {
        this.sharedState.active = false
      }
    }
  }
</script>