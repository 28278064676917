<template>
    <div class="banner-block   ">
                <div class="container">
                    <div class="biolife-banner detail-product biolife-banner__detail-product">
                        <div class="banner-contain">
                            <div class="row">
                                <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                    <div class="media">
                                        <a href="#" class="bn-link">
                                            <img class="store-cover"
                                            v-lazy="base_domain+'upload/store/cover/' +store.cover_photo"  width="647" height="647" alt=""></a>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 md-margin-top-0 sm-margin-top_58px xs-margin-top-0">
                                    <div class="text-content">
                                        <div class="head-info">
                                            <div class="row">
<div class="col-xs-12 col-sm-4">
  <img class="store-logo"
                                            v-lazy="base_domain+'upload/store/' +store.logo"  width="647" height="647" alt=""/> 
                                   
</div>
<div class="col-xs-12 col-sm-8">
     <h3 class="text1">{{store.name}}</h3>
                                            <h4 class="text2">{{store.address}}</h4>
                                        
 <div class="midle-info">
                                            <p class="pr-desc">  <b class="title">{{tr('Delivery Time')}}:</b>{{store.delivery_time}}</p>
                                            <p class="pr-desc">  <b class="title">{{tr('Ratings')}}:</b>                                  <star-rating read-only="true"	 :rating="store.avg_rating"
           star-size="30"	 /></p>
                                            
                                        </div>
</div>


                                            </div>
                                          
                                       </div>
                                       
                                        <div class="bottom-info">
   </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>
<script>
import StarRating from 'vue-star-rating'

export default {components: {
  StarRating
},
      props: {
    store:{ }
  },
}
</script>

<style scoped>
.bn-atts-item .content{
    font-size: unset;
}
.biolife-banner__detail-product .text-content {
    padding-top: unset;
}
</style>