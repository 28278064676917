import { defineStore } from 'pinia'
import Repo from '../repositories/RepositoryFactory'

export const useSettingsStore = defineStore("settings", {
  state: () => {
    return {
        type_list:[],
        has_type_list:false,
        settings:{
            
        }
        ,
      userData: {}
    }
  },
  actions: {

    async  get_type_list(){
        if(this.has_type_list)
        return  this.type_list
        try {
           var res = await Repo.settings.type_list();
            var self = this;  
            var type_list={}
            var l=res.data.type_list
            console.log(l)
            if (res.status === 200) {
                if(l.length>0)
               {
                   l.forEach(function (item) {
                  

                     type_list[item._id]=item.typename ;

                  });
                  console.log(  this.type_list)
              this.type_list = type_list;
                }
             
              this.has_type_list = true;
            }
          } catch (error) {
            throw error
          }
    },
    async login(data) {
      
      try {
        const res = await Repo.otp.verify(data);
        if (res.status === 200) {
          this.userData = res.data;
        }
      } catch (error) {
        throw error
      }
    },
  },
  getters: {
    type_lists(state){
        
        return state.type_list
    },
    token (state) {
      if(state.userData)
      return state.userData.adminDetail?.token
      return false
      },
  },
})
