
<script>

export default {
props : [
    'href' ,
    'title' ,
    'active'
]
,
 data() {
    return {
      // editor: ClasicEditor,
      _title:this.title??'',


    }}}

// const classes = computed(() => {
//     return props.active
//         ? 'nav-link'
//         : 'nav-link';
// });
// const title = computed(() => {
//     return props.title;
// });
</script>

<template>

    <router-link to="href"  class="btn btn-success">
    <i class="fa fa-plus"></i>
  {{ tr('add') }} {{ tr(title) }}
        <slot />
    </router-link>
</template>
