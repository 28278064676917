


export const trans = {
    // setup() {
    //     const { t } = useI18n({});
    //     return { t };
    // },
    methods: {}

}
