import Client from './Clients/AxiosClient';
const availableTimesResource = `/available_times`
const locationDetailsResource = '/location_details'
const orderResource = '/orders'
const paymentMethodsResource = '/payment_methods'
const bankAccountsResource = '/bank_accounts'

export default {
  getAvailableTimes(month, year) {
    return Client.get(`${availableTimesResource}?month=${month}&year=${year}`)
  },
  getLocationDetails(lat, lng) {
    return Client.get(`${locationDetailsResource}?latitude=${lat}&longitude=${lng}`)
  },
  createOrder(date, delivery_time_id, other_person_name, other_person_phone, payment_method_id, coupon, lat, lng, city, district, street, note) {
    return Client.post(`${orderResource}`, {
      "date": date,
      "delivery_time_id": delivery_time_id,
      "other_person_name": other_person_name,
      "other_person_phone": other_person_phone,
      "payment_method_id": payment_method_id,
      "latitude": lat,
      "longitude": lng,
      "city": city,
      "district": district,
      "street": street,
      "note": note,
      "is_direct_purchase": false,
      "coupon_code": coupon
    })
  },
  getOrders() {
    return Client.get(`${orderResource}`)
  },
  getPaymentMethods() {
    return Client.get(`${paymentMethodsResource}`)
  },
  getBankAccounts() {
    return Client.get(`${bankAccountsResource}`)
  }
}