<template>
  <div class="contain-product contain-product__right-info-layout3">
    <div class="product-thumb">
      <a href="#" class="link-to-product" tabindex="0">
        <img
          :src="icon"
          alt="Vegetables"
          width="270"
          height="270"
          class="product-thumnail"
        />
      </a>
    </div>
    <div class="info">
      <h4 class="product-title1">
        {{title }}
      </h4>

      <p class="desc">
        {{
          text
        }}
      </p>
      <div class="buttons">
        <a href="#" class="btn add-to-cart-btn" tabindex="0">{{tr('Register')}}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    text: String,
    icon: String,
  },
};
</script>
<style scoped>
.contain-product__right-info-layout3 .info .buttons {
  opacity: unset;
  visibility: unset;
}
.box {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  background: rgb(249, 250, 252);
  box-shadow: rgba(3, 157, 85, 0.05) 0px 23px 40px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mui-style-lnem2n {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 600;
  font-family: Rubik, "sans-serif";
  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 64px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  text-transform: none;
  padding: 9px 16px;
  background-color: rgb(3, 157, 85);
  color: rgb(255, 255, 255);
}
.mui-style-1ubwuj6 {
  margin: 0px;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: Rubik, "sans-serif";
  font-weight: 700;
}
</style>
