import Client from './Clients/AxiosClient';
const productsResource = '/products';
const userslogin = '/auth/login'
const profileResource = '/my/profile'
const users = '/users'
const user_detail = '/user_detail'

export default {
  user_detail (data) {
    return Client.post(`${user_detail }`, data)
  },
  login(data) {
    return Client.post(`${userslogin}`, data)
  },
  getUsers(page,form_filter=false) {
    let url=users+page
   
    if (!form_filter) return Client.get(`${url}`);
    else return Client.post(`${url}`, form_filter);
  },
  getProducts() {
    return Client.get(`${productsResource}`);
  },
  sendMessage(name, phone, text) {
    return Client.post(`${messageResource}`, {
      name,phone,text
    })
  },
  updateUserData(userName) {
    return Client.post(`${profileResource}`, { name: userName })
  },
  getUserData() {
    return Client.get(`${profileResource}`)
  },
  getNotifications() {
    return Client.get('notifications')
  },
}