<template>
  <div class="container">
    <div class="row">
        <div class="col-lg-9"> 
        
      <!-- Main content -->
      <div class="row">
                            <ul class="products-list">
<template v-for="(pr ,index) in products"  :key="pr.id">
<productcard :pr="pr" />

</template>
                            
                            </ul>
                        </div>
      <!-- Sidebar --></div>
      <div class="col-lg-3">
      <aside id="sidebar" class="sidebar    ">
        <div class="biolife-mobile-panels">
          <span class="biolife-current-panel-title">Sidebar</span>
          <a class="biolife-close-btn" href="#" data-object="open-mobile-filter"
            >×</a
          >
        </div>
        <div class="sidebar-contain">
          <div class="widget biolife-filter">
            <h4 class="wgt-title">{{ tr("Categories") }}</h4>
            <div class="wgt-content">
              <ul class="cat-list">
                 <li
                  
                  class="cat-list-item"
                >
                  <a @click.prevent="selectCat(0)" href="#" class="cat-link">
                    {{ tr('all')}}</a>
                </li>
                <li
                  v-for="(cat, index) in store.category_details"
                  :key="index"
                  class="cat-list-item"
                >
                  <a @click.prevent="selectCat(cat.id)" href="#" class="cat-link">{{ cat.name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
    </div></div>
  </div>
</template>

<script>
import productcard from './productcard.vue'
export default {
 components: {
  productcard
},
  props: {
    store: {},
  },
  data() {
    return {
      selected_cat:0,
      loaderOn: true,
      products: [],
      categories: [],
    };
  },
  async created() {
     // this.service_types()

    this.getData();
  },
  methods: {
     selectCat(id){
this.selected_cat=id
this.getData()
    },
    async getData(page = 1) {
      try {
        var u =
      "items/latest?store_id="+  this.$route.params.id
          +"&category_id="+this.selected_cat+"&offset=1&limit=13&type=all";
        var res = await this.client.get(u);

        if (res.status === 200) {
          this.products = res.data.products;
          //   this.categories = res.data.categories;

          this.total_size = res.data.total_size;
          this.limit = res.data.limit;
          this.offset = res.data.offset;
          this.loaderOn = false;
        }
      } catch (error) {
        this.loaderOn = false;
        throw error;
      }
    },
  },
  //
};
</script>
