import show from "../Pages/product/show.vue";
import cart from "../Pages/cart/list.vue";
import chekout from "../Pages/cart/chekout.vue";

const routes = [
    {
        path: "/product/:id",
        name: "product.show",
        component: show,
     
        
      },
      {
        path: "/cart",
        name: "cart",
        component: cart,
        
      },
       
  {
    path: "/chekout",
    name: "chekout",
    component: chekout,
  },
];
export default routes;
