<template >
<div :class="_class  ">
    <label for="name" v-if="name" class="form-label"> {{ tr(name) }}</label>

 <label class="switch">
        <input
         :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
          ref="input"
:checked="checkbox"
        type="checkbox" @click="toggleCheckbox">
        <div class="slider round"></div>
      </label>
      <!-- <p>{{ checkbox }}</p> -->
</div>
</template>
<script>
export default {
    props: ['modelValue','name', 'class','type', 'required', 'disabled'],
 emits: ['update:modelValue'],
    data() {
        return {
           _class:this.class?this.class:'col-md-4 col-sm-6',
    checkbox: (this.modelValue==1)?true:false
        }
    },
methods:{
    toggleCheckbox() {
      this.checkbox = !this.checkbox
      this.$emit('update:modelValue', this.checkbox)
    }
},
    computed: {

    },created(){
        if(this.modelValue==0)
        this.checkbox=false
 
    }
}
</script>
<style>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #71dd37;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>