 

<template v-if="loading">
    <c-input v-model="form.contact_person_name" name="contact_person_name" type="text" />
    <c-input v-model="form.address_type" name="address_type" type="text" />
    <c-input v-model="form.contact_person_number" name="contact_person_number" type="text" />
    <c-input v-model="form.address" name="address" type="text" />
    <!-- <c-input v-model="form.longitude" name="address" type="text" /> -->
    <!-- <c-input v-model="form.latitude" name="address" type="text" /> -->
   
 
    <!-- <c-input v-model="form.brand_id" name="brand_id" type="number" /> -->
</template>

<script>
export default {
    props: {
        image: "",
        form: {},
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {},
    created() {
        this.loading = true;
    },
};
</script>