<template>

  <div :class="_class  ">
    <label for="name" v-if="name" class="form-label"> {{ tr(name) }}</label>

    <!--    <ckeditor v-if="type=='textarea'"-->
    <!--              :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input"-->
    <!--              :editor="editor" :config="editorConfig"></ckeditor>-->


    <textarea v-if="type=='textarea'" class="form-control"
              :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input"
    >
         </textarea>


    <flat-pickr :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input"
                :type="type"

                :required="required" :disabled="disabled" v-else-if="type=='date'"
                dateFormat='Y-m-d'

    />
    <input v-else-if="type=='hdate'"
           class="form-control  hijri-date-input    "


           :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input"
           type="date"

           :required="required" :disabled="disabled"
    >


    <input v-else class="form-control"
           :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input"

           :type="type" :required="required" :disabled="disabled"

    />

<span
v-if="error"
style="font-size: 22px; display: block; color: red;">{{error}}</span>
  </div>


</template>

<script>
// import ClasicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  props: ['modelValue','name', 'type', 'required', 'disabled'
  ,'class'
  ],

  emits: ['update:modelValue'],

  methods: {
    focus() {
      this.$refs.input.focus()
    }
  },
mounted(){

},
 computed: {
        errors() {
            return []
        },

 errors() {
            return []
        },
        hasErrors() {
            return Object.keys(this.errors).length > 0
        },
        error() {
            // return '6666';
            var n=this.name
            return this.errors[n]
        },hasErrors() {
            return Object.keys(this.errors).length > 0
        },
    },
  data() {
    return {
      // editor: ClasicEditor,
      fullPage: true,
      _class:this.class?this.class:'col-md-4 col-sm-6',
      editorConfig: {},

    };
  }
}
</script>
