import Client from "./Clients/AxiosClient";
//  ''
const approved_providers = "/providers/approved_providers";
const online_providers = "/providers/online_providers";
const pending_for_approval = "/providers/pending_for_approval";
const provider_vehicle_list = "/settings/provider_vehicle_list";
export default {
  provider_vehicle_list() {
    return Client.get(`${provider_vehicle_list}`);
  },

  approved_providers(page, form_filter = false) {
    let url = approved_providers + page;
    if (!form_filter) return Client.get(`${url}`);
    else return Client.post(`${url}`, form_filter);
  },
  online_providers(page, form_filter = false) {
    let url = online_providers + page;
    if (!form_filter) return Client.get(`${url}`);
    else return Client.post(`${url}`, form_filter);
  },
  pending_for_approval(page, form_filter = false) {
    let url = pending_for_approval + page;
    if (!form_filter) return Client.get(`${url}`);
    else return Client.post(`${url}`, form_filter);
  },
};
