<template>
    <div class="shopping-cart-container container">
        <div class="row">
            
            <div class="col-sm-4">
                <router-link  :to="{ name: 'addresses.create'}" 
                class="btn btn-success">
                                                                
                                                               {{tr('Add Address')}}</router-link>
            </div>
        </div>
                    <div class="row" v-if="!loaderOn">
                        <div class=" col-sm-12 col-xs-12">
                            <h3 class="box-title">{{tr('Saved Addresses')}}</h3>
                            <form class="shopping-cart-form" action="#" method="post">
                                <table class="shop_table cart-form">
                                    <thead>
                                    <tr>
                                        <th class="product-name">{{tr('address')}}</th>
                                        <th class="product-price">{{tr('Address Type')}}</th>
                                        <th class="product-quantity">{{tr('Contact Person')}}</th>
                                        <th class="product-subtotal">{{tr('Contact Person Number')}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item,index) in  list.addresses" :key="index" class="cart_item" >
                                        <td class=" " >
                                         {{item.address}}
                                      
                                         
 
                                        </td>
                                        <td class="product-price" data-title="Price">
                                   {{item.address_type}}
                                      
                                        </td>
                                        <td class="product-quantity" data-title="Quantity">
                                              {{item.contact_person_name}}
                                         </td>
                                        <td class="product-subtotal" data-title="Total">
                                          {{item.contact_person_number}}
                                        </td>
                                    </tr>
                                    
                                    
                                    </tbody>
                                </table>
                            </form>
                        </div>
                         
                    </div>
                </div>
</template>
<script>
export default {
 
  data() {
    return {
      loaderOn:true,
     list:[],
       total_size: 0,
    limit: 0,
    offset: 0,
    };
  },
  async created() {
   document.title = this.tr('Saved Addresses');
 
 
    this.getData();
  },
  methods: { 
    // 
    async getData(page = 1) {
      try {
          var u='customer/address/list' 
        var res = await this.client.get(u);

        if (res.status === 200) {
         this.list=res.data
 
          this.loaderOn = false;
        }
      } catch (error) {
        this.loaderOn = false;
        throw error;
      }
    },

     
  }
}
</script>