import { createApp, watch } from 'vue'
import './style.css'
import './custom.css'
//  hhhrrrfffdffffrrrrgttt
import App from './layouts/app.vue'
import {translations} from './Mixins/translations';
import {trans} from './Mixins/trans';
import {lang} from './Mixins/lang';
import {apiCart} from './Mixins/apiCart';
import chekPerms from './Mixins/chekPerms';
import AuditMixin from './Mixins/AuditMixin';
import TransactionsMixin from './Mixins/TransactionsMixin';
// import the styling for the toast
// import 'mosha-vue-toastify/dist/style.css'
import VueCookies from 'vue3-cookies'
import AuthenticatedLayout from "./SnLayout/AuthenticatedLayout.vue";
import GestLayout from "./SnLayout/gest.vue";
// import "./registerServiceWorker";

// import VueScrollTo from 'vue-scrollto';
// import './registerServiceWorker';
 
 
// when user clicked the "refresh" button
 
import { createPinia } from 'pinia';

// import Toast from "vue-toastification";
// import "vue-toastification/dist/index.css";

import router from './router'

// import bottomNavigationVue from "bottom-navigation-vue";
// import "bottom-navigation-vue/dist/style.css";

// import Vue3Geolocation from 'vue3-geolocation';
import mitt from 'mitt';
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/service-worker.js')
//       .then(registration => {
//         console.log('Service Worker registered:', registration);
//       })
//       .catch(error => {
//         console.log('Service Worker registration failed:', error);
//       });
//   });
// }
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register(
//      '/service-worker.js'  
//     // import.meta.env.MODE === 'production' ? '/service-worker.js' : '/dev-sw.js?dev-sw'
//   )
// }
// self.addEventListener('fetch', event => {
//   event.respondWith(
//     caches.match(event.request)
//       .then(response => {
//         if (response) {
//           return response;
//         }
//         return fetch(event.request);
//       })
//   );
// });
const app = createApp(App)
const pinia = createPinia()
const emitter = mitt();
 
// import wb from "./registerServiceWorker";
// import './registerServiceWorker'
// Vue.prototype.$workbox = wb;
// import  VueGoogleMaps from '@fawmi/vue-google-maps'
// app.use(VueGoogleMaps, {
//   load: {
//       key: 'AIzaSyDVM279OIxo9NEqLhWbruP69o5HTUnxu54',
//   },
// })
app.use(router);
// import Vuelidate from 'vuelidate'
// app.use(Vuelidate)
import CInput from "./Components/CInput.vue";
import CBtnSave from "./Components/CBtnSave.vue";
import loader from "./Components/loader.vue";

import paginationapi from './Components/paginateApi.vue';
import CTable from "./Components/Table/table.vue";
import breadcrumb from "./Components/breadcrumb.vue";
import c_form from "./Components/form.vue";
import buttons_dropdown from "./Components/buttons_dropdown.vue";
import DropdownMenu from "./Components/DropdownMenu.vue";
import Cswitch from "./Components/Cswitch.vue";
import FilterTable from "./Components/FilterTable.vue";

import CLink from "./Components/CLink.vue";
import ButtonAdd from "./Components/ButtonAdd.vue";
import Client from './repositories/Clients/AxiosClient';
import Repo from "./repositories/RepositoryFactory";
import { useUserStore } from "./stores/user.js";
import { useCartStore } from "./stores/cart.js";
import { useSettingsStore } from "./stores/settings.js";
import { useSiteStore } from "./stores/site.js";
// import ImageUploadVue from 'image-upload-vue'
if (localStorage.getItem("state")) {
  pinia.state.value = JSON.parse(localStorage.getItem("state"))
}
watch(pinia.state,
  (state) => {
    localStorage.setItem("state", JSON.stringify(state))
  },
  { deep: true }
);
import VueLazyload from 'vue-lazyload'

app.use(VueLazyload)

// or with options
const loadimage =  './theme/404.png'
const errorimage =  './theme/404.png'

app.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1
})
app.use(pinia)
 
app.use(VueCookies, {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None"
})
// app.use(Toast)
// app.use(bottomNavigationVue);

// app.use(Vue3Geolocation);
// app.use(ImageUploadVue)
// app.use(VueScrollTo, {
//   container: "body",
//   duration: 1000,
//   easing: "ease",
//   offset: -60,
// });


app.component("c-input", CInput)
app.component("c-btnsave", CBtnSave)
app.component("loader", loader)

app.component("c-table", CTable)
app.component("breadcrumb", breadcrumb)
app.component("c-form", c_form)
app.component("AuthenticatedLayout", AuthenticatedLayout)
app.component("gest-layout", GestLayout)
app.component("pagination-api", paginationapi)
app.component("c-link", CLink)
app.component("buttons-dropdown",buttons_dropdown)
app.component("dropdown-menu",DropdownMenu)

app.component("btn-add", ButtonAdd)
app.component("c-switch", Cswitch)
app.component("filter-table", FilterTable)
import minusPlus from './Components/minusPlus.vue'
app.mixin(translations)
app.mixin(trans)
app.mixin(lang)
app.mixin(apiCart)

  app.use(mitt)
app.mixin(chekPerms)
app.mixin(AuditMixin)
app.mixin(TransactionsMixin)
app.component("minusPlus",minusPlus)
app.config.globalProperties.$carts = [];
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.client = Client;
app.config.globalProperties.repo= Repo;

app.config.globalProperties.user_store= useUserStore();
app.config.globalProperties.cart_store= useCartStore();
app.config.globalProperties.settings_store= useSettingsStore();
app.config.globalProperties.base_domain= 'https://dawaa.souglink.com/';
// app.config.globalProperties.base_domain= 'http://127.0.0.1:8000/';
app.config.globalProperties.site_store= useSiteStore();
app.mount('#app');
