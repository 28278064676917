
 <template>

<div class="minusplusnumber d-flex">
 <div class="mpbtn minus" v-on:click="mpminus()">
     -
 </div>

 <div id="field_container">
     <input type="number"


 v-model="value"



     disabled />
 </div>

 <div class="mpbtn plus" v-on:click="mpplus()">
     +
 </div>
</div>



</template>
<script>
export default {
 props: ['modelValue','value','min','max']



 ,
   emits: ['update:modelValue'],
 data () {
     return {
value:0
     }

 },
 methods: {
     mpplus: function() {
         if(this.max == undefined || (this.value < this.max)) {
             this.value= this.value + 1;
            //  this.$emit('input', this.value);
              this.$emit('update:modelValue', this.value);
         }
     },
     mpminus: function() {
         if((this.value) > this.min) {
             this.value = this.value - 1;
            //  this.$emit('input', this.value);
             this.$emit('update:modelValue', this.value);

         }
     }
 },
 watch: {
     value: {
         handler: function (newVal, oldVal) {
            //   this.modelValue = newVal;
         }
     }
 },
 created: function() {
    this.value=  this.modelValue ;
 }
}
</script>
<style>
.minusplusnumber {
    border:1px solid silver;
    border-radius:5px;
    background-color: #FFF;
    margin:0 5px 0 5px;
    display:inline-block;
    user-select: none;
}
.minusplusnumber div {
    display:inline-block;
}
.minusplusnumber #field_container input {
    width:50px;
    text-align:center;
    font-size:15px;
    padding:3px;
    border:none;
}
.minusplusnumber .mpbtn {
    padding:3px 10px 3px 10px;
    cursor:pointer;
    border-radius:5px;
}
.minusplusnumber .mpbtn:hover {
    /* background-color:#DDD; */
}
.minusplusnumber .mpbtn:active {
    /* background-color:#c5c5c5; */
}
.mpbtn.minus{
        background: red;
    color: white;
}
.mpbtn.plus{
        background: green;
    color: white;
}
</style>