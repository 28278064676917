import Client from './Clients/AxiosClient';
const Resource = '/said_about_us';

export default {
  getSaiedAboutUs() {
    return Client.get(`${Resource}`)
  },
  getprivacyPolicy() {
    return Client.get('privacy_policy')
  },

}