<template>


 
 <top :site_data="site_data" />
 
   
<earning/>
 
 





   
</template>

<script  >
//  import box from "./box.vue";
import top from './home/top.vue'
import earning from './home/earning.vue'

import { useSiteStore } from "../stores/site";
export default {
components: { top,earning },
  data() {
    return {
            
      site_data:{},
        actions:[],
    dashboard_data:[],
         loaderOn:true,
    };
  },
  methods: {
  //  
   

  },
     async created() {
      
    await this.site_store.get_site_data();
  this.site_data = this.site_store.site_data;
        document.title =this.tr('home')
        
        this.loaderOn = false;
        // this.getData()
   
    },
  props: {

data:[],
      
    list: Object,
  },
};
</script>
<style>
</style>
