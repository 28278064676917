export const translations = {

    methods: {

        tr(key, replacements = {}) {

        let translation = window._translations[key] || key;
        let translationen = window._translationsen[key] || key;

        Object.keys(replacements).forEach(r => {
          translation = translation.replace(`:${r}`, replacements[r]);
        });

        return translation;
      },

      switchLocalePath(lang) {
        // console.log(store.state.Language);
        //console.log("change lang", lang);
        var langs = ['ar', 'en'];
        Cookies.set("active_lang", lang, {
            expires: 30,
        });
        store.dispatch("changeLanguage", lang);


        this.$i18n.locale = lang;
        $('body').removeClass(langs.join(' '))
        $("html").attr('lang', lang);
        $('body').addClass(lang)
        if (lang == 'ar') {
            $("#bootstrap-rtl").removeAttr("disabled", "disabled");
            $("#main-rtl").removeAttr("disabled");
            $("#main-ltr").attr("disabled", "disabled");
            $("html").attr('dir', 'rtl');
        } else {
            $("#main-ltr").removeAttr("disabled");
            $("#bootstrap-rtl").attr("disabled", "disabled");
            $("#main-rtl").attr("disabled", "disabled");


            $("html").attr('dir', 'ltr');
        }
    },
    },
  };
