<template>


<li class="menu-item  " :class="open"  >
              <a href="javascript:void(0);"
                @click.prevent="changeClass()"
              class="menu-link menu-toggle">
          <i :class="icon?icon:'bx bx-list-ul'" ></i>
                <div data-i18n="Dashboards">{{tr(title)}}</div>
                <!-- <div class="badge bg-danger rounded-pill ms-auto">5</div> -->
              </a>
              <ul class="menu-sub">
                <slot/>


              </ul>
            </li>

</template>


<script>

export default {
    props:{url:"",title:"",icon:"bx bx-list-ul"},

data() {
    return {
          open:""
    }
},

methods:{
     changeClass () {
if(this.open=="")
this.open="active open"
else
this.open=""
    }
}}
</script>
