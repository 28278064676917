import { defineStore } from 'pinia'

export const useCartStore = defineStore('cart', {
    state: () => {
        return {
          store_id:0,
            _cart:{},  cart_items:[],
   cart : { 
  
    //   delivery: 0,
    //   discount: 0,
    //   guest_id: null,
    //   subtotal: 0,
    //   tax: 22.34,
    //   total: 0,
    //   user_id: null
    
    }
    }
  },
  getters: {
    get_cart_items(state){
        
        return state.cart.cart_items
    },
    getCart: (state) => state.cart,
    getCartItems: (state) => state.cart.cart_items,
  },
  actions: {
    getCartId(product_id){

      if(!this.cart_items)
      return false;
      let _product =this.cart_items.filter(function (product){
  
        return product.item_id==product_id
      } )

let r=_product[0]?_product[0].id :0
return  r
    },
    chekProductInCart(product_id){

      if(!this.cart_items)
      return false;
      let _product =this.cart_items.filter(function (product){
console.log(product.item_id,'product.item_id')
        return product.item_id==product_id
      } )

let r=_product[0]?true :false
return  r
    },
    getProductQuantity(product_id){
      if(!this.cart_items)
      return 1;
      let _product =this.cart_items.filter(function (product){
        return product.item_id==product_id
      } )
var q=_product[0]?.quantity??1
       
return q
    },
    setCart(data) {
      this.cart  = data
    },
    setStoreId(id) {
      this.store_id  = id
    },

    
    setCartItems(data) {
         
      this.cart_items = data
    },
    isEmpty() {
      if (this.cart.cart_items) {
        if (this.cart.cart_items.length == 0) return true

        return false
      }

      return true
    },
  },
  persist: {
    enabled: true,
  },
})
