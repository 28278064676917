<template>
    
 

<div class="style-0">
    <div class="style-1">
        <div class="style-2">
            <div class="style-3">
                <div class="style-4">
                    <div class="row">
                        <div data-index="0" class="col-xs-6 col-sm-4   style-6" tabindex="-1" aria-hidden="false">
                            <div class="style-7">
                                <div class="style-8" tabindex="-1">
                                    <div class="style-9" objectfit="contain" border_radius="5px"><img src="https://dawaa.souglin.com/upload/app/public/promotional_banner/2023-06-12-6486f7ebe6ad7.png" alt="banners" loading="lazy" class="style-10" /></div>
                                </div>
                            </div>
                        </div>
                        <div data-index="1" class="col-xs-6 col-sm-4  style-11" tabindex="-1" aria-hidden="false">
                            <div class="style-12">
                                <div class=" style-13" tabindex="-1">
                                    <div class="style-14" objectfit="contain" border_radius="5px"><img src="https://dawaa.souglin.com/upload/app/public/promotional_banner/2023-06-12-6486f7f6e2f47.png" alt="banners" loading="lazy" class="style-15" /></div>
                                </div>
                            </div>
                        </div>
                        <div data-index="2" class=" col-xs-6 col-sm-4    style-16" tabindex="-1" aria-hidden="false">
                            <div class="style-17">
                                <div class="style-18" tabindex="-1">
                                    <div class="style-19" objectfit="contain" border_radius="5px"><img src="https://dawaa.souglin.com/upload/app/public/promotional_banner/2023-06-12-6486f80048105.png" alt="banners" loading="lazy" class="style-20" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 
</template>

<script>
export default {
      props: {
site_data:{}
  },
}
</script>

<style scoped>
    .style-0 {
        max-width: 1300px;
        padding-right: 24px;
        padding-left: 24px;
        box-sizing: border-box;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        display: block;
    }

    .style-1 {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .style-2 {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
    }

    .style-3 {
        position: relative;
        display: block;
        box-sizing: border-box;
        user-select: none;
        touch-action: pan-y;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .style-4 {
        transform: matrix(1, 0, 0, 1, 0, 0);
        overflow: hidden;
        margin: 0px;
        padding: 0px;
        position: relative;
        display: block;
        box-sizing: border-box;
    }

    .style-5 {
        width: 1254px;
        opacity: 1;
        transform: translate3d(0px, 0px, 0px);
        opacity: 1;
        transform: matrix(1, 0, 0, 1, 0, 0);
        position: relative;
        top: 0px;
        left: 0px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
        float: left;
        gap: 5px;
    }

    .style-6 {
        outline: none;
         
        padding: 11px;
        display: block;
        float: right;
        height: 204.85px;
        min-height: 1px;
        box-sizing: border-box;
    }

    .style-7 {
        box-sizing: border-box;
    }

    .style-8 {
        width: 100%;
        display: inline-block;
        display: inline-block;
        overflow: hidden;
        box-sizing: border-box;
        border: 0.8px solid rgba(3, 157, 85, 0.15);
        position: relative;
        height: 175px;
        border-radius: 5px;
    }

    .style-9 {
        overflow: hidden;
        box-sizing: border-box;
        display: inline-flex;
        background: rgba(0, 0, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box;
        width: 100%;
        height: 173.4px;
        position: relative;
        border-radius: 5px;
    }

    .style-10 {
        display: block;
        transition: all 0.5s ease 0s;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 173.4px;
        object-fit: contain;
        border-radius: 5px;
    }

    .style-11 {
        outline: none;
        
        padding: 11px;
        display: block;
        float: right;
        height: 204.85px;
        min-height: 1px;
        box-sizing: border-box;
    }

    .style-12 {
        box-sizing: border-box;
    }

    .style-13 {
        width: 100%;
        display: inline-block;
        display: inline-block;
        overflow: hidden;
        box-sizing: border-box;
        border: 0.8px solid rgba(3, 157, 85, 0.15);
        position: relative;
        height: 175px;
        border-radius: 5px;
    }

    .style-14 {
        overflow: hidden;
        box-sizing: border-box;
        display: inline-flex;
        background: rgba(0, 0, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box;
        width: 100%;
        height: 173.4px;
        position: relative;
        border-radius: 5px;
    }

    .style-15 {
        display: block;
        transition: all 0.5s ease 0s;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 173.4px;
        object-fit: contain;
        border-radius: 5px;
    }

    .style-16 {
        outline: none;
       
        padding: 11px;
        display: block;
        float: right;
        height: 204.85px;
        min-height: 1px;
        box-sizing: border-box;
    }

    .style-17 {
        box-sizing: border-box;
    }

    .style-18 {
        width: 100%;
        display: inline-block;
        display: inline-block;
        overflow: hidden;
        box-sizing: border-box;
        border: 0.8px solid rgba(3, 157, 85, 0.15);
        position: relative;
        height: 175px;
        border-radius: 5px;
    }

    .style-19 {
        overflow: hidden;
        box-sizing: border-box;
        display: inline-flex;
        background: rgba(0, 0, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box;
        width: 100%;
        height: 173.4px;
        position: relative;
        border-radius: 5px;
    }

    .style-20 {
        display: block;
        transition: all 0.5s ease 0s;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 173.4px;
        object-fit: contain;
        border-radius: 5px;
    }
</style>