<template>
  <div  class="welcome-us-block text-center container" v-if="!loaderOn">

 <h4 class="title"> {{tr('Need Any help?')}} </h4>
 <br>
 <p class="subtitle h3"> {{tr('Communicate with our support team to get proper guidance to your quaternaries.')}} </p>
 <div class="row">
<div class="col-xs-6 col-md-4">
   <helpbox icon="/theme/img/person.png" :title="tr('CALL US')"
   :text="business_settings.phone"  />
   </div>
<div class="col-xs-6 col-md-4">
   <helpbox icon="/theme/img/mail.png" :title="tr('EMAIL US')"
    :text="business_settings.email_address" />
   </div>
   <div class="col-xs-6 col-md-4">
   <helpbox icon="/theme/img/home.png" :title="tr('VISIT US')" 
   :text="business_settings.address" />
   </div>
 </div>
 
 
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import helpbox from './helpbox.vue'


export default {components: {
  StarRating,helpbox
},

  data() {
    return {
      loaderOn:true,
       site_data:{},
       business_settings:{}
    };
  },
  async created() {

      await this.site_store.get_business_settings();
  this.business_settings = this.site_store.business_settings;
            
       await this.site_store.get_site_data();
  this.site_data = this.site_store.site_data;
               
           document.title = this.tr("Need Any help?");
         this.loaderOn = false;
  },
  methods: {
   
  },
   
};
</script>
