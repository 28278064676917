<template>
     <tr class="cart_item" >
                                      
                                      
                                        <td class="product-thumbnail" data-title="Product Name">
                                            <a class="prd-thumb" href="#">
                                                <figure><img width="113" height="113"
                                                
                                               v-lazy="base_domain+'upload/product/'+item.item.image" 
                                                alt="shipping cart"></figure>
                                            </a>
                                            <router-link      :to="{ name: 'product.show', params: { id: item.item.id } }"  class="prd-name"  >{{item.item.name}}</router-link>
                                            <div class="action">
                                                <a href="#" class="edit">
                                                   <i class="fa-regular fa-pen-to-square"></i>
                                                    
                                                    </a>
                                                <a href="#" @click.prevent="deleteItem(item.id)" class="remove   ">
                                                    <i class="fa-solid fa-trash"></i>
                                                    
                                                    </a>
                                            </div>
                                        </td>
                                        <td class="product-price" data-title="Price">
                                            <div class="price price-contain">
                                                <ins><span class="price-amount"><span class="currencySymbol">£</span>{{item.item.price}}</span></ins>
        
                                            </div>
                                        </td>
                                        <td class="product-quantity" data-title="Quantity">
                                            <div class="quantity-box type1">
                                                <div class="qty-input">
                                                    <minus-plus v-model="quantity" :min="1" :max="100"> </minus-plus>
                                                   </div>
                                                   <div class="p-1 actions">
        <button type="button"
          @click.prevent="updateToCart"
          data-bs-title="The last tip!"
          class="btn b-n btn-primary btn-sm"
        >
          <i class="fa-solid fa-rotate fa-1x"></i>
          <!-- {{ __('update') }} -->
        </button>
        <button type="button"
          @click.prevent="deleteItem(item.id)"
          data-bs-title="The last tip!"
          class="btn b-n btn-danger"
        >
          <i class="fa-regular fa-trash-can fa-1x"></i>
        </button>
      </div>
                                            </div>
                                        </td>
                                        <td class="product-subtotal" data-title="Total">
                                            <div class="price price-contain">
                                                <ins><span class="price-amount"><span class="currencySymbol">£</span>85.00</span></ins>
                                                <del><span class="price-amount"><span class="currencySymbol">£</span>95.00</span></del>
                                            </div>
                                        </td>
                                    </tr>
</template>
<script>
 

export default {
    props: {
item:{}
  },
    data() {
    return {
quantity:this.item.quantity,
product:this.item.item
    }},
     async created() {
 
     },
    methods:{

    }
    }

</script>