import axios from "axios";
import { useUserStore } from "../../stores/user";

// const baseDomain = "https://jsonplaceholder.typicode.com";
// const baseDomain = "http://127.0.0.1:8000/api/v1";
const baseDomain = "https://dawaa.souglink.com/api/v1";
// https://dev.with.ws/api/v2/login
// const baseURL = `${baseDomain}/api`; // Incase of /api/v1;
const baseURL = `${baseDomain}/`; // Incase of /api/v1;
 
const apiClient = axios.create({
  baseURL,
  headers: {
   'Accept' :' */*',

   'Access-Control-Allow-Origin':'*',
  // 'Connection' :' Keep-Alive',
  // 'Accept-Encoding' : 'gzip',
  // 'X-Site24x7-Id' : '1420e1c1b70c',
  // 'Content-Length' : 3,
  // 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
  'Moduleid':1,
    "zoneid" :  "[1]",
    
    "language" :undefined,
    "currentLocation" : undefined,
    "software_id" : 33571750,

}})

apiClient.interceptors.request.use((req) => {
  const store = useUserStore()
  req.headers.Authorization = `Bearer ${store.token}`
  // req.headers.Authorization = `Bearer ${store.token}`
  return req
})

apiClient.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    const { response } = { ...error }
    var t=response.data
    if(t==='Invalid Token')
 {  
  const store = useUserStore() 
  store.logout()
  // this.$router.push("/").then(() => {
  //   window.location.reload();
  // });
  // console.log({t})
}
    return Promise.reject(response)
  }
)

export default apiClient

