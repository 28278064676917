<template>


   <li class="menu-item">
              <router-link
               :to="url"
                
                :class="$route.path === url?'active': '' "
                class="menu-link">
        <i :class="i_class"></i>
                <span  class="link-title" data-i18n="Email">{{ tr(title) }}</span>
                <!-- <div class="badge bg-label-primary fs-tiny rounded-pill ms-auto">Pro</div> -->
              </router-link>
            </li>

</template>


<script>

export default {
  props: {url: "", title: "",i_class:""}
}
</script>
