<template>
     <div class="row ">
    <div class="col-md-12">
      <div class="panel panel-default form-filter">
        <div class="panel-body">
          <form @submit.prevent="filterData"
        action="#"
            class="form-horizontal "
            role="form"
            method="post"
       
          >
            <div class="form-group">
              <input type="hidden" name="page" id="page" value="1" />
              <input type="hidden" name="user_type" value="users" />
              <div class="col-md-4">
                <div style="text-align: center">
                  <label>فرز</label>
                </div>
                <div class="col-md-6 mb-3">
                   
                  <select v-model="form_filter.sort_item"
                    class="form-select"
                    id="exampleFormControlSelect1"
                    aria-label="Default select example"
                  >
                    
                    <option  v-for="(s,index) in sort_item_list" 
                    :key="'sort_'+index"
                    :value="s"  >
                     {{tr(s)}}
                    </option>
                   
                  </select>
                </div>
                <div class="col-md-6 mb-3">
                  
                  <select v-model="form_filter.sort_value"
                    class="form-select"
                    id="exampleFormControlSelect1"
                    aria-label="Default select example"
                  >
                    <option value="1">تصاعدي</option>
                    <option value="-1" selected>تنازلي</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div style="text-align: center">
                  <label>بحث</label>
                </div>
                <div class="col-md-6">
                   
                  <select v-model="form_filter.search_item"
                    class="form-select"
                    id="exampleFormControlSelect1"
                    aria-label="Default select example"
                  >

 <option  v-for="(s,index) in search_item_list" 
                    :key="'search_'+index"
                    :value="s"  >
                     {{tr(s)}}
                    </option>

                                      
                  </select>
                </div>
                <div class="col-md-6">
                  <div>
                   
                    <input v-model="form_filter.search_value"
                      type="text"
                      class="form-control"
                      id="defaultFormControlInput"
                      placeholder=" "
                      aria-describedby="defaultFormControlHelp"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div style="text-align: center">
                  <label>مرشح التاريخ</label>
                </div>
                <div class="col-md-12">
                  <div class="btn-group">
                    <div class="input-group form-floating">
                      <input aria-describedby="floatitngInputHelp"
                        class="form-control"
                        placeholder="تاريخ البدء"
                        name="start_date"
                        v-model="form_filter.start_date"
                        type="date"
                        value="2021-06-18"
                        id="html5-date-input"
                      />
                      <label for="floatingInput">{{tr("starting date")}}</label>
                      <span class="input-group-addon add-on">-</span>
<div class="form-floating">
                      <input aria-describedby="floatitngInputHelp"
                        class="form-control"
                        placeholder="تاريخ الانتهاء"
                        name="end_date"
                        type="date"
                        v-model="form_filter.end_date"
                        value="2021-06-18"
                        id="html5-date-input"
                      />
                      <label for="floatingInput">{{tr("Expiry date")}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group col-md-4">
              <div class="col-md-6">
                <button
                  type="submit"
                  id="apply_filter"
                  class="btn btn-primary"
                  style="height: 35px; width: 100%"
                >
                  <span>تطبيق</span>
                </button>
              </div>

              <div class="col-md-6">
                <!-- <button class="btn btn-danger pull-right" type="button" style="height: 35px; width: 100%;" onClick="$('#customers2').tableExport({type: 'excel', escape: 'false', ignoreColumn: [1, 7]});"><i class="fa fa-bars"></i> تصدير</button> -->

                <button
                  class="btn btn-danger pull-right"
                  type="button"
                  style="height: 35px; width: 100%"
                  onclick="export_excel()"
                >
                  <i class="fa fa-download"></i>تصدير
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 

export default  {
     methods: {
    filterData() {
      this.$emit("filterData");
    },
  },
    props: {form_filter:[],sort_item_list:[],search_item_list:[]} 
}
</script>
