<template>
<div class="container">
  <div class="row" v-if="!loaderOn">
    <div class="col-md-4" v-for="(store,index) in stores" :key="index">
      <div
        class="contain-product right-info-layout contain-product__right-info-layout"
      >
        <div class="product-thumb">
          <a href="#" class="link-to-product" tabindex="0">
            <img
              v-lazy="base_domain+'upload/store/'+store.logo"
              alt="dd"
              width="270"
              height="270"
              class="product-thumnail"
            />
          </a>
        </div>
        <div class="info">
        
          <h4 class="product-title">
            <router-link :to="{ name: 'stores.show', params: { id: store.id } }" class="pr-name" tabindex="0">{{store.name}}</router-link>
          </h4>
           <b class="categories">{{store.address}}</b>
          <div class="price">
            <ins
              ><span class="price-amount"
                > {{store.delivery_time}}</span
              ></ins
            >
            
          </div>
          <div class="rating">
           
          <star-rating read-only="true"	 :rating="store.avg_rating"
           star-size="30"	
          />


             
            <!-- <span class="review-count">(05 Reviews)</span> -->
          </div>
        </div>
      </div>
    </div>
  </div></div>
</template>

<script>
import StarRating from 'vue-star-rating'


export default {components: {
  StarRating
},
  data() {
    return {
      loaderOn:true,
      stores:[],
       total_size: 0,
    limit: 0,
    offset: 0,
    };
  },
  async created() {
    document.title = this.tr("stores");
    // this.service_types()

    this.getData();
  },
  methods: {
    async getData(page = 1) {
      try {
        var res = await this.client.get("/stores/popular?type=all");

        if (res.status === 200) {
          this.stores = res.data.stores;
 this.total_size=res.data.total_size
    this.limit= res.data.limit
    this.offset= res.data.offset
          this.loaderOn = false;
        }
      } catch (error) {
        this.loaderOn = false;
        throw error;
      }
    },
  },
};
</script>
