<template>
<div  class="welcome-us-block text-center" v-if="!loaderOn">
 
 <h4 class="title"> {{tr('Let’s Start Earning with')}} </h4>
   <p class="subtitle h3">{{ tr('Join our online marketplace revolution and boost your income.')}}</p>
    <div class="banner-promotion xs-margin-top-0 sm-margin-top-60px xs-margin-top-100">
                <div class="biolife-banner promotion6 biolife-banner__promotion6">
                    <div class="banner-contain">
                        <div class="media">
                            
                            <div class="img-moving position-2">
                                <img src="/theme/assets/images/home-01/bn-promotion-6-child-02.png" width="745" height="682" alt="img msv">
                           
                            </div>
                        </div>
                        
                        <div class="text-content">
<div class="row">
<div class="col-xs-12 col-sm-6"> 
    <earningbox :title=" tr('Become A Seller')"
    :text="tr(
            'Register as seller and open shop in souglink to start your business'
          )"
          icon="/theme/img/seller.2044003e.svg"
     /></div>
     <div class="col-xs-12 col-sm-6"> 
    <earningbox :title=" tr('Become A Deliveryman')"
    :text="tr(
            'Register as delivery man and earn money'
          )"
          icon="/theme/img/delivery-man.c145d2d3.svg"
     /></div>
                        </div>

                        </div>

                        
                    </div>
                </div>
            </div>
            </div>
</template>
<script>
import earningbox from "./earningbox.vue";
    
export default {
    components: {
  earningbox
},
}
</script>
<style scoped>
.biolife-banner__promotion6 .banner-contain .media{
   height: 400px;   
}
.biolife-banner__promotion6 .banner-contain .text-content{
    max-width: unset;
   
    left: unset;display: block;
    background: unset;
}
</style>