<template>
  <!-- Menu -->

  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
    <div class="app-brand demo">
      <a href="index.html" class="app-brand-link">
        <span class="app-brand-logo demo">
          <svg
            width="25"
            viewBox="0 0 25 42"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <path
                d="M13.7918663,0.358365126 L3.39788168,7.44174259 C0.566865006,9.69408886 -0.379795268,12.4788597 0.557900856,15.7960551 C0.68998853,16.2305145 1.09562888,17.7872135 3.12357076,19.2293357 C3.8146334,19.7207684 5.32369333,20.3834223 7.65075054,21.2172976 L7.59773219,21.2525164 L2.63468769,24.5493413 C0.445452254,26.3002124 0.0884951797,28.5083815 1.56381646,31.1738486 C2.83770406,32.8170431 5.20850219,33.2640127 7.09180128,32.5391577 C8.347334,32.0559211 11.4559176,30.0011079 16.4175519,26.3747182 C18.0338572,24.4997857 18.6973423,22.4544883 18.4080071,20.2388261 C17.963753,17.5346866 16.1776345,15.5799961 13.0496516,14.3747546 L10.9194936,13.4715819 L18.6192054,7.984237 L13.7918663,0.358365126 Z"
                id="path-1"
              ></path>
              <path
                d="M5.47320593,6.00457225 C4.05321814,8.216144 4.36334763,10.0722806 6.40359441,11.5729822 C8.61520715,12.571656 10.0999176,13.2171421 10.8577257,13.5094407 L15.5088241,14.433041 L18.6192054,7.984237 C15.5364148,3.11535317 13.9273018,0.573395879 13.7918663,0.358365126 C13.5790555,0.511491653 10.8061687,2.3935607 5.47320593,6.00457225 Z"
                id="path-3"
              ></path>
              <path
                d="M7.50063644,21.2294429 L12.3234468,23.3159332 C14.1688022,24.7579751 14.397098,26.4880487 13.008334,28.506154 C11.6195701,30.5242593 10.3099883,31.790241 9.07958868,32.3040991 C5.78142938,33.4346997 4.13234973,34 4.13234973,34 C4.13234973,34 2.75489982,33.0538207 2.37032616e-14,31.1614621 C-0.55822714,27.8186216 -0.55822714,26.0572515 -4.05231404e-15,25.8773518 C0.83734071,25.6075023 2.77988457,22.8248993 3.3049379,22.52991 C3.65497346,22.3332504 5.05353963,21.8997614 7.50063644,21.2294429 Z"
                id="path-4"
              ></path>
              <path
                d="M20.6,7.13333333 L25.6,13.8 C26.2627417,14.6836556 26.0836556,15.9372583 25.2,16.6 C24.8538077,16.8596443 24.4327404,17 24,17 L14,17 C12.8954305,17 12,16.1045695 12,15 C12,14.5672596 12.1403557,14.1461923 12.4,13.8 L17.4,7.13333333 C18.0627417,6.24967773 19.3163444,6.07059163 20.2,6.73333333 C20.3516113,6.84704183 20.4862915,6.981722 20.6,7.13333333 Z"
                id="path-5"
              ></path>
            </defs>
            <g
              id="g-app-brand"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="Brand-Logo" transform="translate(-27.000000, -15.000000)">
                <g id="Icon" transform="translate(27.000000, 15.000000)">
                  <g id="Mask" transform="translate(0.000000, 8.000000)">
                    <mask id="mask-2" fill="white">
                      <use xlinkto="#path-1"></use>
                    </mask>
                    <use fill="#696cff" xlinkto="#path-1"></use>
                    <g id="Path-3" mask="url(#mask-2)">
                      <use fill="#696cff" xlinkto="#path-3"></use>
                      <use
                        fill-opacity="0.2"
                        fill="#FFFFFF"
                        xlinkto="#path-3"
                      ></use>
                    </g>
                    <g id="Path-4" mask="url(#mask-2)">
                      <use fill="#696cff" xlinkto="#path-4"></use>
                      <use
                        fill-opacity="0.2"
                        fill="#FFFFFF"
                        xlinkto="#path-4"
                      ></use>
                    </g>
                  </g>
                  <g
                    id="Triangle"
                    transform="translate(19.000000, 11.000000) rotate(-300.000000) translate(-19.000000, -11.000000) "
                  >
                    <use fill="#696cff" xlinkto="#path-5"></use>
                    <use
                      fill-opacity="0.2"
                      fill="#FFFFFF"
                      xlinkto="#path-5"
                    ></use>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
        <span class="app-brand-text demo menu-text fw-bold ms-2">{{
          tr("site_title")
        }}</span>
      </a>

      <a
        @click.prevent="swithSideBar()"
        href="javascript:void(0);"
        class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
      >
        <i class="bx bx-chevron-left bx-sm align-middle"></i>
      </a>
    </div>

    <div class="menu-inner-shadow"></div>

    <ul class="menu-inner py-1">
      <!-- Dashboards -->
      <!-- nested -->

      
      <nested title="dashboard" icon="fa-solid fa-chart-column">
        <item i_class="fa-solid fa-chart-column  " title="dashboard" url="/" />
        <item i_class= "fa-solid fa-paper-plane " title="running_requests" url="/requests/running_requests" />
        <item
          i_class=" fa-regular fa-calendar-days "
          title="requests"
          url="/requests/requests"
        />
        <item
          i_class=" fa-regular fa-calendar "
          title="schedules"
          url="/requests/schedules"
        />
        <item i_class=" fa-solid fa-comment " title="Reviews" url="/reviews" />
        <item
          i_class=" fa-solid fa-comment-slash "
          title="Cancellation Reason"
          url="/cancellation_reasons"
        />
      </nested>
      <nested title="Map View" icon="fa-solid fa-location-dot">
        <item
          i_class="fa-solid fa-map-location-dot"
          title="View drivers map"
          url="/mapview"
        />

        <item
          i_class=" fa-solid fa-map-location-dot "
          title="Driver Tracking"
          url="/provider_track"
        />
        <item
          i_class="fa-solid fa-map-location-dot"
          title="All Cities"
          url="/all_city"
        />
        <!-- <item i_class='fa fas fa-address-book'  title="all country" url="/all_country"/> -->
      </nested>

      <nested title="Drivers" icon=" fa-solid fa-users ">
        <item
          i_class="fa-solid fa-users"
          title="Online Drivers"
          url="/providers/online_providers"
        />

        <item
          i_class="fa-solid fa-users"
          title="Approved Drivers"
          url="/providers/approved_providers"
        />
        
        <item
          i_class=" fa-solid fa-users-slash "
          title="Unapproved Drivers"
          url="/providers/pending_providers"
        />
      </nested>
      <nested title="users" icon="fa-solid fa-users-line">
        <item i_class="fa-solid fa-users-line" title="users" url="/users" />
        <item i_class=" fa-solid fa-user-xmark " title="Banned Users" url="/" />
      </nested>

      <item i_class=" fa-solid fa-users-rectangle"  title="dispatcher" url="/dispatcher" />
      <item i_class="fa-solid fa-users-rectangle" title="corporate" url="/corporate" />
      <item i_class="fa-solid fa-users-rectangle" title="Hotels" url="/" />
      <item i_class="fa-solid fa-users-rectangle" title="Partners" url="/" />
      <nested title="Service Types" icon="fa-solid fa-gauge-high">
        <item i_class="fa fas fa-address-book" title="Type" url="/service_types" />
        <item i_class= "fa-solid fa-earth-asia " title="Country" url="/country" />
        <item i_class="fa-solid fa-city" title="City" url="/city" />
        <item
          i_class="fa-solid fa-city"
          title="Type-City Association"
          url="/city_type"
        />
      </nested>
      <nested title="Trip Payment" icon="fa-solid fa-hand-holding-dollar">
        <item i_class="fa fas fa-address-book" title="trip_earning" url="/trip/trip_earning" />
        <item i_class="fa fas fa-address-book" title="daily_earning" url="/trip/daily_earning" />
        <item
          i_class="fa fas fa-address-book"
          title="weekly_earning"
          url="/trip/weekly_earning"
        />
        <item
          i_class="fa fas fa-address-book"
          title="Partner Weekly Payments"
          url="/"
        />
        <item
          i_class="fa fas fa-address-book"
          title="Wallet Archives"
          url="/"
        />
        <item
          i_class="fa fas fa-address-book"
          title="Transaction Logs"
          url="/"
        />
      </nested>
      <nested title="Report" icon="fa-solid fa-chart-simple"> 
        <item
          i_class="fa fas fa-address-book"
          title="User Referral Report"
          url="/"
        />
        <item
          i_class="fa fas fa-address-book"
          title="Driver Referral Report"
          url="/"
        />
      </nested>

      <nested title="Settings" icon="fa-solid fa-gears">
        <item
          i_class="fa-solid fa-language"
          title="trip_languages"
          url="/trip_languages"
        />
        <item
          i_class=" fa-solid fa-gear "
          title="basic_settings"
          url="/basic_settings"
        />
        <item
          i_class="fa-solid fa-gear"
          title="installation_settings"
          url="/installation_settings"
        />
        <item
          i_class=" fa-solid fa-gift "
          title="promotional_codes"
          url="/promotional_codes"
        />
        <item
          i_class=" fa-solid fa-file-lines "
          title="documents"
          url="/documents"
        />
        <item
          i_class=" fa-solid fa-envelope-open-text "
          title="email_emplate"
          url="/email_emplate"
        />
        <item
          i_class="  fa-solid fa-comment-sms  "
          title="template"
          url="/sms"
        />
        <item
          i_class=" fa-solid fa-users "
          title="admin_settings"
          url="/admin_list"
        />
        <item
          i_class=" fa-solid fa-shield-halved "
          title="terms_privacy_settings"
          url="/terms_privacy_settings"
        />
        <item
          i_class=" fa-solid fa-person-circle-question "
          title="guest_token_setting"
          url="/guest_token_setting"
        />
      </nested>
       <item
          i_class="   fa-solid fa-paper-plane  "
          title="send_mass_notification"
          url="/send_mass_notification"
        />
      <nested v-if="chekPerm('read_users')" title="employees">
        <item
          v-if="chekPerm('read_users')"
          title="employees"
          url="index.html"
        />
        <item v-if="chekPerm('read_users')" title="roles" url="index.html" />
      </nested>

      <li class="menu-item m-50p">
        <router-link
          lass="menu-link"
          style="margin-inline-start: 1rem"
          to="#"
          method="post"
          @click="logout"
        >
          <i class="fa fa-power-off"></i>
          <div class="link-title">
            {{ tr("logout") }}
          </div>
        </router-link>
      </li>

      <!--
            <li class="menu-header small text-uppercase">
              <span class="menu-header-text">Apps &amp; Pages</span>
            </li> -->
      <!-- Apps -->

      <!-- item -->
    </ul>
  </aside>
  <!-- / Menu -->
</template>

<script>
// import auduitsidebar from "./auduit_sidebar.vue";
import notification from "./notification.vue";
import item from "./item.vue";
import nested from "./nested.vue";

export default {
  name: "Navbar",
  components: { notification, item, nested },
  data() {
    return {
      school_perms: ["read_schools", "read_familes", "read_student"],
      repo_perms: ["read_repoguid", "read_products"],
      entry_perms: ["read_entries"],

      travel_perms: [
        "read_tr_travel_agency",
        "read_tr_invoices",
        "travel_agencies",
        "read_tr_travel_types",
        "read_tr_ticket_bookings",
        "read_tr_customers",
        "read_tr_service_providers",
      ],
      timeNow: 0,
    };
  },
  created() {},
  mounted() {
    setInterval(() => this.getTime(), 1000);
  },

  methods: {
    async logout() {
      try {
        // const res = await this.repo.otp.logout();
        // if (res.status == 200) {
           this.user_store.logout()
          localStorage.removeItem("state");
          this.$router.push("/").then(() => {
            window.location.reload();
          });
        // }
      } catch (error) {
        throw error;
      }
    },
    getTime() {
      const d = new Date();
      this.timeNow = d.toLocaleString();
    },
  },
};
</script>
