import { createRouter, createWebHistory } from "vue-router";
import Home from "../Pages/index.vue";
import stores from "../Pages/stores.vue";
import storeshow from "../Pages/storeshow.vue";

import page from "../Pages/page.vue";
import helpandsupport from "../Pages/helpandsupport.vue";
import addresses from "../Pages/Addresses/index.vue";
import addresses_create from "../Pages/Addresses/create.vue";
 
// import edit_document_form from "../Pages/Settings/edit_document_form.vue";
import providersRoutes from './providers';
import usersRoutes from './users';
import productRoutes from './product';
import { useUserStore } from "../stores/user";
 const AdminRoutes = [
   
  // {
  //   path: "/edit_document_form/:id",
  //   name: "edit_document_form",
  //   component: edit_document_form,
  //   meta: { requiresAuth: true },
  // },
  
  {
    path: "/addresses",
    name: "addresses",
    component: addresses,
  },
 
  {
    path: "/addresses/create",
    name: "addresses.create",
    component: addresses_create,
  },
  {
    path: "/stores",
    name: "stores",
    component: stores,
    
  },{
    path: "/stores/:id",
    name: "stores.show",
    component: storeshow,
    
  },
  ,{
    path: "/page/:slug",
    name: "page.show",
    component: page,
    
  },
  
  ,{
    path: "/help-and-support",
    name: "helpandsupport",
    component: helpandsupport,
    
  },
      {
    path: "/",
    name: "home",
    component: Home,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  
   

   
];
var allRoutes = []
allRoutes = allRoutes.concat(productRoutes,usersRoutes,providersRoutes, AdminRoutes)

const routes = allRoutes

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    // const token = localStorage.getItem('token');
    // !this.user_store.token && !JSON.parse(localStorage.getItem("user"))
    const store = useUserStore();
    if (store.token) {
      // User is authenticated, proceed to the route
      next();
    } else {
      // User is not authenticated, redirect to login
      next("/login");
    }
  } else {
    // Non-protected route, allow access
    next();
  }
});

export default router;
