<template>
  

  

    <div   v-if="loading">
      <form role="form"
        method="post"
        @submit.prevent="Submit"
        action="#"
        enctype="multipart/form-data"
      >
        <div class="row">
        <slot/>
        </div>

        <div class="row" style="margin-bottom: 3px">
          <div class="col-sm-3 col-md-2">
            <button
              type="submit"
              class="
                btn

                btn-success
              "
            >
              <i class="fa fa-check"></i>
              {{ tr("save") }}
            </button>
          </div>
        </div>
      </form>
    </div>
   
</template>

<script>


export default {
 computed: {
        data() {
            return []
        }},
  props: {
 
form:{}  ,  user: Object,
    edit_mode: false,
    schools: [],
    roles: [],
    audit_roles:[],
    branches: [],
  }

,
  data() {
    return {
        loading:false,

    };
  },
  created() {
      console.log(this.form)





    this.loading = true;


  },
  methods: {
    Submit() {
      if (this.edit_mode) 
      
      this.emitter.emit('update')
      else  this.emitter.emit('store')
    },
    
     
  },
};
</script>
