<template>
    <div class="card">
        <!-- <headtable/> -->
                <h5 class="card-header">{{title}}</h5>
                <div id="product_table_wrapper" class="dataTables_wrapper form-inline dt-bootstrap">
                <div class="dataTables_scroll">

                
                <div class="dataTables_scrollHead">

             
                <div class="dataTables_scrollHeadInner">
                 
                  <table 
                  cellspacing="0" 
                  class="table table-hover c-table">
                    <thead>
                      <tr>
                  
                            <slot name="thead"></slot>
                      </tr>
                      
                    </thead>
                     <loader  v-if="$parent.loaderOn"/>
                    <tbody  v-if="!$parent.loaderOn" class="table-border-bottom-0">
                   <slot name="tbody"></slot>
                
                    </tbody>
                  </table>
                   <slot name="pagination"></slot>
                   </div>
                </div>   </div>
              </div> </div>
</template>

<script>
// import ClasicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  props: [ 'title'],

  emits: ['update:modelValue'],

  methods: {
       del(users, index, id) {
      axios
        .delete(route(this.actions.destroy.route, id))
        .then((res) => {
          users.splice(index, 1);
          this.alertSuccess();
        })
        .catch((error) => {
          this.alertError();
        });
    },
    destroy(users, index, id) {
      this.Swal.fire({
        title: "تأكيد الحذف ?",
        text: "هل تريد الحذف!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.del(users, index, id);
        } else {
        }
      });
    },
    focus() {
      this.$refs.input.focus()
    }
  },
mounted(){


},

 computed: {
        errors() {
            return this.$page.props.errors
        },
 errors() {
            return this.$page.props.errors
        },
        hasErrors() {
            return Object.keys(this.errors).length > 0
        },
         list1() {
            return this.$page.props.list
        },
        columns() {
            return this.$page.props.columns
        },
        error() {
            // return '6666';
            var n=this.name
            return this.$page.props.errors[n]
        },hasErrors() {
            return Object.keys(this.errors).length > 0
        },
    },
      data() {
    return {
         columns1 : [
  { data: 'name', title: 'Name' },
  { data: 'position', title: 'Position' },
  { data: 'office', title: 'Office' },
  { data: 'extn', title: 'Extension' },
  { data: 'start_date', title: 'Start date' },
  { data: 'salary', title: 'Salary' },
],
        _cols1 :[
    { data: 'id' },
    { data: 'name' },

],
list:[],
actions:[],
        _cols:[],

        }}

}
</script>
<style scoped>
tr{
      /* max-width: 100%;
    display: block; */
}
</style>