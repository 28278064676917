<template>
    <div class="col-12">

   
     <div class="  col-12">
                
                  <button
                    type="submit"
                    id="apply_filter"
                    class="btn btn-primary"
                    style="height: 35px;
    width: fit-content;
    margin-top: 5px;
    padding: 0.5rem 4rem;"
                  >
                    <span>{{tr('save')}}</span>
                  </button>
             
              </div> </div>
</template>

<script>
 

export default  {
    
}
</script>
