<template>
<m-header :business_settings="business_settings"/>


    <!-- Page Contain -->
    <div class="page-contain">

        <!-- Main content -->
        <div id="main-content" class="main-content container-fluid">
<router-view></router-view>
  <slot name="full"></slot>
                  </div>
    </div>

   <mfooter :business_settings="business_settings"/>
    <!--Quickview Popup-->
    <div id="biolife-quickview-block" class="biolife-quickview-block">
        <div class="quickview-container">
            <a href="#" class="btn-close-quickview" data-object="open-quickview-block"><span class="biolife-icon icon-close-menu"></span></a>
            <div class="biolife-quickview-inner">
                <div class="media">
                    <ul class="biolife-carousel quickview-for" data-slick='{"arrows":false,"dots":false,"slidesMargin":30,"slidesToShow":1,"slidesToScroll":1,"fade":true,"asNavFor":".quickview-nav"}'>
                        <li><img src="/theme/assets/images/details-product/detail_01.jpg" alt="" width="500" height="500"></li>
                        <li><img src="/theme/assets/images/details-product/detail_02.jpg" alt="" width="500" height="500"></li>
                        <li><img src="/theme/assets/images/details-product/detail_03.jpg" alt="" width="500" height="500"></li>
                        <li><img src="/theme/assets/images/details-product/detail_04.jpg" alt="" width="500" height="500"></li>
                        <li><img src="/theme/assets/images/details-product/detail_05.jpg" alt="" width="500" height="500"></li>
                        <li><img src="/theme/assets/images/details-product/detail_06.jpg" alt="" width="500" height="500"></li>
                        <li><img src="/theme/assets/images/details-product/detail_07.jpg" alt="" width="500" height="500"></li>
                    </ul>
                    <ul class="biolife-carousel quickview-nav" data-slick='{"arrows":true,"dots":false,"centerMode":false,"focusOnSelect":true,"slidesMargin":10,"slidesToShow":3,"slidesToScroll":1,"asNavFor":".quickview-for"}'>
                        <li><img src="/theme/assets/images/details-product/thumb_01.jpg" alt="" width="88" height="88"></li>
                        <li><img src="/theme/assets/images/details-product/thumb_02.jpg" alt="" width="88" height="88"></li>
                        <li><img src="/theme/assets/images/details-product/thumb_03.jpg" alt="" width="88" height="88"></li>
                        <li><img src="/theme/assets/images/details-product/thumb_04.jpg" alt="" width="88" height="88"></li>
                        <li><img src="/theme/assets/images/details-product/thumb_05.jpg" alt="" width="88" height="88"></li>
                        <li><img src="/theme/assets/images/details-product/thumb_06.jpg" alt="" width="88" height="88"></li>
                        <li><img src="/theme/assets/images/details-product/thumb_07.jpg" alt="" width="88" height="88"></li>
                    </ul>
                </div>
                <div class="product-attribute">
                    <h4 class="title"><a href="#" class="pr-name">National Fresh Fruit</a></h4>
                    <div class="rating">
                        <p class="star-rating"><span class="width-80percent"></span></p>
                    </div>

                    <div class="price price-contain">
                        <ins><span class="price-amount"><span class="currencySymbol">£</span>85.00</span></ins>
                        <del><span class="price-amount"><span class="currencySymbol">£</span>95.00</span></del>
                    </div>
                    <p class="excerpt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel maximus lacus. Duis ut mauris eget justo dictum tempus sed vel tellus.</p>
                    <div class="from-cart">
                        <div class="qty-input">
                            <input type="text" name="qty12554" value="1" data-max_value="20" data-min_value="1" data-step="1">
                            <a href="#" class="qty-btn btn-up"><i class="fa fa-caret-up" aria-hidden="true"></i></a>
                            <a href="#" class="qty-btn btn-down"><i class="fa fa-caret-down" aria-hidden="true"></i></a>
                        </div>
                        <div class="buttons">
                            <a href="#" class="btn add-to-cart-btn btn-bold">add to cart</a>
                        </div>
                    </div>

                    <div class="product-meta">
                        <div class="product-atts">
                            <div class="product-atts-item">
                                <b class="meta-title">Categories:</b>
                                <ul class="meta-list">
                                    <li><a href="#" class="meta-link">Milk & Cream</a></li>
                                    <li><a href="#" class="meta-link">Fresh Meat</a></li>
                                    <li><a href="#" class="meta-link">Fresh Fruit</a></li>
                                </ul>
                            </div>
                            <div class="product-atts-item">
                                <b class="meta-title">Tags:</b>
                                <ul class="meta-list">
                                    <li><a href="#" class="meta-link">food theme</a></li>
                                    <li><a href="#" class="meta-link">organic food</a></li>
                                    <li><a href="#" class="meta-link">organic theme</a></li>
                                </ul>
                            </div>
                            <div class="product-atts-item">
                                <b class="meta-title">Brand:</b>
                                <ul class="meta-list">
                                    <li><a href="#" class="meta-link">Fresh Fruit</a></li>
                                </ul>
                            </div>
                        </div>
                        <span class="sku">SKU: N/A</span>
                        <div class="biolife-social inline add-title">
                            <span class="fr-title">Share:</span>
                            <ul class="socials">
                                <li><a href="#" title="twitter" class="socail-btn"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="#" title="facebook" class="socail-btn"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li><a href="#" title="pinterest" class="socail-btn"><i class="fa fa-pinterest" aria-hidden="true"></i></a></li>
                                <li><a href="#" title="youtube" class="socail-btn"><i class="fa fa-youtube" aria-hidden="true"></i></a></li>
                                <li><a href="#" title="instagram" class="socail-btn"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




 

 
 
</template>

<script>

import navbar from './navbar.vue'
import { useSlots } from 'vue'

import topmenu from './topmenu.vue'
import Menu from './Menu.vue'
import MHeader from './header.vue'
import mfooter from './footer.vue'

export default {


  components: {MHeader,mfooter,
    navbar, topmenu, Menu

  },
  methods: {
        async accept() {
      this.showUpgradeUI = false
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    updateLang() {

      if (this.getLang() != 'ar')
        this.main_class = ''
      else
        this.main_class = 'overflow-x-hidden'

    }
  },
  async created() {
      if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpgradeUI = true;
      });
    }   
       var th = this.$cookies.get('theme')
       var lang = this.$cookies.get('lang')
       
    this.swithTheme(th)
    this.updateLang()
    this.emitter.on('updatelang', this.updateLang);
   await this.site_store.get_business_settings();
  this.business_settings = this.site_store.business_settings;
    

  },
  data() {
    return {
        slots :useSlots(),
      main_class: '',business_settings:{},
      showingNavigationDropdown: false,
    }
  },
}
</script>
