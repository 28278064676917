<template>
        <li  class="product-item col-lg-3 col-md-3 col-sm-4 col-xs-6">
                                    <div class="contain-product layout-default">
                                        <div class="product-thumb">
                                            <a href="#" class="link-to-product">
                                                <img v-lazy="base_domain+'upload/product/'+pr.image  " alt="dd" width="270" height="270" class="product-thumnail">
                                            </a>
                                        </div>
                                        <div class="info">
                                            <b class="categories">{{pr.category_ids[0].name}}</b>
                                            <h4 class="product-title">
                                              <router-link href="#" class="pr-name"
                                              :to="{ name: 'product.show', params: { id: pr.id } }" 
                                              >
                                                {{pr.name}}t</router-link></h4>
                                            <div class="price">
                                                <ins><span class="price-amount"><span class="currencySymbol">£</span>{{pr.price}}</span></ins>
                                                <del><span class="price-amount"><span class="currencySymbol">£</span>95.00</span></del>
                                            </div>
                                            <div class="shipping-info">
                                                <p class="shipping-day">3-Day Shipping</p>
                                                <p class="for-today">Pree Pickup Today</p>
                                            </div>
                                            <div class="slide-down-box">
                                                <p class="message">{{pr.description}}</p>
                                                <div class="buttons">
                                                    <a href="#" class="btn wishlist-btn"><i class="fa fa-heart" aria-hidden="true"></i></a>
                                                    <a href="#"   v-if="!in_cart"  @click.prevent="addToCart()"  class="btn add-to-cart-btn"><i class="fa fa-cart-arrow-down" aria-hidden="true"></i> </a>

                                                     <button type="button"   v-if="in_cart"
          @click.prevent="deleteItem(cart_id)"
          data-bs-title="The last tip!"
          class="btn b-n btn-danger"
        >
          <i class="fa-regular fa-trash-can fa-1x"></i>
        </button>
                                                    <a href="#" class="btn compare-btn"><i class="fa fa-random" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                             
</template>
<script>
 

export default {
    props: {
pr:{}
  },
    data() {
    return {
 in_cart:this.cart_store.chekProductInCart(this.pr.id),
 cart_id:this.cart_store.getCartId(this.pr.id),
quantity:this.cart_store.getProductQuantity(this.pr.id),
product:this.pr
    }},
     async created() {
 
     },
    methods:{

    }
    }

</script><style scoped>
.contain-product.layout-default .slide-down-box{
    opacity: 1;    padding-top: 11px;
    visibility: visible;
}
.contain-product.layout-default  {
    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
}


</style>