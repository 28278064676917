import { defineStore } from 'pinia'
import Repo from '../repositories/RepositoryFactory'
import Client from '../repositories/Clients/AxiosClient';

export const useSiteStore = defineStore("site", {
  state: () => {
    return {
      business_settings:{
            
      },
        has_business_settings:false,
        has_site_data:false,
        site_data:{
            
        }
        ,
      userData: {}
    }
  },
  actions: {
    
    async  get_business_settings(){
      // if(this.has_site_data)
      // return  this.site_data
      try {
         var res = await  Client.get("/business_settings");;
          var self = this;  
          this.business_settings=  res.data
          if (res.status === 200) {
             this.has_business_settings = true;
          }
        } catch (error) {
          throw error
        }
  },
    async  get_site_data(){
        // if(this.has_site_data)
        // return  this.site_data
        try {
           var res = await  Client.get("/react-landing-page");;
            var self = this;  
            this.site_data=  res.data
            if (res.status === 200) {
               this.has_site_data = true;
            }
          } catch (error) {
            throw error
          }
    },
    async login(data) {
      
      try {
        const res = await Repo.otp.verify(data);
        if (res.status === 200) {
          this.userData = res.data;
        }
      } catch (error) {
        throw error
      }
    },
  },
  getters: {
    sites_data(state){
        
        return state.site_data
    },
    token (state) {
      if(state.userData)
      return state.userData.adminDetail?.token
      return false
      },
  },
})
