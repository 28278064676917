<template>
   <nav
            class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
            id="layout-navbar">
            <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
              <a class="nav-item nav-link px-0 me-xl-4"

@click.prevent="swithSideBar()"
               href="javascript:void(0)">
                <i class="bx bx-menu bx-sm"></i>
              </a>
            </div>

            <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
              <!-- Search -->
              <div class="navbar-nav align-items-center">
                <div class="nav-item d-flex align-items-center">
                  <i class="bx bx-search fs-4 lh-0"></i>
                  <input
                    type="text"
                    class="form-control border-0 shadow-none ps-1 ps-sm-2"
                    placeholder="Search..."
                    aria-label="Search..." />
                </div>
              </div>
              <!-- /Search -->

              <ul class="navbar-nav flex-row align-items-center ms-auto">
                <!-- Place this tag where you want the button to render. -->
                <li class="nav-item lh-1 me-3">
                  <a
                    class="github-button"
                    href="https://github.com/themeselection/sneat-html-admin-template-free"
                    data-icon="octicon-star"
                    data-size="large"
                    data-show-count="true"
                    aria-label="Star themeselection/sneat-html-admin-template-free on GitHub"
                    >Star</a
                  >
                </li>

                <!-- User -->
                <li class="nav-item navbar-dropdown dropdown-user dropdown">
                  <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
                    <div class="avatar avatar-online">
                      <img src="/assets/img/avatars/1.png" alt class="w-px-40 h-auto rounded-circle" />
                    </div>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li>
                      <a class="dropdown-item" href="#">
                        <div class="d-flex">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar avatar-online">
                              <img src="/assets/img/avatars/1.png" alt class="w-px-40 h-auto rounded-circle" />
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <span class="fw-medium d-block">John Doe</span>
                            <small class="text-muted">Admin</small>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <div class="dropdown-divider"></div>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bx bx-user me-2"></i>
                        <span class="align-middle">My Profile</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <i class="bx bx-cog me-2"></i>
                        <span class="align-middle">Settings</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <span class="d-flex align-items-center align-middle">
                          <i class="flex-shrink-0 bx bx-credit-card me-2"></i>
                          <span class="flex-grow-1 align-middle ms-1">Billing</span>
                          <span class="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">4</span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <div class="dropdown-divider"></div>
                    </li>
                    <li>
                      <a class="dropdown-item" href="javascript:void(0);">
                        <i class="bx bx-power-off me-2"></i>
                        <span class="align-middle">Log Out</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <!--/ User -->

   <li class="nav-itemnavbar-dropdown dropdown-user dropdown">
       <div class="dropdown">
                            <button type="button" class="btn p-0 dropdown-toggle hide-arrow show" data-bs-toggle="dropdown" aria-expanded="true">
                            <i class="fa-solid fa-language fa-2x"></i>
                            </button>
                            <div class="dropdown-menu  " data-popper-placement="bottom-end" style="position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate3d(6.4px, 40px, 0px);">


            <router-link class="dropdown-item" @click.prevent="swithLang('en')" to="#" data-language="en"><i
                class="flag-icon flag-icon-us"></i> English6
            </router-link>

            <router-link class="dropdown-item" @click.prevent="swithLang('ar')" to="#" data-language="fr"><i
                class="flag-icon flag-icon-ar"></i> العربية
            </router-link>
 </div>
 </div>


        </li>

              </ul>
            </div>
          </nav>
</template>


<script>
// import BreezeResponsiveNavLink from "@/Components/ResponsiveNavLink.vue";
// import auduitsidebar from "./auduit_sidebar.vue";
import item from "./item.vue";
import notification from './notification.vue'
import Client from '../repositories/Clients/AxiosClient';
export default {
  setup() {
  },
  components: {
    notification,
     item,
    // BreezeResponsiveNavLink,
  },
  data() {
    return {
      timeNow: 0,
      aside_class: '',
      sidenav_class: '',
      showMobileMenu: false,
      loding: false,
      school_perms: ["read_schools", "read_familes", "read_student"],
      repo_perms: ["read_repoguid", "read_products"],
      entry_perms: ["read_entries"],
      rclass: " ",
      rclass2: " ",
      rclass3: " ",
    };
  },
  created() {
 
    // document.getElementById("main_html").classList.add(th);

  },
  mounted() {
    setInterval(() => this.getTime(), 1000)


  },
  methods: {
    changeTheme() {
// document.getElementById("html_main").classList.add('MyClass');

// document.getElementById("html_main").classList.remove('MyClass');

      if (document.getElementById("html_main").classList.contains('dark-layout')) {


        document.getElementById("html_main").classList.remove('dark-layout');
        this.$cookies.set('theme', '', 60 * 60 * 365, '/')   //return this

      } else {
        document.getElementById("html_main").classList.add('dark-layout');

        this.$cookies.set('theme', 'dark-layout', 60 * 60 * 365, '/')   //return this

      }

// document.getElementById("html_main").classList.toggle('MyClass');
    },
    getTime() {
      const d = new Date();
      this.timeNow = d.toLocaleString()
    },
    addClass() {
      document.body.classList.add("bg-light");
      // document.body.classList.add('bg-light', 'login')
    },
    removeClass() {
      document.body.classList.remove("bg-light");
      // document.body.classList.remove('bg-light', 'login')
    },

    changeClass(class1) {
      if (class1 == "rclass") {
        if (this.rclass == "pcoded-trigger") this.rclass = "";
        else this.rclass = "pcoded-trigger";
      } else if (class1 == "rclass2") {
        if (this.rclass2 == "pcoded-trigger") this.rclass2 = "";
        else this.rclass2 = "pcoded-trigger";
      } else if (class1 == "rclass3") {
        if (this.rclass3 == "pcoded-trigger") this.rclass3 = "";
        else this.rclass3 = "pcoded-trigger";
      }

    },
    Subscriber() {
      if (this.role == "Subscriber") return true;
      return false;
    },
    updateLang() {
      Client.get('fff').then(({data}) => {
        console.log(data, 'async getLang')
        // var l=   data  ;
        console.log({data})
        if (data == 'ar') {

          this.aside_class = 'fixed-end'
          this.sidenav_class = 'start-0'
          console.log(data, 1)
        } else {

          this.aside_class = 'fixed-start'
          this.sidenav_class = 'end-0'
          console.log(data, 22)
        }

      });


    }
  },
  created() {
    this.updateLang()
    this.emitter.on('updatelang', this.updateLang);
    this.loding = true


  },
  computed: {},
};
</script>
